import {
  useCallback, useEffect, useRef, useMemo,
} from 'react'
import cx from 'classnames'
import { useIntl } from '@wiz/intl'
import useSplit from '@/hooks/useSplit'
import { goreact } from '@wiz/utils'
import { Icon } from '@wiz/components'
import { useDiagram } from './hooks/useDiagram'
import Result from './result'

const DefaultSplitSizes = [ 100, 0 ]
const SplitMinSizes = [ 300, 0 ]

export default function Diagram ({ data, execution, isRunning }) {
  const ref = useRef()
  const diagramDivRef = useRef(null)
  const diagramRef = useRef(null)
  const diagramSectionRef = useRef()
  const resultSectionRef = useRef()

  const intl = useIntl()

  const { initDiagram } = useDiagram({ data, diagramRef })

  const splitParams = useMemo(() => ({
    items: [ diagramSectionRef, resultSectionRef ],
    direction: 'horizontal',
    sizes: DefaultSplitSizes, // explorerViewSplit
    defaultSizes: DefaultSplitSizes,
    minSize: 0,
    onChange: console.log,
  }), [])

  const refSplit = useSplit(splitParams)

  const handleModelChanged = useCallback((args) => {
    console.log('model changed', args)
  }, [])

  const handleSelection = useCallback((...args) => {
    console.log('selection', args)
  }, [])
  const handleDblClick = useCallback((...args) => {
    console.log('handleDblClick', args)
  }, [])

  const handleClick = useCallback(() => {
    // refSplit.current.collapse(0)
  }, [ refSplit ])

  // useEffect(() => {
  //   if (execution) {
  //     refSplit.current?.setSizes([ 50, 50 ])
  //   } else {
  //     refSplit.current?.destroy()
  //   }
  // }, [ execution, refSplit ])

  console.log('ref split', refSplit)
  useEffect(() => {
    const diagram = diagramRef.current
    if (diagram) {
      //   // diagram.addDiagramListener('BackgroundDoubleClicked', handleDblClick)
      //   // diagram.addDiagramListener('BackgroundSingleClicked', handleClick)
      //   // diagram.addDiagramListener('ChangedSelection', handleChangedSelection)
      //   // diagram.addDiagramListener('LinkDrawn', handleLink)
      //   // diagram.addDiagramListener('LinkRelinked', handleLink)
      diagram.addDiagramListener('ObjectDoubleClicked', handleDblClick)
      diagram.addDiagramListener('ObjectSingleClicked', handleClick)
    //   // diagram.addDiagramListener('SelectionDeleted', handleDeleted)
    //   // diagram.addDiagramListener('SelectionMoved', handleSelectionMoved)
    //   // diagram.addDiagramListener('ViewportBoundsChanged', handleViewportChange)
    //   // diagram.addDiagramListener('TextEdited', handleTextEdit)
    // diagram.addModelChangedListener(handleModelChanged)
    //   diagram.onUserEvent('selection', handleSelection)
    //   // diagram.onUserEvent('togglecontext', handleToggleContext)
    //   // diagram.onUserEvent('clickEvents', handleClickEvents)
    //   // diagram.onUserEvent('paste', handlePaste)
    //   // diagram.onUserEvent('deleted', handleUserDeleted)
    }
    return () => {
      // diagram.removeModelChangedListener(handleModelChanged)
      //     diagram.offUserEvent('selection', handleSelection)
    }
  }, [ diagramRef.current ])

  return (
    <div ref={ref} className="d-flex flex-row min-h-0 min-w-0 flex-fill">
      <div ref={diagramSectionRef} className="position-relative overflow-hidden bg-white d-flex">
        {!data ? (
          <div className="position-relative position-center-fill bg-light opacity-50">
            <Icon name="fa--spinner" size="2X" spin />
          </div>
        ) : (
          <goreact.ReactDiagram
            ref={diagramDivRef}
            divClassName="diagram-component flex-fill"
            initDiagram={initDiagram}
            onModelChange={handleModelChanged}
          />
        )}
      </div>
      <div
        ref={resultSectionRef}
        className={cx('d-flex flex-fill position-relative overflow-hidden m-2', {})}
      >
        <Result isRunning={isRunning} execution={execution} />
      </div>
    </div>
  )
}
