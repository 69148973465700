import {
  forwardRef,
  useRef,
  useMemo,
  useImperativeHandle,
} from 'react'
import { ResponsiveList } from '@wiz/components'
import { consts } from '@wiz/utils'
import ListOption from './ListOption'
import NoDataDisplay from './NoDataDisplay'
import withDrag from './withDrag'

function List ({
  className,
  options,
  optionClassName,
  search,
  draggable,
  selectable,
  Content,
  active,
  itemSize = consts.ListItemHeight,
  checkDragged,
  checkFiltered,
  onDragStart,
  onDragEnd,
  onDrop,
  onResetFilter,
  onChange,
  onAction,
  onEdit,
}, ref) {
  const refList = useRef()

  const itemData = useMemo(() => ({
    checkDragged,
    Content,
    draggable: typeof draggable === 'function' ? draggable : () => draggable,
    selectable: typeof selectable === 'function' ? selectable : () => selectable,
    optionClassName: typeof optionClassName === 'function' ? optionClassName : () => ('d-flex align-items-center hover-toggle'),
    active,
    onAction,
    onChange,
    onDragEnd,
    onDragStart,
    onDrop,
    onEdit,
    options,
    search,
  }), [
    checkDragged,
    Content,
    draggable,
    selectable,
    optionClassName,
    active,
    onDragEnd,
    onDragStart,
    onDrop,
    onAction,
    onChange,
    onEdit,
    options,
    search,
  ])

  useImperativeHandle(ref, () => ({
    scrollTo (id) {
      if (id && refList.current) {
        refList.current.scrollToRecord(id)
      }
    },

    getItemById (id) {
      if (id && refList.current) {
        return refList.current.getItemById(id)
      }

      return undefined
    },

    getSiblingsById (id) {
      if (id && refList.current) {
        return refList.current.getSiblingsById(id)
      }

      return []
    },

    getVisible () {
      return refList.current?.getVisible()
    },
  }), [])

  if (!options.length && (!checkFiltered || checkFiltered())) {
    return (
      <NoDataDisplay
        className={className}
        onResetFilter={onResetFilter}
      />
    )
  }

  return (
    <ResponsiveList
      refList={refList}
      className={className}
      itemCount={options.length}
      itemSize={itemSize}
      itemData={itemData}
    >
      {ListOption}
    </ResponsiveList>
  )
}

export default withDrag(
  forwardRef(List),
)
