import { useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField, FormControl, FormFieldInline, FormSelectDuration,
} from '@wiz/components'
import FormTemplates from '@/components/Form/ListTemplates'
import { useIntl } from '@wiz/intl'
import FormPipelines from '@/components/Form/SelectPipeline'
import FormPipelineImages from '@/components/Form/SelectPipelineImage'
import { testJSON } from '@/utils/json'

export default function Fields ({
  scope, templateId, pipelineId, pipelines,
}) {
  const intl = useIntl()
  const {
    watch, formState: { errors }, setValue, clearErrors,
  } = useFormContext()

  const selectedTemplateId = watch(`${scope}.templateId`)
  const selectedPipelineId = watch(`${scope}.pipelineId`)
  const selectedPipelineImageId = watch(`${scope}.pipelineImageId`)
  const selectedPipeline = pipelines?.find(pl => pl.id === selectedPipelineId)

  useEffect(() => {
    if (selectedPipelineId && templateId !== selectedTemplateId) {
      setValue(`${scope}.pipelineId`, undefined, { shouldDirty: true })
    }
  }, [ selectedTemplateId ])

  useEffect(() => {
    if (selectedPipelineImageId && pipelineId !== selectedPipelineId) {
      setValue(`${scope}.pipelineImageId`, undefined, { shouldDirty: true })
    }
  }, [ selectedPipelineId ])

  useEffect(() => {
    if (!selectedPipelineId && selectedPipelineImageId) {
      clearErrors(`${scope}.pipelineId`)
    }
  }, [ selectedPipelineImageId ])

  return (
    <>
      <FormField
        label={intl.t('form.fields.interval')}
        description={intl.t('triggers.form.fields.intervalDescr')}
        errors={get(errors, `${scope}.interval`)}
      >
        <Controller
          name={`${scope}.interval`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <FormSelectDuration
              {...field}
              label={intl.t('form.fields.interval')}
              parts="days,hours,minutes,seconds"
              invalid={fieldState.invalid}
              clearable
            />
          )}
        />
      </FormField>
      <FormField
        label={intl.t('form.fields.delay')}
        description={intl.t('triggers.form.fields.delayDescr')}
        errors={get(errors, `${scope}.delay`)}
      >
        <Controller
          name={`${scope}.delay`}
          render={({ field, fieldState }) => (
            <FormSelectDuration
              {...field}
              label={intl.t('form.fields.delay')}
              parts="hours,minutes,seconds"
              invalid={fieldState.invalid}
              clearable
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.template')}
        description={intl.t('triggers.form.fields.templateDescr')}
        errors={get(errors, `${scope}.templateId`)}
      >
        <Controller
          name={`${scope}.templateId`}
          render={({ field, fieldState }) => (
            <FormTemplates
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.pipeline')}
        description={intl.t('triggers.form.fields.pipelineDescr')}
        errors={get(errors, `${scope}.pipelineId`)}
      >
        <Controller
          name={`${scope}.pipelineId`}
          rules={{
            validate: (value) => {
              if (!value && !selectedPipelineImageId) {
                return intl.t('form.errors.fieldRequired')
              }
              return true
            },
          }}
          render={({ field, fieldState }) => (
            <FormPipelines
              {...field}
              templateId={selectedTemplateId}
              invalid={fieldState.invalid}
              placeholder="--"
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.pipelineImage')}
        description={intl.t('triggers.form.fields.pipelineImageDescr')}
        errors={get(errors, `${scope}.pipelineImageId`)}
      >
        <Controller
          name={`${scope}.pipelineImageId`}
          render={({ field, fieldState }) => (
            <FormPipelineImages
              {...field}
              pipelineId={selectedPipelineId}
              pipelineKey={selectedPipeline?.key}
              invalid={fieldState.invalid}
              placeholder="--"
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('triggers.form.fields.jsonProperties')}
        description={intl.t('triggers.form.fields.jsonPropertiesDescr')}
        errors={get(errors, `${scope}.jsonProperties`)}
        vertical
        complex
      >
        <FormControl
          type="code"
          title={intl.t('triggers.form.fields.jsonProperties')}
          description={intl.t('triggers.form.fields.jsonPropertiesDescr')}
          name={`${scope}.jsonProperties`}
          lang="json"
          rootStyle={{ minHeight: 150 }}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (str.length && !testJSON(str)) {
                return intl.t('form.errors.mustBeJson')
              }
              // if (!str.length) {
              //   return intl.t('form.errors.fieldRequired')
              // }
              if (str.length > 20000) {
                return intl.t('form.errors.fieldMaxlen', { max: 20000 })
              }
              return true
            },
          }}
        />
      </FormFieldInline>
    </>
  )
}
