import { useCallback, useMemo } from 'react'
import { Q, dbProvider, StreamJob } from '@wiz/store'
import { withProps } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import events from '@/utils/events'
import { useAuth } from '@/auth'
import Component from '@/components/Form/BulkSensorOperations'

const BulkOperations = [
  {
    action: 'explorer',
    label: 't/form.actions.openDataExplorer',
    icon: 'fa--search',
    access: auth => auth.checkAccessRead('SectionDataExplorer'),
  },
  {
    action: 'export',
    label: 't/form.actions.exportTitle',
    icon: 'fa--file-download',
    access: auth => auth.checkAccessCreate('Job'),
  },
  {
    action: 'notebook',
    label: 't/form.actions.createNotebook',
    icon: 'fa--file-contract',
    access: auth => auth.checkAccessCreate('Job'),
  },
  {
    action: 'dashboard',
    label: 't/form.actions.addToDashboard',
    icon: 'fa--th-large',
    access: auth => auth.checkAccessRead('SectionDashboards'),
  },
  {
    action: 'streamJob',
    label: 't/form.actions.createStreamJob',
    icon: 'mlworkflow',
    access: auth => auth.checkAccessCreate('StreamJob'),
  },
  {
    action: 'unlink',
    label: 't/form.actions.unlinkSensors',
    icon: 'fa--unlink',
    access: auth => auth.checkAccessUpdate('Sensor'),
  },
  {
    action: 'inputSensorValue',
    label: 't/form.actions.inputSensorValue',
    icon: 'fa--pen-square',
    access: auth => auth.checkAccessUpdate('Sensor'),
  },
]

const enhanceProps = withProps(({
  exclude,
}) => {
  const auth = useAuth()
  const intl = useIntl()

  const onUnlinkSensors = useCallback(async (sensorIds) => {
    const sensors = await dbProvider.database.collections.get('sensors')
      .query(Q.where('id', Q.oneOf(sensorIds)))
      .fetch()

    const context = dbProvider.createBatchContext()
    for (const sensor of sensors) {
      // eslint-disable-next-line no-await-in-loop
      await sensor.prepareUnlink(context)
    }
    await dbProvider.batch(context)
  }, [])

  const onCreateStreamJob = useCallback(async (sensorIds) => {
    try {
      const context = dbProvider.createBatchContext()
      const model = await StreamJob.prepareCreateBySensors(
        context,
        dbProvider.database,
        sensorIds,
      )
      await dbProvider.batch(context)
      return model
    } catch (error) {
      events.emit('app:notify', {
        type: 'error',
        title: 't/streamJobs.titleCreate',
        rawMessage: error.message,
      })
      throw error
    }
  }, [])

  const options = useMemo(() => (
    BulkOperations
      .filter(item => !exclude?.includes(item.action))
      .filter(item => item.access(auth))
      .map(item => ({
        ...item,
        label: intl.t(item.label),
      }))
  ), [ auth, intl, exclude ])

  return {
    options,
    onUnlinkSensors,
    onCreateStreamJob,
  }
})

export default enhanceProps(Component)

export function hasBulkSensorOperations (auth) {
  return BulkOperations.some(item => item.access(auth))
}
