import {
  useEffect,
  useMemo, useRef,
  useCallback,
  useState,
} from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import cx from 'classnames'
import { withObservables } from '@wiz/components'
import { useRouter, useLocationQuery } from '@/router'
import { wizataApi } from '@/api'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { dbProvider } from '@wiz/store'
import FormGrafanaDashboards from '@/components/Forms/GrafanaDashboards'
import events from '@/utils/events'
import camelCase from 'lodash/camelCase'
import Topbar from './topbar'
import RightBar from './rightbar'
import Grafana from './grafana'
import TilesView from './tilesView'
import s from './index.module.css'

const enhanceProps = withObservables([], () => ({
  labels: dbProvider.database.collections.get('business_labels').query().observeWithColumns([ 'updated_at' ]),
}))

const GrafanaDashboards = ({ labels }) => {
  const [ formData, setFormData ] = useState({ dateFrom: null, dateTo: null })
  const [ isCreating, setIsCreating ] = useState(false)
  const [ isEditing, setIsEditing ] = useState(false)
  const [ editId, setEditId ] = useState()
  // const [ list, setList ] = useState(options)

  const refRightBar = useRef(null)
  const dragItem = useRef()
  const dragOverItem = useRef()

  const { currentUser } = useAuth()
  const intl = useIntl()
  const router = useRouter()
  const {
    category, sub, twinId, view,
  } = useLocationQuery()

  const currentTwinDashboards = useQuery({
    queryKey: [ 'currentTwinDashboards', twinId ],
    queryFn: () => wizataApi.components.getList(twinId, { ownerId: currentUser.id, organizationOnly: true }),
    enabled: !twinId,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const dashboardsComponentsDeepList = useQuery({
    queryKey: [ 'dashboardsComponentsDeepList', twinId ],
    queryFn: () => wizataApi.dashboardsComponents.getListTwinId(twinId),
    enabled: !!twinId,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const grafanaCheckData = useQuery({
    queryKey: [ 'grafanaCheck' ],
    queryFn: () => wizataApi.grafana.check(),
    enabled: true,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    mutateAsync: createComponent,
  } = useMutation({
    mutationKey: [ 'createComponent' ],
    mutationFn: data => wizataApi.components.create(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.create.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
      dashboardsComponentsDeepList.refetch()
    },
  })

  const {
    mutateAsync: updateComponent,
  } = useMutation({
    mutationKey: [ 'updateComponent' ],
    mutationFn: data => wizataApi.components.update(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
    },
  })

  const {
    mutateAsync: deleteComponent, isLoading: isDeleting,
  } = useMutation({
    mutationKey: [ 'deleteComponent' ],
    mutationFn: id => wizataApi.components.deleteById(id),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.delete.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
      dashboardsComponentsDeepList.refetch()
    },
  })

  const {
    mutateAsync: duplicateComponent, isLoading: isDuplicating,
  } = useMutation({
    mutationKey: [ 'duplicateComponent' ],
    mutationFn: params => wizataApi.grafana.createDashboardGrafana(params),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/grafana.duplicate.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
    },
  })

  const {
    mutateAsync: duplicateNativeDashboard, isLoading: isDuplicatingNativeDashboard,
  } = useMutation({
    mutationKey: [ 'duplicateNativeDashboard' ],
    mutationFn: params => wizataApi.dashboardsComponents.duplicate(params),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.duplicate.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
    },
  })

  const handleSaveFormData = useCallback((data) => {
    setFormData(prev => ({ ...prev, ...data }))
  }, [])

  const handleAdd = ({ id }) => {
    setIsCreating(id)
  }

  const handleEdit = useCallback((isEdit = !isEditing) => {
    setIsEditing(isEdit)
  }, [ isEditing ])

  const handleSyncSuccess = () => {
    dbProvider.sync.removeListener('synced', handleSyncSuccess)
  }

  const handleDashboardCreate = async (model) => {
    if (!isCreating && !model?.type) {
      currentTwinDashboards.refetch()
      return
    }

    const type = isCreating || model.type.toLowerCase()
    let component
    if (type === 'grafana') {
      currentTwinDashboards.refetch()
      component = model
    } else if (type === 'dashboard' && model) {
      await currentTwinDashboards.refetch()
      dbProvider.sync.once('synced', handleSyncSuccess)
      dbProvider.sync.sync()
      component = model
      // setIsCreating(false)
    } else if (type === 'iframe') {
      component = await createComponent({ ...model, type: 'Iframe' })
    } else {
      // return
    }
    // router.replace({ query: { twinId, category, sub: component.id } })
  }

  const handleDuplicate = async (params) => {
    const next = { ...params }
    await window.wizConfirm({ message: 't/units.confirmDuplicate' })
    let item
    if (next.type === 'Grafana') {
      delete next.componentId
      item = await duplicateComponent({
        ...next,
        ownerId: currentUser?.id,
      })
    } else if (next.type === 'Dashboard') {
      item = await duplicateNativeDashboard({ componentId: next.componentId })
      dbProvider.sync.once('synced', handleSyncSuccess)
      dbProvider.sync.sync()
    } else {
      delete next.componentId
      item = await createComponent({ ...next, ownerId: currentUser?.id })
    }

    router.push({ query: { twinId, category, sub: item.id } })

    return item
  }

  const handleDragStart = useCallback((e, item) => {
    dragItem.current = item
  }, [])

  const handleDragEnd = useCallback((e, item) => {
    dragOverItem.current = item
  }, [])

  const handleDrop = useCallback((e) => {
    if (dragOverItem.current.type === 'Label') {
      const next = {
        ...dragItem.current,
        labelId: dragOverItem.current.labelId,
      }
      updateComponent(next)
    }
    // const copyListItems = [ ...list ]
    // const dragItemContent = copyListItems[dragItem.current]
    // copyListItems.splice(dragItem.current, 1)
    // copyListItems.splice(dragOverItem.current, 0, dragItemContent)
    dragItem.current = null
    dragOverItem.current = null
    // setList(copyListItems)
  }, [ updateComponent ])

  const components = useMemo(
    () => {
      if (!currentTwinDashboards || !dashboardsComponentsDeepList) {
        return { data: [] }
      }

      const nextDashboards = currentTwinDashboards.data?.filter((item) => {
        if (view === 'personal') {
          return item.ownerId
        } if (view === 'org') {
          return !item.ownerId
        }
        return true
      })

      return !twinId ? dashboardsComponentsDeepList : { ...currentTwinDashboards, data: nextDashboards }
    },
    [ twinId, dashboardsComponentsDeepList, currentTwinDashboards, view ],
  )

  const options = useMemo(() => {
    if (!components?.data) {
      return []
    }

    return components?.data.map(d => ({ ...d, value: d.id, label: d.name }))
  }, [ components?.data ])

  const isOwn = useMemo(() => {
    if (components?.data?.length) {
      const twin = components?.data?.find(opt => opt.twinId === twinId)
      return !!twin
    }
    return false
  }, [ components, twinId ])

  const categories = useMemo(() => components?.data?.reduce((acc, val) => {
    if (!twinId || !isOwn) {
      return []
    }
    const label = labels?.find(l => l.id === val.labelId)

    const subCat = acc.find(item => item.labelId === val.labelId)

    if (!subCat) {
      acc.push({
        ...val, label: label.name, value: val.id, icon: camelCase(label.icon), order: label.order, color: label.color,
      })
    }

    return acc
  }, []).sort((a, b) => a.order - b.order) || [], [ components?.data, labels, twinId, isOwn ])

  const subCategories = useMemo(() => components?.data?.reduce((acc, val) => {
    if (!twinId || !isOwn) {
      return []
    }
    const subCat = acc.find(item => item.id === val.id)
    if (!subCat && val.labelId === category) {
      acc.push({ ...val, label: val.name, value: val.id })
    }

    return acc
  }, []).sort((a, b) => a.order - b.order) || [], [ components?.data, category, twinId, isOwn ])

  const dashboard = useMemo(() => {
    if (!options?.length || !category || category === 'search') {
      return null
    }

    const item = options.find(i => i.labelId === category && i.id === sub)

    if (!item) {
      return options[0]
    }

    return item
  }, [ options, category, sub ])

  const grafanaId = dashboard?.content?.split('/')[1]
  const grafanaVariables = useQuery({
    queryKey: [ 'grafanaVars', grafanaId ],
    queryFn: () => wizataApi.grafana.getVariablesById(grafanaId),
    enabled: !!grafanaId,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const handleRightBarClick = async (labelId) => {
    if (isEditing && labelId !== category) {
      await window.wizConfirm({ message: 't/components.leaveEdit' })
      handleEdit(false)
    }
    const item = components?.data.sort((a, b) => a.order - b.order).find(g => g.labelId === labelId)
    router.push({
      query: {
        twinId, category: labelId, sub: item?.id, view,
      },
    })
  }

  const handleDelete = async (id) => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    await deleteComponent(id)
    const lastItem = subCategories[subCategories.length - 2] || false
    if (lastItem) {
      router.replace({
        query: {
          twinId, category, sub: lastItem?.id, view,
        },
      })
    } else {
      router.replace({
        query: {
          twinId, view,
        },
      })
    }
  }
  useEffect(() => {
    if (twinId && !sub && categories?.length) {
      router.replace({
        query: {
          category: categories[0]?.labelId, sub: subCategories[0]?.id, twinId, view,
        },
      })
    }
  }, [ sub, twinId, categories, subCategories, router, view ])

  useEffect(() => {
    if (!twinId && !category && !sub && !categories.length && !subCategories.length) {
      router.replace({ query: { category: 'search' } })
    }
  }, [ twinId, category, sub, router, categories, subCategories ])

  useEffect(() => {
    if (!twinId && category) {
      router.push({ query: { category: 'search' } })
    } else {
      components?.refetch()
    }
  }, [ twinId ])

  // if (isLoading) {
  //   return (
  //     <div className="position-absolute-fill position-center-fill bg-light opacity-50">
  //       <Icon name="fa--spinner" size="2X" spin />
  //     </div>
  //   )
  // }

  // if (!twinId) {
  //   return (
  //     <div className="position-center-fill flex-column h-100">
  //       <h4>{intl.t('form.info.solutionsSelectTwin')}</h4>
  //     </div>
  //   )
  // }
  return (
    <>
      <div className="d-flex flex-row">
        <RightBar
          ref={refRightBar}
          options={categories}
          item={subCategories[0]}
          isEditing={isEditing}
          onDrop={handleDrop}
          onDragEnd={handleDragEnd}
          onClick={handleRightBarClick}
          onEdit={handleEdit}
        />
        <div className={cx('d-flex flex-column flex-fill position-relative')}>
          <Topbar
            dashboard={dashboard}
            options={subCategories}
            list={currentTwinDashboards.data}
            onSaveFormData={handleSaveFormData}
            formData={formData}
            isEdit={isEditing}
            isLoading={isDeleting || isDuplicating || currentTwinDashboards?.isLoading || isDuplicatingNativeDashboard}
            onAdd={handleAdd}
            onEditing={handleEdit}
            onComponentEdit={setEditId}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDrop={handleDrop}
            onDelete={handleDelete}
            onDuplicate={handleDuplicate}
          />
          <div className={cx('d-flex flex-column flex-fill position-relative', { [s.wrap]: !!isEditing })}>
            {dashboard ? (
              <Grafana
                {...dashboard}
                formData={formData}
                variables={grafanaVariables.data}
                isEdit={isEditing}
                onEditing={handleEdit}
                check={grafanaCheckData}
              />
            ) : (
              <TilesView
                twinId={twinId}
                components={twinId ? dashboardsComponentsDeepList : currentTwinDashboards}
                deepList={dashboardsComponentsDeepList}
                list={currentTwinDashboards}
              />
            )}
          </div>
        </div>
      </div>
      {(isCreating || editId) ? (
        <FormGrafanaDashboards
          scope={isCreating || editId?.type?.toLowerCase()}
          item={editId}
          onClose={() => {
            setIsCreating(false)
            setEditId(null)
          }}
          items={subCategories}
          onSuccess={handleDashboardCreate}
          onUpdate={currentTwinDashboards.refetch}
          dialog={{
            title: isCreating ? intl.t(`components.form.create.${isCreating}`) :
              intl.t(`components.form.edit.${editId?.type?.toLowerCase()}`),
            dataTestid: 'createComponentDialog',
          }}
        />
      ) : null}
    </>
  )
}

export default enhanceProps(GrafanaDashboards)
