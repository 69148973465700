import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import { FormFieldInline } from '@wiz/components'
import Select from '@/components/Form/Select'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function FieldsTwins ({ scope, templateId }) {
  const intl = useIntl()
  const { watch, setValue, formState: { errors } } = useFormContext()
  const selectedTemplateId = watch('trigger.templateId')
  const selectedTwinId = watch(scope)

  const { data: twins, isLoading } = useQuery({
    queryKey: [ 'twinsByTemplateId', selectedTemplateId ],
    queryFn: () => wizataApi.templates.getRegistrationsById(selectedTemplateId)
      .then(items => items.map(({ twinId, name }) => ({ id: twinId, name, value: twinId }))),
    enabled: !!selectedTemplateId,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (selectedTwinId && templateId !== selectedTemplateId) {
      setValue(`${scope}`, undefined, { shouldDirty: true })
    }
  }, [ selectedTemplateId ])

  return (
    <FormFieldInline
      errors={get(errors, scope)}
    >
      <Controller
        name={scope}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            invalid={fieldState.invalid}
            placeholder={intl.t('form.fields.twinPlaceholder')}
            multiselect
            options={twins}
            disabled={isLoading || !selectedTemplateId}
            isLoading={isLoading}
          />
        )}
      />
    </FormFieldInline>
  )
}
