import {
  useState, useRef, useEffect, useMemo,
} from 'react'
import classnames from 'classnames'
import { useQueryClient } from '@tanstack/react-query'
import {
  FormInputSearch,
} from '@wiz/components'
import { useLocation, useLocationQuery, useRouter } from '@/router'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'
import ComponentsFilters from '@/components/DashboardsComponents/Filters'
import ExperimentsFilters from '@/components/Experiment/Filters'
import ExecutionsFilters from '@/components/HistoricalRuns/Filters'
import StreamJobsFilters from '@/components/StreamJobs/Filters'
import StreamJobLogsFilters from '@/components/StreamJobLogs/Filters'
import SentSMSFilters from '@/components/SentSMSLogs/Filters'
import SentEmailFilters from '@/components/SentEmailLogs/Filters'
import TriggersFilters from '@/components/Triggers/Filters'
import PipelinesFilters from '@/components/Pipelines/filters'
import SensorsFilters from '@/components/sensors/Filters'
import TwinsFilters from '@/components/Twin/Filters'
import {
  PagesMap, AiLabPages, DataHubPages,
} from '@/utils/consts'
import { debounce } from '@wiz/utils'
import useAppContext from '@/hooks/useAppContext'
import classes from './index.module.css'

const Filters = () => {
  const [ isOpen, setOpen ] = useState(false)

  const ref = useRef(null)
  const refSearch = useRef(null)
  const refFilters = useRef(null)

  const queryClient = useQueryClient()
  const {
    hasActiveFilters, onFiltersReset, filters: contextFilters, onRefresh,
  } = useAppContext()

  const intl = useIntl()
  const router = useRouter()
  const { defaultSearch, view, search } = useLocationQuery() // view -> using for twins page
  const loc = useLocation()
  const [ ,,, currentPage, id ] = loc.pathname.split('/')

  const handleClick = () => {
    setOpen(!isOpen)
  }
  const handleRefresh = (queryKey) => {
    if (contextFilters.dateRange) {
      onRefresh()
    }
    if (currentPage === PagesMap.Templates && id) {
      queryClient.invalidateQueries({ queryKey: [ 'templateRegistrations', id ] })
    } else {
      queryClient.invalidateQueries({ queryKey: [ queryKey ] })
    }
  }

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isOpen) {
        const isOpenMenu = document.querySelectorAll('[role="menu"]')
        if (!ref.current?.contains(e.target) && !isOpenMenu?.length) {
          setOpen(false)
        }
      }
    }

    document.addEventListener('click', handleOutsideClick, false)
    return () => {
      document.removeEventListener('click', handleOutsideClick, false)
    }
  }, [ ])

  useEffect(() => {
    if (search && defaultSearch && refSearch.current) {
      // router.replace({ query: { view, search: defaultSearch } })
      refSearch.current?.set(defaultSearch)
    }
  }, [ defaultSearch ])

  const pageWithActions = useMemo(() => {
    const page = AiLabPages[currentPage] || DataHubPages[currentPage]
    if (page) {
      return page
    }
    return {}
  }, [ currentPage ])

  return (
    <div ref={ref} className="d-flex ms-auto position-relative">
      <div className="ms-2">
        <FormInputSearch
          ref={refSearch}
          placeholder={intl.t('form.actions.searchPlaceholder')}
          onChange={(value) => {
            // if (value?.length > 2) {
            router.replace({ query: { view, search: value } })
            // }
          }}
          className="form-control-sm bg-light-alt"
          disabled={!pageWithActions.hasSearch}
          timeout={500}
        />
      </div>
      <button
        type="button"
        className="btn btn-fill-secondary-alt btn-text ms-2"
        onClick={() => handleRefresh(pageWithActions.queryKey)}
        disabled={!pageWithActions.hasRefresh}
      >
        <Icon name="faArrowsRotate" className="me-2" />
        {intl.t('form.actions.refresh')}
      </button>
      <button
        type="button"
        className="btn btn-fill-secondary-alt btn-text ms-2"
        onClick={onFiltersReset}
        disabled={!hasActiveFilters}
      >
        <Icon name="faFilterSlash" className="me-2" />
        {intl.t('form.actions.clear')}
      </button>
      <button
        type="button"
        className={classnames('btn btn-fill-secondary-alt btn-text ms-2', { acive: isOpen })}
        onClick={handleClick}
        disabled={!pageWithActions.hasFilters}
      >
        <Icon name="faFilter" className="me-2" />
        {intl.t('form.actions.filters')}
        {hasActiveFilters ? <Icon name="faCircleDot" className={classes.status} /> : null}
      </button>
      {pageWithActions.hasFilters && isOpen ? (
        <div className={classes.dialog}>
          <div className="d-flex mb-2">
            <div className="flex-fill fw-bold">{intl.t('form.actions.filters')}</div>
            <Icon name="faXmark" onClick={handleClick} className="pointer" />
          </div>
          <div className="d-flex flex-wrap">
            {currentPage === PagesMap.Components ? <ComponentsFilters ref={refFilters} /> : null}
            {currentPage === PagesMap.Experiments ? <ExperimentsFilters /> : null}
            {currentPage === PagesMap.Executions ? <ExecutionsFilters /> : null}
            {currentPage === PagesMap.StreamJobs ? <StreamJobsFilters /> : null}
            {currentPage === PagesMap.StreamJobsLogs ? <StreamJobLogsFilters /> : null}
            {currentPage === PagesMap.SMSLogs ? <SentSMSFilters /> : null}
            {currentPage === PagesMap.EmailLogs ? <SentEmailFilters /> : null}
            {currentPage === PagesMap.Pipelines ? <PipelinesFilters /> : null}
            {currentPage === PagesMap.Triggers ? <TriggersFilters /> : null}
            {currentPage === PagesMap.Sensors ? <SensorsFilters /> : null}
            {currentPage === PagesMap.Twins ? <TwinsFilters /> : null}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Filters
