import { map } from 'rxjs/operators'
import { toArrayValue, orderBy } from '@wiz/utils'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'

export default withObservables([
  'selectedOnly',
  'search',
  'value',
], ({
  selectedOnly,
  search,
  value,
}) => {
  let query = dbProvider.database.collections.get('units').query()

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('symbol', Q.like(`%${sanitizeSearch}%`)),
      Q.where('short_name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('full_name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('id', search),
    ))
  }

  if (selectedOnly) {
    query = query.extend(Q.where('id', Q.oneOf(toArrayValue(value))))
  }

  const observe = selectedOnly ?
    query.observeWithColumns([ 'updated_at' ]) :
    query.observe()

  return {
    options: observe
      .pipe(
        map(items => items.map(item => ({
          id: item.id,
          name: item.displayName,
          icon: item.icon,
          color: item.color,
        }))),
        map(items => orderBy(items, [ item => item.name.toLowerCase() ], [ 'asc' ])),
      ),
  }
})
