import { useMemo, useCallback, useContext } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dbProvider, Dashboard } from '@wiz/store'
import events from '@/utils/events'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import { useLocationQuery, useRouter } from '@/router'
import { OperateContext } from '@/pages/context/operate'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'

const GrafanaDashboards = ({
  dialog,
  onClose,
  onUpdate,
  items,
  onSuccess,
  scope,
  item,
}) => {
  const { contextTwinId } = useContext(OperateContext)
  const auth = useAuth()
  const queryClient = useQueryClient()
  const { twinId, category, sub } = useLocationQuery()
  const router = useRouter()
  const { isOrganizational, isPersonalStrictly } = useAuthComponents()

  const {
    mutateAsync: createDashboardGrafana,
  } = useMutation({
    mutationKey: [ 'createDashboardGrafana' ],
    mutationFn: data => wizataApi.grafana.createDashboardGrafana(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.create.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess,
  })

  const {
    mutateAsync: createDashboardNative,
  } = useMutation({
    mutationKey: [ 'createDashboardNative' ],
    mutationFn: data => wizataApi.dashboardsComponents.createDashboardNative(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.create.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess,
  })

  const {
    mutateAsync: updateComponent,
  } = useMutation({
    mutationKey: [ 'updateComponent', item?.id ],
    mutationFn: data => wizataApi.components.update(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess,
  })

  const handleCreateDashboard = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Dashboard, data)
    if (auth.checkAccessRead('Twin')) {
      const twins = data.twinId ? [ data.twinId ] : []
      await model.prepareReplaceTwins(context, twins)
    }
    await dbProvider.batch(context)

    // if (auth.checkAccessShare(model)) {
    //   context = dbProvider.createBatchContext()
    //   await model.prepareReplaceShareUsers(context, data.permissions)
    //   await dbProvider.batch(context)
    // }
    return model
  }, [ auth ])

  const handleUpdate = useCallback(async (data) => {
    let result
    if (data.type === 'Dashboard') {
      result = await handleCreateDashboard({ ...data, id: data.dashboardId, title: data.name })
      const model = { ...Dashboard.toJSON(result) }
      if (!data.isOrganizational) {
        model.ownerId = auth.currentUser.id
      }
      result = await updateComponent({
        ...model,
        id: data.componentId || item.id,
        labelId: data.labelId,
        twinId: data.twinId,
        name: model.title,
        dashboardId: model.id,
        type: data.type,
        order: data.order,
      })
    } else {
      result = await updateComponent({ ...data, id: item.id })
    }
  }, [ auth, handleCreateDashboard, updateComponent, item ])

  const handleSyncSuccess = useCallback(() => {
    dbProvider.sync.removeListener('synced', handleSyncSuccess)
  }, [])

  const handleSubmit = async ({ component }) => {
    let result

    const next = { ...component, ownerId: auth.currentUser.id }
    delete next.id

    if (component.isOrganizational) {
      delete next.ownerId
    }

    if (item) {
      result = await handleUpdate(next)
      return result
    }

    if (contextTwinId) {
      next.twinId = contextTwinId
    }

    if (scope === 'dashboard') {
      next.type = 'Dashboard'
      result = await createDashboardNative(next)
    } else if (scope === 'grafana') {
      result = await createDashboardGrafana(next)
    } else if (scope === 'iframe') {
      result = await onSuccess(next) // onSuccess is for direct create component
    }
    return result
  }

  const handleSuccess = (data) => {
    onUpdate?.()
    if (data) {
      router.replace({ query: { twinId: data.twinId || twinId, category: data.labelId || category, sub: data.id } })
    }
  }

  const defaultValues = useMemo(() => ({
    component: {
      labelId: category && category !== 'search' ? category : undefined,
      twinId,
      order: item?.order || items?.length ? items[items.length - 1].order + 1 : 1,
      isOrganizational: isPersonalStrictly ? false : !item?.ownerId,
      ...item,
    },
  }), [ item, items, category, twinId, isPersonalStrictly ])

  // if (id && isLoading) {
  //   return (
  //     <div className="position-absolute-fill position-center-fill bg-light opacity-50">
  //       <Icon name="fa--spinner" size="2X" spin />
  //     </div>
  //   )
  // }

  return (
    <FormDialog
      onClose={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      defaultValues={defaultValues}
      dataTestid="dashboardGrafanaCreateForm"
      dialog={dialog}
    >
      <Section
        scope="component"
        isIframe={scope === 'iframe'}
        isGrafana={scope === 'grafana' || item?.type?.toLowerCase() === 'grafana'}
      />
    </FormDialog>
  )
}

export default GrafanaDashboards
