import {
  useMemo, useRef,
  useCallback,
  useState,
  createContext,
  useEffect,
} from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useRouter, useLocationQuery } from '@/router'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import { dbProvider } from '@wiz/store'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import events from '@/utils/events'
import camelCase from 'lodash/camelCase'

export const OperateContext = createContext()

export default function OperateContextProvider ({ children }) {
  const {
    contextTwinId,
    handleTwinChange,
  } = useGlobalExecute()
  const [ formData, setFormData ] = useState({ dateFrom: null, dateTo: null })
  const [ isCreating, setIsCreating ] = useState(false)
  const [ isEditing, setIsEditing ] = useState(false)
  const [ labels, setLabels ] = useState()
  const [ editId, setEditId ] = useState()
  const [ selectedSensorsContext, setSelectedSensorsContext ] = useState([])
  const [ isShowChildren, setShowChildren ] = useState(false)

  const dragItem = useRef()
  const dragOverItem = useRef()

  const { currentUser } = useAuth()
  const router = useRouter()
  const {
    category, sub, view, search,
  } = useLocationQuery()

  const currentTwinDashboards = useQuery({
    queryKey: [ 'currentTwinDashboards', contextTwinId ],
    queryFn: () => wizataApi.components.getList(contextTwinId, { ownerId: currentUser.id, organizationOnly: true }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const dashboardsComponentsDeepList = useQuery({
    queryKey: [ 'dashboardsComponentsDeepList', contextTwinId ],
    queryFn: () => wizataApi.dashboardsComponents.getListTwinId(contextTwinId, isShowChildren),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const grafanaCheckData = useQuery({
    queryKey: [ 'grafanaCheck' ],
    queryFn: () => wizataApi.grafana.check(),
    enabled: true,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const grafanaId = dashboardsComponentsDeepList?.data
    ?.find(i => i.labelId === category && i.id === sub)?.content
    ?.split('/')[1]

  const grafanaVariables = useQuery({
    queryKey: [ 'grafanaVars', grafanaId ],
    queryFn: () => wizataApi.grafana.getVariablesById(grafanaId),
    enabled: !!grafanaId,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    mutateAsync: createComponent,
  } = useMutation({
    mutationKey: [ 'createComponent' ],
    mutationFn: data => wizataApi.components.create(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.create.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
      dashboardsComponentsDeepList.refetch()
    },
  })

  const {
    mutateAsync: updateComponent,
  } = useMutation({
    mutationKey: [ 'updateComponent' ],
    mutationFn: data => wizataApi.components.update(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
    },
  })

  const {
    mutateAsync: deleteComponent, isLoading: isDeleting,
  } = useMutation({
    mutationKey: [ 'deleteComponent' ],
    mutationFn: id => wizataApi.components.deleteById(id),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.delete.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
      dashboardsComponentsDeepList.refetch()
    },
  })

  const {
    mutateAsync: duplicateComponent, isLoading: isDuplicating,
  } = useMutation({
    mutationKey: [ 'duplicateComponent' ],
    mutationFn: params => wizataApi.grafana.createDashboardGrafana(params),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/grafana.duplicate.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
    },
  })

  const {
    mutateAsync: duplicateNativeDashboard, isLoading: isDuplicatingNativeDashboard,
  } = useMutation({
    mutationKey: [ 'duplicateNativeDashboard' ],
    mutationFn: params => wizataApi.dashboardsComponents.duplicate(params),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.duplicate.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      currentTwinDashboards.refetch()
    },
  })

  const handleSaveFormData = useCallback((data) => {
    setFormData(prev => ({ ...prev, ...data }))
  }, [])

  const handleAdd = useCallback((data) => {
    setIsCreating(data?.id)
  }, [])

  const handleEdit = useCallback((isEdit = !isEditing) => {
    setIsEditing(isEdit)
  }, [ isEditing ])

  const handleSyncSuccess = useCallback(() => {
    dbProvider.sync.removeListener('synced', handleSyncSuccess)
  }, [])

  const handleDashboardCreate = useCallback(async (model) => {
    if (!isCreating && !model?.type) {
      currentTwinDashboards.refetch()
      return
    }

    const type = isCreating || model.type.toLowerCase()
    if (type === 'grafana') {
      currentTwinDashboards.refetch()
    } else if (type === 'dashboard' && model) {
      await currentTwinDashboards.refetch()
      dbProvider.sync.once('synced', handleSyncSuccess)
      dbProvider.sync.sync()
      // setIsCreating(false)
    } else if (type === 'iframe') {
      await createComponent({ ...model, type: 'Iframe' })
    } else {
      // return
    }
    // router.replace({ query: { twinId, category, sub: component.id } })
  }, [
    createComponent,
    currentTwinDashboards,
    handleSyncSuccess,
    isCreating,
  ])

  const handleDuplicate = useCallback(async (params) => {
    const next = { ...params }
    await window.wizConfirm({ message: 't/units.confirmDuplicate' })
    let item
    if (next.type === 'Grafana') {
      delete next.componentId
      item = await duplicateComponent({
        ...next,
        ownerId: currentUser?.id,
      })
    } else if (next.type === 'Dashboard') {
      item = await duplicateNativeDashboard({ componentId: next.componentId })
      dbProvider.sync.once('synced', handleSyncSuccess)
      dbProvider.sync.sync()
    } else {
      delete next.componentId
      item = await createComponent({ ...next, ownerId: currentUser?.id })
    }

    router.push({ query: { twinId: contextTwinId, category, sub: item.id } })

    return item
  }, [
    category,
    createComponent,
    currentUser?.id,
    duplicateComponent,
    duplicateNativeDashboard,
    handleSyncSuccess,
    router,
    contextTwinId,
  ])

  const handleDragStart = useCallback((e, item) => {
    dragItem.current = item
  }, [])

  const handleDragEnd = useCallback((e, item) => {
    dragOverItem.current = item
  }, [])

  const handleDrop = useCallback(() => {
    if (dragOverItem.current.type === 'Label') {
      const next = {
        ...dragItem.current,
        labelId: dragOverItem.current.labelId,
      }
      updateComponent(next)
    }
    // const copyListItems = [ ...list ]
    // const dragItemContent = copyListItems[dragItem.current]
    // copyListItems.splice(dragItem.current, 1)
    // copyListItems.splice(dragOverItem.current, 0, dragItemContent)
    dragItem.current = null
    dragOverItem.current = null
    // setList(copyListItems)
  }, [ updateComponent ])

  const components = useMemo(
    () => {
      if (!currentTwinDashboards || !dashboardsComponentsDeepList) {
        return { data: [] }
      }

      const nextDashboards = currentTwinDashboards.data?.filter((item) => {
        if (view === 'personal') {
          return item.ownerId
        } if (view === 'org') {
          return !item.ownerId
        }
        return true
      })

      return !contextTwinId ? dashboardsComponentsDeepList : { ...currentTwinDashboards, data: nextDashboards }
    },
    [ contextTwinId, dashboardsComponentsDeepList, currentTwinDashboards, view ],
  )

  const options = useMemo(() => {
    if (!components?.data) {
      return []
    }

    return components?.data.map(d => ({ ...d, value: d.id, label: d.name }))
  }, [ components?.data ])

  const isOwn = useMemo(() => {
    if (components?.data?.length) {
      const twin = components?.data?.find(opt => opt.twinId === contextTwinId)
      return !!twin
    }
    return false
  }, [ components, contextTwinId ])

  const categories = useMemo(() => components?.data?.reduce((acc, val) => {
    if (!contextTwinId || !isOwn) {
      return []
    }
    const label = labels?.find(l => l.id === val.labelId)

    const subCat = acc.find(item => item.labelId === val.labelId)

    if (!subCat) {
      acc.push({
        ...val,
        label: label?.name,
        value: val.id,
        icon: camelCase(label?.icon),
        order: label?.order,
        color: label?.color,
      })
    }

    return acc
  }, []).sort((a, b) => a.order - b.order) || [], [ components?.data, labels, contextTwinId, isOwn ])

  const subCategories = useMemo(() => components?.data?.reduce((acc, val) => {
    if (!contextTwinId || !isOwn) {
      return []
    }
    const subCat = acc.find(item => item.id === val.id)
    if (!subCat && val.labelId === category) {
      acc.push({ ...val, label: val.name, value: val.id })
    }

    return acc
  }, []).sort((a, b) => a.order - b.order) || [], [ components?.data, category, contextTwinId, isOwn ])

  const dashboard = useMemo(() => {
    if (!options?.length || !category) {
      return null
    }

    const item = options.find(i => i.labelId === category && i.id === sub)

    if (!item) {
      return options[0]
    }

    return item
  }, [ options, category, sub ])

  const handleDelete = useCallback(async (id) => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    await deleteComponent(id)
    const lastItem = subCategories[subCategories.length - 2] || false
    if (lastItem) {
      router.replace({
        query: {
          twinId: contextTwinId, category, sub: lastItem?.id, view,
        },
      })
    } else {
      router.replace({
        query: {
          twinId: contextTwinId, view,
        },
      })
    }
  }, [
    category,
    deleteComponent,
    router,
    subCategories,
    contextTwinId,
    view,
  ])

  const handleComponentsRefetch = useCallback((includeChildren) => {
    setShowChildren(includeChildren)
  }, [])

  useEffect(() => {
    dbProvider.database.collections.get('business_labels').query().fetch().then(setLabels)
  }, [])

  useEffect(() => {
    if (!contextTwinId && !currentTwinDashboards?.data) {
      currentTwinDashboards.refetch()
    }

    if (!!contextTwinId && !dashboardsComponentsDeepList?.data) {
      dashboardsComponentsDeepList.refetch()
    }
  }, [ contextTwinId ])

  useEffect(() => {
    if (!!contextTwinId && !!dashboardsComponentsDeepList?.data && !dashboardsComponentsDeepList?.isFetching) {
      dashboardsComponentsDeepList?.refetch()
    }
  }, [ isShowChildren ])

  const value = useMemo(() => ({
    dashboard,
    subCategories,
    categories,
    options,
    components,
    labels,
    handleDelete,
    handleDrop,
    handleDragEnd,
    handleDragStart,
    handleDuplicate,
    handleDashboardCreate,
    handleEdit,
    handleAdd,
    handleSaveFormData,
    isDuplicating: isDuplicating || isDuplicatingNativeDashboard || isDeleting,
    grafanaCheckData,
    currentTwinDashboards,
    dashboardsComponentsDeepList,
    grafanaVariables,
    formData,
    contextTwinId,
    handleTwinChange,
    isEditing,
    isCreating,
    editId,
    handleEditId: setEditId,
    selectedSensorsContext,
    setSelectedSensorsContext,
    isShowChildren,
    onComponentsRefetch: handleComponentsRefetch,
  }), [
    dashboard,
    categories,
    subCategories,
    options,
    components,
    labels,
    handleDelete,
    handleDrop,
    handleDragEnd,
    handleDragStart,
    handleDuplicate,
    handleDashboardCreate,
    handleEdit,
    handleAdd,
    handleSaveFormData,
    isDuplicating,
    isDuplicatingNativeDashboard,
    isDeleting,
    grafanaCheckData,
    currentTwinDashboards,
    dashboardsComponentsDeepList,
    grafanaVariables,
    formData,
    contextTwinId,
    handleTwinChange,
    isEditing,
    isCreating,
    editId,
    setEditId,
    selectedSensorsContext,
    setSelectedSensorsContext,
    isShowChildren,
    handleComponentsRefetch,
  ])

  return (
    <OperateContext.Provider value={value}>
      {children}
    </OperateContext.Provider>
  )
}
