import {
  useMemo,
} from 'react'
import { of } from 'rxjs'
import cx from 'classnames'
import { Skeleton, withObservables } from '@wiz/components'
import { useRouter, useLocationQuery } from '@/router'
import { useIntl } from '@wiz/intl'
import { dbProvider, Q } from '@wiz/store'
import placeholderImg from './placeholder-image.png'
import s from './index.module.css'

const CardView = ({ options, settings }) => {
  const router = useRouter()

  const handleClick = (id) => {
    router.replace({ query: { twinId: id } })
  }

  return options?.map(a => (
    <div
      key={a.id}
      className={cx('card me-2 mb-2', s.card)}
      onClick={() => handleClick(a.id)}
      style={{ borderColor: settings.EnvironmentColor }}
      aria-hidden
    >
      <div className="position-relative">
        <img src={a.image || placeholderImg} className="card-img-top" alt={a.name} />
        <div className={cx('card-action-open position-absolute', s.hover)}>Open</div>

      </div>
      <div className="card-body">
        <h5 className="card-title">{a.name}</h5>
      </div>
    </div>
  ))
}

const enhance = withObservables([ 'twins' ], ({ twins }) => ({
  options: twins?.length ? dbProvider.database.collections.get('twins')
    .query(Q.where('id', Q.oneOf(twins)))
    .observeWithColumns([ 'updated_at' ]) : of([]),
  settings: dbProvider.observeGlobalSettings([ 'EnvironmentColor' ]),
}))

const Cards = enhance(CardView)

const TilesView = ({ components, deepList, list }) => {
  const intl = useIntl()
  const router = useRouter()
  const { twinId } = useLocationQuery()

  // const {
  //   data: grafanaDashboards, isLoading, refetch, isFetching,
  // } = useQuery({
  //   queryKey: [ 'grafanaDashboards' ],
  //   queryFn: () => wizataApi.components.getList(),
  //   enabled: true,
  //   // staleTime: Infinity,
  //   retry: false,
  //   refetchOnWindowFocus: false,
  // })

  const options = useMemo(() => {
    const data = components?.data?.reduce((acc, val) => {
      const subCat = acc.find(item => item.twinId === val.twinId)
      if (!subCat) {
        acc.push(val)
      }

      return acc
    }, [])

    if (data?.length) {
      const twinIds = data.map(cmp => cmp.twinId).filter(i => !!i && i !== twinId)

      return twinIds
    }

    return []
  }, [ components, twinId ])

  const isLoading = (deepList.isLoading && deepList.isFetching) || (list.isLoading && list.isFetching)

  if (!options?.length && !isLoading) {
    return (
      <div className="position-center-fill flex-column h-100">
        <h4>{intl.t('form.info.solutionsNotFound')}</h4>
        {/* <button
          type="button"
          className="btn btn-flat-secondary"
          onClick={refetch}
        >
          {intl.t('form.actions.refresh')}
        </button> */}
      </div>
    )
  }

  return (
    <div className="d-flex flex-column min-h-0 pt-3 pb-md-3 px-3">
      {/* <h4>
        <b>Assets - </b>
        {intl.t('solutionsView.info.assets')}
      </h4> */}
      <div className="d-flex flex-wrap mt-2">
        {isLoading ?
          Array.from(new Array(5)).map((_, idx) => (
            <Skeleton
              key={idx}
              width={200}
              height={260}
              className="me-2 mb-2"
            />
          )) :
          <Cards twins={options} />}
      </div>
    </div>
  )
}

export default TilesView
