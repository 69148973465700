import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import CategorieList from '@/hoc/CategorieList'
import UnitList from '@/hoc/UnitList'
import Selectable from '@/components/Form/Selectable'
import SelectBatchDataSource from '@/containers/Form/SelectBatchDataSource'
import SelectLocation from '@/components/Form/SelectLocation'
import SelectIcon from '@/containers/Form/SelectIcon'
import SelectLabel from '@/components/Form/SelectLabel'
import SelectTwin from '@/components/Form/SelectTwin'
import Select from '@/components/Form/Select'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const isBatch = watch(`${scope}.batch`)

  return (
    <>
      <FormField
        complex
        label={intl.t('form.fields.category')}
        description={intl.t('sensors.form.fields.categoryBulkDescr')}
        errors={get(errors, `${scope}.categoryId`)}
        selectable={() => <Selectable value="categoryId" />}
      >
        <Controller
          name={`${scope}.categoryId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={CategorieList}
              placeholder="--"
            />
          )}
        />
      </FormField>

      <FormField
        complex
        label={intl.t('form.fields.unit')}
        description={intl.t('sensors.form.fields.unitBulkDescr')}
        errors={get(errors, `${scope}.unitId`)}
        selectable={() => <Selectable value="unitId" />}
      >
        <Controller
          name={`${scope}.unitId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={UnitList}
              placeholder="--"
            />
          )}
        />
      </FormField>

      <FormField
        complex
        label={intl.t('form.fields.validity')}
        description={intl.t('sensors.form.fields.validityBulkDescr')}
        errors={get(errors, `${scope}.validity`)}
        selectable={() => <Selectable value="validity" />}
      >
        <FormControl
          type="duration"
          name={`${scope}.validity`}
          parts="years,months,weeks,days,hours,minutes,seconds"
          clearable
        />
      </FormField>

      <FormFieldInline
        complex
        label={intl.t('form.fields.batch')}
        description={intl.t('sensors.form.fields.batchBulkDescr')}
        errors={get(errors, `${scope}.batch`)}
        selectable={() => <Selectable value="batch" />}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.batch`}
          className="ms-2"
        />
      </FormFieldInline>

      <FormField
        complex
        label={intl.t('form.fields.batchDataSource')}
        description={intl.t('sensors.form.fields.batchBulkDataSourceDescr')}
        errors={get(errors, `${scope}.batchDataSource`)}
        disabled={isBatch}
        selectable={() => <Selectable value="batchDataSource" />}
      >
        <Controller
          name={`${scope}.batchDataSource`}
          render={({ field, fieldState }) => (
            <SelectBatchDataSource
              {...field}
              invalid={fieldState.invalid}
              disabled={isBatch}
              placeholder="--"
            />
          )}
        />
      </FormField>

      <FormFieldInline
        complex
        label={intl.t('form.fields.color')}
        description={intl.t('sensors.form.fields.colorDescr')}
        errors={get(errors, `${scope}.color`)}
        selectable={() => <Selectable value="color" />}
      >
        <FormControl
          type="color"
          name={`${scope}.color`}
          clearable
        />
      </FormFieldInline>

      <FormField
        complex
        label={intl.t('form.fields.icon')}
        description={intl.t('sensors.form.fields.iconDescr')}
        errors={get(errors, `${scope}.icon`)}
        selectable={() => <Selectable value="icon" />}
      >
        <Controller
          name={`${scope}.icon`}
          render={({ field, fieldState }) => (
            <SelectIcon
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormField>

      <FormField
        complex
        label={intl.t('form.fields.location')}
        description={intl.t('sensors.form.fields.locationDescr')}
        selectable={() => <Selectable value={[ 'latitude', 'longitude' ]} />}
      >
        <SelectLocation scope={scope} />
      </FormField>

      <FormField
        complex
        label={intl.t('form.fields.labels')}
        description={intl.t('sensors.form.fields.labelsDescr')}
        errors={get(errors, `${scope}.labels`)}
        selectable={() => <Selectable value="labels" />}
      >
        <Controller
          name={`${scope}.labels`}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.labelsPlaceholder')}
              multiselect
            />
          )}
        />
      </FormField>

      <FormField
        complex
        label={intl.t('form.fields.twin')}
        description={intl.t('sensors.form.fields.twinDescr')}
        errors={get(errors, `${scope}.twinId`)}
        selectable={() => <Selectable value="twinId" />}
      >
        <Controller
          name={`${scope}.twinId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
              filters={{
                types: [
                  consts.TwinType.Area,
                  consts.TwinType.Equipment,
                  consts.TwinType.Machine,
                  consts.TwinType.Flow,
                ],
              }}
            />
          )}
        />
      </FormField>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
