import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import {
  FormSection,
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { get } from '@wiz/utils'
import SelectSensor from '@/components/Form/SelectSensor'

export default function SectionGeneral ({ title, scope, registerId = true }) {
  const intl = useIntl()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FormSection title={title}>
      {registerId ? (
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />
      ) : null}

      <FormField
        label={intl.t('form.fields.dataPoint')}
        errors={get(errors, `${scope}.sensorId`)}
      >
        <Controller
          name={`${scope}.sensorId`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.disabled')}
        errors={get(errors, `${scope}.disabled`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.disabled`}
          className="ms-2"
        />
      </FormFieldInline>
    </FormSection>
  )
}

SectionGeneral.propTypes = {
  scope: PropTypes.string.isRequired,
  title: PropTypes.string,
  registerId: PropTypes.bool,
}

SectionGeneral.defaultProps = {
  title: undefined,
  registerId: true,
}
