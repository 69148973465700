/* eslint-disable react/no-danger */
import classnames from 'classnames'
import { markSearch } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Link from '@/components/Link'

export default function CellName ({
  twin,
  filters,
}) {
  const intl = useIntl()
  return (
    <div className="min-w-0">
      <Link
        className={classnames({ 'text-muted': !twin.name })}
        name="twin-items-list"
        query={{ tree: twin.id }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: twin.name ? markSearch(twin.name, filters.search) : intl.t('form.noname'),
          }}
        />
      </Link>

      {twin.description ? (
        <div
          className="font-italic text-muted text-truncate small"
          dangerouslySetInnerHTML={{
            __html: markSearch(twin.description, filters.search),
          }}
        />
      ) : null}
    </div>
  )
}
