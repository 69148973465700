import { DateTime } from 'luxon'
import shortHash from 'short-hash'
import compact from 'lodash/compact'
import {
  Service,
  withVersioning,
  withAuth,
  withRetry,
  DataSourceRequest,
  DataSourceResponse,
  SensorStatRequest,
  SensorStatResponse,
  SensorHistogramRequest,
  SensorHistogramResponse,
} from '@wiz/api'
import { auth } from '@/auth'
import { Notification } from '@wiz/store'
import SensorStatistics from '@/store/models/SensorStatistics'
import { appEnv } from '@/config'

import users from './users'
import sync from './sync'
import ml from './ml'
import logs from './logs'
import events from './events'
import executions from './executions'
import deviceCommands from './deviceCommands'
import entities from './entities'
import dashboardsComponents from './components'
import settings from './settings'
import pipelines from './pipelines'
import templates from './templates'
import twins from './twins'
import sensors from './sensors'
import categories from './categories'
import units from './units'
import labels from './labels'

import ds from './ds/ds'
import grafana from './ds/grafana'
import experiments from './ds/experiments'
import library from './ds/library'
import pipelinesDS from './ds/pipelines'
import registrations from './ds/registrations'
import templatesDS from './ds/templates'
import components from './ds/components'

const client = withRetry(
  withAuth(
    withVersioning(
      new Service(process.env.WIZATA_API_BASE_URL),
      () => '1.0',
    ),
    forceRefresh => (
      auth.acquireToken(forceRefresh)
    ),
  ),
)

const dsClient = withRetry(
  withAuth(
    new Service(appEnv.DS_API_RECENT_URL),
    forceRefresh => (
      auth.acquireToken(forceRefresh)
    ),
  ),
)

@users(client)
@sync(client)
@ml(client)
@logs(client)
@events(client)
@executions(client)
@deviceCommands(client)
@entities(client)
@dashboardsComponents(client)
@settings(client)
@pipelines(client)
@templates(client)
@twins(client)
@sensors(client)
@labels(client)
@categories(client)
@units(client)

@grafana(dsClient)
@experiments(dsClient)
@library(dsClient)
@pipelinesDS(dsClient)
@registrations(dsClient)
@templatesDS(dsClient)
@ds(dsClient)
@components(dsClient)

class WizataApi {
  constructor (clientApi) {
    this.$api = clientApi
  }

  // eslint-disable-next-line class-methods-use-this
  init () {
    return Promise.resolve()
  }

  api () {
    return this.$api
  }

  getGeoLocations (searchTerm) {
    return this.init()
      .then(() => this.api().get('/GeoLocations', { searchTerm }).fetch())
      .then(data => data.map((item) => {
        const address = compact([
          item.street,
          item.postalCode,
          item.adminArea6, // neighborhood
          item.adminArea5, // city,
          item.adminArea4, // county,
          item.adminArea3, // state,
          item.adminArea1, // country
        ]).join(', ')
        const id = shortHash(`${address} (${item.latLng.lat}, ${item.latLng.lng})`)

        return {
          id,
          address,
          location: {
            lat: item.latLng.lat,
            lng: item.latLng.lng,
          },
        }
      }))
  }

  getSensorStatistics (ids) {
    if (!Array.isArray(ids) || !ids.length) {
      return Promise.reject()
    }

    return this.init()
      .then(() => this.api().post('/Sensors/statistics', ids).fetch())
      .then(data => Object.keys(data).reduce((out, key) => ({
        ...out,
        [key]: new SensorStatistics(data[key]),
      }), {}))
  }

  /**
   * @param {Array<{ sensorId, value, timestamp }>} values
   * @returns {Promise<Array<{ sensorId, value, timestamp, result: boolean | { error } }>>}
   */
  setSensorValues (values) {
    return this.init()
      .then(() => this.api().post('/Sensors/setValues', values).fetch())
      .then(data => values.map((item, idx) => ({
        ...item,
        result: data[idx],
      })))
  }

  getRoseWindData (request) {
    const fetchRequest = this.api().post('/TimeSeries/WindRose', {
      windDirectionSensorId: request.windDirectionSensorId,
      windSpeedSensorId: request.windSpeedSensorId,
      dateFrom: request.dateFrom,
      dateTo: request.dateTo,
      windSpeedBins: request.windSpeedBins,
    })

    return {
      fetch () {
        return fetchRequest.fetch().then(data => ({ request, data }))
      },
      abort () {
        return fetchRequest.abort()
      },
    }
  }

  getSensorsData (chunk: DataSourceRequest[]) {
    const fetchRequest = this.api().post('/TimeSeries/Data', chunk.map(item => item.toJSON()), {
      apiVersion: '1.0',
    })

    const chunkHash = chunk.reduce((out, item) => ({
      ...out,
      [item.id]: item,
    }), {})

    return {
      fetch () {
        return fetchRequest.fetch()
          .then(data => data.map(item => (
            new DataSourceResponse(item, chunkHash[item.id])
          )))
      },
      abort () {
        return fetchRequest.abort()
      },
    }
  }

  getSensorsStat (chunk: SensorStatRequest[]) {
    const fetchRequest = this.api().post('/TimeSeries/Stat', chunk.map(item => item.toJSON()), {
      apiVersion: '1.0',
    })

    return {
      fetch () {
        return fetchRequest.fetch()
          .then(data => chunk.map((item, idx) => (
            new SensorStatResponse(data[idx], item)
          )))
      },
      abort () {
        return fetchRequest.abort()
      },
    }
  }

  getSensorsHistogram (chunk: SensorHistogramRequest[]) {
    const fetchRequest = this.api()
      .post('/TimeSeries/Histogram', chunk.map(item => item.toJSON()), {
        apiVersion: '1.0',
      })

    return {
      fetch () {
        return fetchRequest.fetch()
          .then(data => chunk.map((item, idx) => (
            new SensorHistogramResponse(data[idx], item)
          )))
      },
      abort () {
        return fetchRequest.abort()
      },
    }
  }

  getPlotlyChart (sources) {
    const isBase64 = sources.some(item => item.responseType === 'base64')

    const fetchRequest = this.api()
      .post('/TimeSeries/Plotly', sources, {
        apiVersion: '1.0',
      })

    return {
      fetch () {
        return fetchRequest.fetch(isBase64)
          .then(image => (isBase64 ? { image } : image))
      },
      abort () {
        return fetchRequest.abort()
      },
    }
  }

  getFileDownloadLink (data) {
    return this.init()
      .then(() => this.api().get(`/Files/downloadLink/${data.id}`).fetch(true))
  }

  getFileRawText (data) {
    return this.init()
      .then(() => this.api().get(`/Files/rawText/${data.id}`).fetch(true))
  }

  notebookPreviewRequest (id) {
    return this.api()
      .get(`/Notebooks/htmlPreview/${id}`, null, {
        headers: {
          'Content-Type': 'text/plain; charset=utf-8',
        },
      })
  }

  notebookExportRequest (id) {
    return this.api()
      .post('/Notebooks/exportToFile', {
        exportNotebookId: id,
      })
  }

  getNotifications ({
    hardwareId,
    includeAcknowledged = true,
    from,
    to,
    offset,
    limit,
  } = {}) {
    return this.init()
      .then(() => this.api().get('/Notifications', {
        hardwareId,
        includeAcknowledged,
        from: from && DateTime.fromMillis(from).setZone('utc').toISO(),
        to: to && DateTime.fromMillis(to).setZone('utc').toISO(),
        offset,
        limit,
      }).fetch())
      .then(data => data.map(item => new Notification(item)))
  }

  setNotificationAsk (id, acknowledged) {
    return this.init()
      .then(() => this.api()
        .post(`/Notifications/Ack?notificationId=${id}&state=${acknowledged}`).fetch())
  }

  getFileImportAutoDetection (importId, fileId) {
    return this.init()
      .then(() => this.api()
        .post(`/FileImports/${importId}/files/${fileId}/auto-detection`).fetch())
  }
}

export const wizataApi = new WizataApi(client)
