import { useContext, forwardRef } from 'react'
import cx from 'classnames'
import { FormSwitch } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { auth } from '@/auth'
import BusinessLabelList from '@/hoc/BusinessLabelList'
import Select from '@/components/Form/Select'
import FormTemplates from '@/components/Form/ListTemplates'
import ListFilters from '@/components/Form/ListFilters'
import { AppContext } from '@/pages/context'

const dashboardTypes = [
  {
    value: 'grafana',
    name: 'Grafana',
    id: 'grafana',
  },
  {
    value: 'dashboard',
    name: 'Dashboard',
    id: 'dashboard',
  },
]

const Filters = forwardRef(({
  hasTemplateId,
}, ref) => {
  const intl = useIntl()
  const { filters, onFilter } = useContext(AppContext)

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="me-1 mb-2" style={{ width: '22%' }}>
            <Select
              placeholder={intl.t('form.fields.typePlaceholder')}
              options={dashboardTypes}
              onChange={type => onFilter({ type })}
              value={filters.type}
              className="form-select-sm"
            />
          </div>
          <div className="mx-1 mb-2" style={{ width: '22%' }}>
            <Select
              placeholder={intl.t('form.fields.labelsPlaceholder')}
              ListSource={BusinessLabelList}
              value={filters.labelId}
              onChange={labelId => onFilter({ labelId })}
              className="form-select-sm"
            />
          </div>
          {hasTemplateId ? null : (
            <div className="mx-1 mb-2 col-md-3">
              <FormTemplates
                placeholder={intl.t('form.actions.templatesPlaceholder')}
                value={filters.templateId}
                onChange={templateId => onFilter({ templateId })}
                className="form-select-sm"
              />
            </div>
          )}
          <div className="ms-1 mb-2 d-flex align-items-center">
            <FormSwitch
              checked={filters.ownerId === auth.currentUser.id}
              onChange={owner => onFilter({ ownerId: owner ? auth.currentUser.id : undefined })}
            />
            <span className="fs-7">
              {intl.t('form.actions.owner')}
            </span>
          </div>
        </>
      )}
    </ListFilters>
  )
})

export default Filters
