import { useMemo } from 'react'
import { useMobile, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'

export default function GraphToolbar ({
  dashboard,
  onAction,
}) {
  const isMobile = useMobile()
  const auth = useAuth()
  const intl = useIntl()

  const options = useMemo(() => {
    const items = []
    if (auth.checkAccessUpdate(dashboard)) {
      items.push(
        {
          id: 'settings',
          icon: 'fa--edit',
          name: intl.t('twin.rightMenu.edit'),
        },
        {
          id: 'widgets',
          icon: 'fa--shapes',
          name: intl.t('dashboards.form.fields.widgets'),
        },
      )
    }
    // if (auth.checkAccessShare(dashboard)) {
    //   items.push({
    //     id: 'share',
    //     icon: 'fa--share',
    //     name: intl.t('dashboards.form.fields.share'),
    //   })
    // }

    // items.push({
    //   id: 'dashboards',
    //   icon: 'fa--th-large',
    //   name: intl.t('dashboards.title'),
    // })

    return items
  }, [ dashboard, auth, intl ])

  return isMobile ? (
    <header className="d-flex align-items-center px-3 py-2">
      {options.map(item => (
        <button
          key={item.id}
          type="button"
          className="btn btn-flat-secondary btn-text"
          onClick={() => onAction(item)}
        >
          <Icon name={item.icon} />
        </button>
      ))}
    </header>
  ) : null
}
