import {
  forwardRef, useRef, useCallback, useState, 
} from 'react'
import classnames from 'classnames'
import {
  FormSelectDateTimeRange, DropdownMenu,
} from '@wiz/components'
import FormatDateTime from '@/containers/FormatDateTime'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'
import { useRouter, useLocationQuery } from '@/router'
import Icon from '@/shared/icon'
import Breadcrumb from './breadcrumb'
import s from '../index.module.css'

const dashboardTypes = [
  {
    value: 'grafana',
    name: 'Grafana',
    id: 'grafana',
  },
  {
    value: 'dashboard',
    name: 'Dashboard',
    id: 'dashboard',
  },
  {
    value: 'iframe',
    name: 'Iframe',
    id: 'iframe',
  },
]

const viewButtonByType = {
  all: {
    icon: 'faEye',
    next: 'personal',
    nextTtl: 'personal only',
  },
  personal: {
    icon: 'faUser',
    next: 'org',
    nextTtl: 'organizational',
  },
  org: {
    icon: 'faSitemap',
    next: 'all',
    nextTtl: 'all',
  },
}

const Topbar = forwardRef(({
  options = [], dashboard, formData, onSaveFormData, onAdd, isEdit, onEditing, list, ...props
}, ref) => {
  const refMenu = useRef()
  const refMenuTarget = useRef()

  const { isReadOnly } = useAuthComponents()
  const { view, ...query } = useLocationQuery()
  const router = useRouter()
  const defaultValues = [dashboard?.defaultFrom, dashboard?.defaultTo]
  const [isTouched, setIsTouched] = useState(false);
  const [userValue, setUserValue] = useState([null, null])


  const handleViewChange = useCallback(() => {
    const nextView = viewButtonByType[view || 'all'].next
    const next = { ...query }
    if (nextView === 'personal') {
      const db = options.find(opt => !!opt.ownerId)
      if (db) {
        next.category = db.labelId
        next.sub = db.id
      }
    } else if (nextView === 'org') {
      const orgList = list.filter(item => !item.ownerId).sort((a, b) => a.order - b.order)
      const withSameCategory = orgList.find(item => item.labelId === query.category)
      if (withSameCategory) {
        next.category = withSameCategory.labelId
        next.sub = withSameCategory.id
      } else {
        next.category = orgList[0].labelId
        next.sub = orgList[0].id
      }
    } else {
      const db = options[0]
      next.category = db.labelId
      next.sub = db.id
    }
    router.replace({ query: { ...next, view: nextView } })
  }, [ view, router, query, options, list ])

  return (
    <header
      className={classnames('d-flex align-items-center border-bottom p-2 bg-light')}
    >

      <Breadcrumb
        options={options}
        onEditing={onEditing}
        isEdit={isEdit}
        {...props}
        // className="d-flex flex-fill"
      >
        {isReadOnly ? null : (
          <button
            ref={refMenuTarget}
            type="button"
            className="btn btn-flat-secondary btn-text"
            disabled={!query.twinId}
          >
            <Icon name="faPlus" />
          </button>
        )}

        {isEdit ? (
          <button
            type="button"
            className={classnames('btn btn-flat-secondary btn-text', s.editBtn)}
            onClick={() => onEditing(false)}
            title="Stop editing"
          >
            <Icon type="solid" name="faSquare" />
          </button>
        ) : null}
      </Breadcrumb>

      {/* {options.length ? <div className="border-start rh-4 flex-shrink-0 mx-2" /> : null } */}
      {/*
      {isReadOnly ? null : (
        <div className="d-flex align-items-center flex-shrink-0 ms-auto me-2 mb-auto mt-1">
          <FormSwitch
            checked={isEdit}
            onChange={onEditing}
          />
          <span className={classnames({ 'text-muted': !isEdit })}>
            {intl.t('form.actions.edit')}
          </span>
        </div>
      )} */}

      <div className="d-flex mb-auto ms-auto">
        {isReadOnly ? null : (
          <button
            type="button"
            className={classnames('btn btn-flat-secondary btn-text me-2', s.viewBtn)}
            onClick={handleViewChange}
            title={`See ${viewButtonByType[view || 'all']?.nextTtl}`}
          >
            <Icon name={viewButtonByType[view || 'all']?.icon} />
          </button>
        )}
        {dashboard?.type !== 'Dashboard' ? (
          <FormSelectDateTimeRange
            value={(
              formData.dateFrom && formData.dateTo ?
                [ formData.dateFrom, formData.dateTo ] :
                [ null, null ]
            )}
            DateTimeComponent={FormatDateTime}
            size="sm"
            clearable
            showArrows
            isRenderWithDefault
            defaultValues={defaultValues}
            userValue={userValue}
            setUserValue={setUserValue}
            isTouched={isTouched}
            setIsTouched={setIsTouched}
            disabled={!!formData.stepRequest || !options.length}
            onChange={([ dateFrom, dateTo ]) => {
              onSaveFormData({ dateFrom, dateTo })
            }}
          />
        ) : null}
      </div>
      <DropdownMenu
        ref={refMenu}
        target={refMenuTarget}
        arrow
        placement="bottom-start"
        draggable
        autoclose
        options={dashboardTypes}
        onClick={onAdd}
        // onDragStart={handleDragStart}
        // onDragEnd={handleDragEnd}
      />
    </header>
  )
})

export default Topbar
