import {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
} from 'react'
import classnames from 'classnames'
import { useRouter, useLocation, useLocationQuery } from '@/router'
import {
  Dialog,
  Icon as LegacyIcon,
  withResizeObserver,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'

const LeftBar = forwardRef(({
  disabled,
  options,
  value,
  onChange,
  getWidth,
}, ref) => {
  const refNode = useRef(null)
  const intl = useIntl()

  const router = useRouter()
  const query = useLocationQuery()
  const loc = useLocation()
  const [ ,,, currentPage ] = loc.pathname.split('/')

  const id = value?.id
  const menuOptions = options.filter(item => !item.hidden)

  const handleClick = (item) => {
    if (query.tree) {
      onChange(item.id, true)
    } else {
      router.push({ name: item.path })
    }
  }

  useEffect(() => {
    if (refNode.current) {
      getWidth(refNode.current.offsetWidth)
    }
  }, [ getWidth ])

  return menuOptions.map((item, idx) => {
    const isDisabled = Boolean(disabled && item.canDisable)
    const className = classnames('btn btn-secondary-alt py-2 ps-3 d-flex justify-content-start', {
      active: (item.id === id && !isDisabled) || currentPage === item.route,
    })

    return (
      <div
        key={item.id}
        type="button"
        title={intl.t(item.title)}
        className={classnames(className, {
          disabled: currentPage !== 'items' && !item.path,
        })}
        onClick={() => handleClick(item)}
        aria-hidden
      >
        <LegacyIcon name={item.icon} className="me-2" size="md" />
        <span className="fill-flex text-start text-truncate">
          {intl.t(item.title)}
        </span>
        {currentPage === 'items' && item.path ? (
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-text ms-auto"
            title={intl.t('t/menu.description.openList')}
            onClick={(e) => {
              e.stopPropagation()
              router.push({ name: item.path })
            }}
          >
            <Icon name="faTableList" />
          </button>
        ) : null}
      </div>
    )
  })
})

const LeftBarResize = withResizeObserver(LeftBar)

const LeftBarMenu = forwardRef(({
  refDialog,
  container = null,
  disabled,
  pinned,
  options,
  selectedProps,
  value,
  width,
  onClose,
  onChange,
  onPin,
  expanded,
  expandedClose,
  selectedSensorsCount,
  ...rest
}, ref) => {
  const intl = useIntl()
  const refDialogElement = useRef(null)
  const refLeftBar = useRef(null)
  const {
    back,
    props,
    id: dialogId,
    component: DialogComponent,
    title,
    dialogTitle,
    withGlobalFiltersButton,
    showSelectedCount,
  } = value || {}

  const onChangeBack = useMemo(() => (
    back && back !== dialogId ? () => onChange(back) : undefined
  ), [ back, dialogId, onChange ])

  const ComponentTitle = useMemo(() => ({
    children,
  }) => (
    <>
      {onChangeBack ? (
        <button
          type="button"
          className="btn btn-flat-secondary me-2"
          onClick={onChangeBack}
        >
          <Icon name="fa--chevron-left" size="md" />
        </button>
      ) : null}

      <h1 className="h4 flex-fill m-0">
        {children}
      </h1>
    </>
  ), [ onChangeBack ])

  const getRightBarWidth = useCallback((rbWidth) => {
    refLeftBar.current = rbWidth
  }, [])

  return (
    <>
      <Dialog
        ref={refDialog}
        refDialog={refDialogElement}
        classNameBody="p-0 overflow-hidden"
        className="shadow-none"
        container={container}
        strategy="absolute"
        orient="left"
        backdrop={false}
        withGlobalFiltersButton={withGlobalFiltersButton}
        showSelectedCount={showSelectedCount}
        selectedSensorsCount={selectedSensorsCount}
        pinned
        expanded={expanded}
        expandedClose={expandedClose}
        size="sm"
        opened={Boolean(DialogComponent)}
        title={intl.t(dialogTitle || title)}
        ComponentTitle={ComponentTitle}
        resize
        left={refLeftBar.current}
        dataTestid={`${dialogId}LeftBarDialog`}
        onClose={onClose}
        onPin={onPin}
      >
        {DialogComponent ? (
          <DialogComponent
            {...props}
            {...selectedProps}
            container={container}
            onChangeBack={onChangeBack}
          />
        ) : null}
      </Dialog>
      <LeftBarResize
        refResize={refDialogElement}
        disabled={disabled}
        options={options}
        value={value}
        width={width}
        onChange={onChange}
        blocks={selectedProps.blocks}
        getWidth={getRightBarWidth}
      />
    </>
  )
})

export default withResizeObserver(LeftBarMenu)
