import {
  useRef,
  useState,
  useMemo,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import { useMobile } from '@wiz/components'
import { auth } from '@/auth'
import RightBarWidgets from '@/containers/Dashboards/RightBarWidgets'
import RightBarMenu from '@/components/RightBarFixedMenu'

const RightBar = forwardRef(({
  dashboard,
  setEditId,
  setLeftBarExpanded,
  scrollToWidget,
}, ref) => {
  const refDialog = useRef(null)
  const [ value, setValue ] = useState(null)
  const isMobile = useMobile()

  const options = useMemo(() => {
    const items = []
    if (auth.checkAccessUpdate(dashboard)) {
      items.push(
        {
          id: 'settings',
          icon: 'fa--edit',
          title: 't/twin.rightMenu.edit',
          hidden: isMobile,
        },
        {
          id: 'widgets',
          icon: 'fa--shapes',
          title: 't/dashboards.form.fields.widgets',
          component: RightBarWidgets,
          hidden: isMobile,
          props: {
            onSuccessAttachWidget: (widget) => {
              if (isMobile) {
                refDialog.current?.close()
              }

              scrollToWidget?.(widget)
            },
          },
        },
      )
    }
    // if (auth.checkAccessShare(dashboard)) {
    //   items.push({
    //     id: 'share',
    //     icon: 'fa--share',
    //     title: 't/dashboards.form.fields.share',
    //     hidden: isMobile,
    //   })
    // }

    // items.push({
    //   id: 'dashboards',
    //   icon: 'fa--th-large',
    //   title: 't/dashboards.title',
    //   hidden: isMobile,
    // })

    return items
  }, [ dashboard, isMobile, scrollToWidget ])

  const handleChange = useCallback((id) => {
    if (id === 'settings' || id === 'share') {
      setEditId(dashboard.id)
    } else if (id === 'dashboards') {
      setLeftBarExpanded(true)
    } else if (id === value?.id) {
      refDialog.current?.close()
    } else {
      setValue(options.find(item => item.id === id))
    }
  }, [ setEditId, setLeftBarExpanded, dashboard, options, value ])

  const handleClose = useCallback(() => {
    setValue(null)
  }, [])

  useImperativeHandle(ref, () => ({
    open: handleChange,
    close: handleClose,
  }), [ handleChange, handleClose ])

  return (
    <RightBarMenu
      refDialog={refDialog}
      value={value}
      options={options}
      selectedProps={{ dashboard }}
      onClose={handleClose}
      onChange={handleChange}
    />
  )
})

export default RightBar
