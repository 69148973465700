import {
  useState, useMemo, useEffect,
} from 'react'
import withTableActions from '@/containers/withTableActions'
import { useIntl } from '@wiz/intl'
import Link from '@/components/Link'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import FormTemplate from '@/components/Forms/Template'
import { consts } from '@wiz/utils'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import useAppContext from '@/hooks/useAppContext'
import { useQuery } from '@tanstack/react-query'
import {
  VirtualTable, Pagination, withSort, withPagination,
} from '@wiz/components'
import Icon from '@/shared/icon'
import EditButton from '@/shared/editButton'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  key: 'key',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const Table = ({
  data, columns, selected, filters, ...props
}) => {
  const intl = useIntl()
  const {
    onRowSelect,
  } = useAppContext()

  if (props.loading && !data) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light opacity-50">
        <Icon name="faSpinner" size="xl" spin />
      </div>
    )
  }

  return (
    <>
      <VirtualTable
        {...props}
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={data?.items}
        items={data?.items}
        filters={filters}
        onSelect={onRowSelect}
      />

      <Pagination
        {...props}
        total={data?.totalCount}
        items={data?.items}
        filters={filters}
      />
    </>
  )
}

const TableComponent = withSort()(
  withPagination()(Table),
)

const Templates = ({ filters, ...props }) => {
  const [ editId, setEditId ] = useState(null)

  const {
    onRowSelect,
    filters: contextFilters,
  } = useAppContext()

  const auth = useAuth()
  const intl = useIntl()

  const {
    data: templatesList, isLoading, refetch, isRefetching,
  } = useQuery({
    queryKey: [ 'templatesPagedList' ],
    queryFn: () => {
      // wizataApi.templates.getList('false') // using string false as a bypass
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize
      const filtersArr = [
        {
          name: 'key',
          type: consts.FilterType.Text,
          operationType: consts.FilterOperationType.Contains,
          value: filters.search,
        },
      ]

      if (contextFilters.templateId) {
        filtersArr.push({
          name: 'Id',
          type: consts.FilterType.Guid,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.templateId,
        })
      }

      return wizataApi.templatesCore.getList({
        pagination: {
          take: limit - 1,
          skip: offset || 0,
        },
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
        filters: filtersArr.map(({
          name, value, values, operationType, type, canBeNull,
        }) => {
          if (!value && !values) {
            return undefined
          }

          const next = {
            type,
            operationType,
            // start: 'string',
            // end: 'string',
            propertyName: name,
            canBeNull,
          }

          if (value) {
            next.value = value
          } else if (values.length) {
            next.values = values
          }

          return {
            ...next,
          }
        }).filter(f => !!f),
      })
        .then((data) => {
          const { items } = data || {}
          const _items = items.map(template => (
            { ...template, properties: template.properties ? JSON.parse(template.properties) : template.properties }))
          return { ...data, items: _items }
        })
    },
    refetchOnWindowFocus: false,
  })

  const handleEdit = (templateId) => {
    setEditId(templateId)
  }

  const handleCloseDialog = () => {
    setEditId(null)
  }

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      resetCellStyle: true,
      Cell: ({ cell }) => (auth.checkAccessUpdate('Template') ? (
        <EditButton cell={cell} onEdit={handleEdit} />
      ) : null),
    },
    {
      Header: intl.t('form.fields.key'),
      accessor: 'key',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 240,
      Cell: ({ cell, row }) => (cell.value ? (
        <div className="text-truncate min-w-0">
          <Link
            name="template"
            params={{ id: row.original.id }}
            className="text-reset text-truncate min-w-0"
          >
            {cell.value}
            <span />
          </Link>
        </div>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      disableResizing: true,
      minWidth: 200,
      Cell: ({ row, cell }) => (
        <div className="text-truncate min-w-0">
          <Link
            name="template"
            params={{ id: row.original.id }}
            className="text-reset text-truncate min-w-0"
          >
            {cell.value}
            <span />
          </Link>
        </div>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
  ], [ intl, auth ])

  useEffect(() => {
    if (templatesList && !isRefetching) {
      refetch()
    }
  }, [
    filters.search,
    contextFilters.templateId,
    // contextTwinId,
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
  ])

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          {...props}
          placeholder={intl.t('errors.noDataDisplay')}
          columns={columns}
          data={templatesList}
          filters={filters}
          loading={isLoading || isRefetching}
        />

        {editId !== null ? (
          <FormTemplate
            id={editId}
            onClose={handleCloseDialog}
            onUpdate={refetch}
            dialog={{
              title: intl.t('templates.form.fields.title'),
              dataTestid: 'replaceTemplateDialog',
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

export default withTableActions({
  defaultFilters () {
    return {
      sortBy: 'updatedDate',
      sortDir: 'desc',
    }
  },
})(
  Templates,
)
