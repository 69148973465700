import {
  Fragment, forwardRef, useEffect, useMemo,
} from 'react'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import {
  useFieldArray, Controller, useFormContext,
} from 'react-hook-form'
import classnames from 'classnames'
import {
  FormSection,
  FormFieldInline,
  Icon,
  withObservables,
  FormField,
  FormControl,
  FormSelectDateTime,
} from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import { get, has } from '@wiz/utils'
import { dbProvider, Q } from '@wiz/store'
import FormatDateTime from '@/containers/FormatDateTime'
import SelectTwin from '@/components/Form/SelectTwin'
import Select from '@/components/Form/Select'
import { testJSON } from '@/utils/json'
import s from './index.module.css'

const SectionRatio = [ 9, 3 ]

const defaultProperty = {
  datapoint: undefined,
  datetime: undefined,
  float: undefined,
  integer: undefined,
  json: undefined,
  name: undefined,
  relative: undefined,
  string: undefined,
}

const byType = {
  integer: 'number',
  float: 'number',
  relative: 'text',
  string: 'text',
}

const enhanceData = withObservables([
  'sensorIds',
  // 'value',
], ({
  sensorIds,
  // value,
}) => {
  let query = dbProvider.database.collections.get('sensors').query()

  if (sensorIds?.length) {
    query = query.extend(Q.where('id', Q.oneOf(sensorIds)))
  }

  return {
    options: query.observeWithColumns([ 'updated_at' ]),
    // value: value ? dbProvider.database.collections.get('sensors')
    //   .query(Q.where('hardware_id', value))
    //   .observeWithColumns([ 'updated_at' ])
    //   .pipe(map(items => items.map(item => item.id))) : of(undefined),
  }
})

const ListSensors = forwardRef(({
  id, disabled, options, ...props
}, ref) => (
  <Select
    {...props}
    invalid={props.invalid}
    options={options}
    disabled={disabled}
    withTwinFiltering
  />
))

const FormListSensors = enhanceData(ListSensors)

const Registrations = ({
  isLoading, isUpdating, registration, properties, id,
}) => {
  const intl = useIntl()
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    watch,
    getValues,
  } = useFormContext()

  const {
    fields,
  } = useFieldArray({
    name: 'properties',
    keyName: '_id',
    shouldUnregister: true,
  })

  const { data, isFetching } = useQuery({
    queryKey: [ 'registrationProperties', registration?.id ],
    queryFn: () => wizataApi.registrations.getProperties(registration?.id),
    enabled: !!registration?.id,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const twinId = watch('registration.twinId')

  // const handleChange = useCallback(async (data, item) => {
  //   console.log(data)
  //   const sensor = await dbProvider.database.collections.get('sensors').query(Q.where('id', data)).fetch()
  //   properties?.find((prop, idx) => {
  //     if (prop.name === item.name) {
  //       setValue(`registration.properties.${idx}.name`, item.name, { shouldDirty: true })
  //       setValue(`registration.properties.${idx}.sensorId`, sensor[0]?.hardwareId, { shouldDirty: true })
  //       clearErrors(`registration.properties.${idx}.sensorId`)
  //     }
  //   })
  // }, [ properties, setValue, clearErrors ])

  useEffect(() => {
    reset({
      properties,
      registration: {
        twinId: registration?.twinId,
        properties: properties?.map((_prop) => {
          const val = data?.find(item => item.templatePropertyId === _prop.id)

          return {
            ...defaultProperty,
            ..._prop,
            sensorId: val?.sensorId,
            name: _prop.name,
            [_prop.type]: val?.[_prop.type],
          }
        }),
      },
    })
    if (!registration && properties?.length && !data?.length && !fields?.length) {
      reset({
        properties,
        registration: {
          twinId: undefined,
          properties: properties?.map(_prop => ({
            ...defaultProperty,
            ..._prop,
          })),
        },
      })
    }
  }, [ data, registration, properties ])

  if (isLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light opacity-50">
        <Icon name="fa--spinner" size="2X" spin />
      </div>
    )
  }

  return (
    <>
      <FormSection>
        <FormField
          label={intl.t('form.fields.twin')}
          description={intl.t('templates.form.fields.twinDescr')}
          errors={get(errors, 'registration.twinId')}
        >
          <Controller
            name="registration.twinId"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <SelectTwin
                {...field}
                disabled={!!registration}
                invalid={fieldState.invalid}
                placeholder={intl.t('form.fields.twinPlaceholder')}
              />
            )}
          />
        </FormField>
      </FormSection>
      <FormSection
        ratio={SectionRatio}
        title={intl.t('templates.form.fields.properties')}
        description={intl.t('templates.form.fields.propertiesDescr')}
      >
        <FormFieldInline complex>
          <div className="px-2 mt-2 min-h-0 flex-fill h-100 w-100">
            {/* {!isLoading && !options?.length ? (
                <div className="list-group-item text-center">
                  {intl.t('form.info.propertiesNotFound')}
                </div>
              ) : null} */}
            {fields?.map((item, idx) => {
              const scopeRow = `registration.properties.${idx}`
              const error = (
                get(errors, `${scopeRow}.${item.type}`)
              )

              return (
                <Fragment
                  key={item.name}
                >
                  <div
                    className={classnames('list-group-item py-2 px-0 d-flex align-items-center', {
                      'is-invalid': !!error,
                    })}
                  >
                    <span className="text-truncate text-muted w-50">
                      {`${item.name} (${item.type})`}
                    </span>

                    {item.type === 'json' ? (
                      <FormControl
                        type="code"
                        className={classnames(s.code, 'ms-2 w-50')}
                        required={item.required}
                        name={`${scopeRow}.${item.type}`}
                        lang="json"
                        rules={{
                          validate: (value) => {
                            const str = String(value || '').trim()
                            if (str.length && !testJSON(str)) {
                              return intl.t('form.errors.mustBeJson')
                            }
                            if (value && !str.length) {
                              return intl.t('form.errors.fieldRequired')
                            }
                            if (value?.length > 8000) {
                              return intl.t('form.errors.fieldMaxlen', { max: 8000 })
                            }
                            return true
                          },
                        }}
                      />
                    ) : null }
                    {item.type === 'datapoint' ? (
                      <Controller
                        name={`${scopeRow}.sensorId`}
                        rules={{ required: item.required }}
                        render={({ field, fieldState }) => (
                          <FormListSensors
                            {...field}
                            invalid={fieldState.invalid}
                            placeholder="--"
                            // onChange={data => handleChange(data, item)}
                            className={classnames('ms-2 w-50')}
                            required={item.required}
                            twinId={twinId}
                          />
                        )}
                      />
                    ) :
                      null}
                    {item.type === 'datetime' ? (
                      <Controller
                        name={`${scopeRow}.${item.type}`}
                        rules={{ required: item.required }}
                        render={({ field, fieldState }) => (
                          <FormSelectDateTime
                            {...field}
                            invalid={fieldState.invalid}
                            future={false}
                            DateTimeComponent={FormatDateTime}
                            className={classnames('ms-2 w-50')}
                            required={item.required}
                          />
                        )}
                      />

                    ) : null}

                    {[ 'integer', 'float', 'string', 'relative' ].includes(item.type) ? (
                      <input
                        {...register(`${scopeRow}.${item.type}`, {
                          validate: (value) => {
                            const str = String(value || '').trim()
                            if (item?.required && !str.length) {
                              return intl.t('form.errors.fieldRequired')
                            }
                            if (value?.length > 450) {
                              return intl.t('form.errors.fieldMaxlen', { max: 450 })
                            }
                            return true
                          },
                        })}
                        type={byType[item.type] || 'search'}
                        className={classnames('form-control w-50 ms-2', {
                          'ms-2': has(errors, `${scopeRow}.${item.type}`),
                        })}
                      />
                    ) : null}

                  </div>
                  {error ? (
                    <div className="invalid-feedback m-0">
                      {error.message}
                    </div>
                  ) : null}
                </Fragment>
              )
            })}
            {isLoading ? (
              <div className="list-group-item text-center">
                <Icon
                  className="ms-2"
                  name="fa--spinner"
                  spin
                />
              </div>
            ) : null}
            {!isLoading && !properties?.length ? (
              <div className="list-group-item text-center">
                {intl.t('form.info.propertiesNotFound')}
              </div>
            ) : null}
          </div>
        </FormFieldInline>
      </FormSection>
      {isUpdating || isFetching ? (
        <div className="position-absolute-fill position-center-fill bg-light opacity-50">
          <Icon name="fa--spinner" size="2X" spin />
        </div>
      ) : null}
    </>
  )
}

const FormRegistrations = ({
  dialog, onClose, onRefetch, id, registration, registrations, ...props
}) => {
  const queryClient = useQueryClient()

  const { data: properties, isFetching } = useQuery({
    queryKey: [ 'templateProperties', registration?.twinId ],
    queryFn: () => wizataApi.templates.getRegistrationPropertiesById(id).then((res) => {
      if (Array.isArray(res)) {
        return res
      }

      return undefined
    }),
    enabled: !!id,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const {
    mutateAsync: createRegistration,
    isLoading: createLoading,
  } = useMutation({
    mutationKey: [ 'createRegistration', id ],
    mutationFn: data => wizataApi.registrations.create(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/registrations.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: (data) => {
      events.emit('app:notify', {
        type: 'success',
        title: 't/registrations.createSuccess',
        duration: 5000,
      })

      queryClient.invalidateQueries([ 'templateRegistrations', id ])
    },
  })

  const {
    mutateAsync: updateRegistration,
    isLoading: updateLoading,
  } = useMutation({
    mutationKey: [ 'updateRegistration', id ],
    mutationFn: data => wizataApi.registrations.update(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/registrations.updateError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      events.emit('app:notify', {
        type: 'success',
        title: 't/registrations.updateSuccess',
        duration: 5000,
      })

      queryClient.invalidateQueries([ 'templateRegistrations', id ])
      queryClient.invalidateQueries([ 'registrationProperties', registration?.id ])
    },
  })

  const onSubmit = async ({ registration: item }) => {
    const next = {
      id: registration?.id,
      payload: {
        twinId: item.twinId,
        templateId: id,
        properties: item.properties.map((p, idx) => {
          const n = {
            ...defaultProperty,
            ...p,
            templatePropertyId: p.id,
            twinRegistrationId: registration?.id,
            integer: p.integer ? +p.integer : undefined,
            float: p.float ? +p.float : undefined,
            datetime: p.datetime || undefined,
            sensorId: properties[idx]?.type !== 'datapoint' ? null : p.sensorId,
          }

          delete n.type
          delete n.required
          delete n.id

          const vals = Object.values(n).filter(v => !!v)

          if (p.json && typeof p.json === 'string') {
            n.json = JSON.parse(p.json)
          }

          if (vals.length < 2) {
            if (Object.keys(p).includes(properties[idx]?.type) && !!p[properties[idx]?.type]) {
              n.name = properties[idx].name
              return n
            }
            return undefined
          }
          return n
        }).filter(pr => !!pr),
      },
    }

    if (registration) {
      await updateRegistration(next)
    } else {
      const { properties } = next.payload
      delete next.payload.properties
      const { id } = await createRegistration(next)
      await updateRegistration({
        id, payload: { ...next.payload, properties: properties.map(p => ({ ...p, twinRegistrationId: id })) },
      })
    }
  }

  const item = useMemo(() => {
    if (registration) {
      const next = registrations.find(
        reg => reg.name === registration.name && reg.twinId === registration.twinId,
      )

      return next
    }
    return undefined
  }, [ registration, registrations ])

  // const defaultValues = useMemo(() => ({
  //   properties,
  //   registration: {
  //     twinId: item?.twinId,
  //     properties: properties?.map((_prop) => {
  //       const val = item?.properties?.find(p => p.name === _prop.name)
  //       // if (!val) {
  //       //   return undefined
  //       // }

  //       return {
  //         ...defaultProperty,
  //         name: _prop.name,
  //         [_prop.type]: val?.[_prop.type],
  //       }
  //     }).filter(p => !!p),
  //   },
  // }), [ properties, item ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      // defaultValues={defaultValues}
      dataTestid="registrationForm"
      dialog={dialog}
    >
      <Registrations
        {...props}
        id={id}
        properties={properties}
        registration={item}
        isLoading={isFetching}
        isUpdating={createLoading || updateLoading}
      />
    </FormDialog>
  )
}

export default FormRegistrations
