import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import union from 'lodash/union'
import { Icon, FormInputSearch, useDrag } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { toArrayValue } from '@wiz/utils'
import { Sensor } from '@wiz/store'
import ListSensorsByTwin from '@/containers/Form/ListSensorsByTwin'
import BulkSensorOperations from '@/containers/Form/BulkSensorOperations'
import FormSensor from '@/containers/Forms/Sensor'
import AttachSensors from './AttachSensors'

const DefaultDraggable = data => (Sensor.is(data.payload || data) ? 'true' : undefined)

export default function RightBarInfo ({
  businessType,
  blockSettings,
  sensorIds,
  onChange,
  onAttachSensors,
  onToggleByTwin,
  onToggleAll,
  onChangeSelection,
  onBeforeAction,
  onUnlinkSensors,
  setAttachedSensorsCount,
}) {
  const [ search, setSearch ] = useState('')
  const [ selectedBlockSettings, setSelectedBlockSettings ] = useState(null)
  const [ scrollTo, setScrollTo ] = useState(null)
  const [ isEdit, setEdit ] = useState(false)

  const intl = useIntl()
  const drag = useDrag()

  const handleAttachSensors = useCallback((twin, index) => {
    setSelectedBlockSettings(twin)
    setScrollTo(index)
  }, [])

  const handleCreateCommand = useCallback((data) => {
    onChange('createCommand', {
      props: data,
    })
  }, [ onChange ])

  const handleInputSensorValue = useCallback((data) => {
    onChange('inputSensorValue', {
      props: data,
    })
  }, [ onChange ])

  const handleSuccess = useCallback((action, data) => {
    if (action === 'streamJob') {
      onChange('streamJob', {
        title: data.name,
        props: {
          id: data.id,
          withTitle: false,
          withBack: false,
        },
      })
    }
  }, [ onChange ])

  const handleDragStart = useCallback((data, twin) => {
    const ids = toArrayValue(data).map(item => (item.payload || item).id)
    drag.context.id = twin.id
    union(sensorIds, ids).forEach(id => drag.data.add(id))
  }, [ drag, sensorIds ])

  const handleDragEnd = useCallback(() => {
    drag.clear()
  }, [ drag ])

  if (selectedBlockSettings) {
    return (
      <AttachSensors
        businessType={businessType}
        selectedBlockSettings={selectedBlockSettings}
        onAttachSensors={onAttachSensors}
        onResetSelectedBlockSettings={() => setSelectedBlockSettings(null)}
        setAttachedSensorsCount={setAttachedSensorsCount}
      />
    )
  }

  return (
    <>
      <div className="d-flex flex-wrap mt-2 mx-n1 px-3">
        <div className="mx-1 mb-2 flex-fill">
          <FormInputSearch
            size="sm"
            placeholder={intl.t('form.actions.searchPlaceholder')}
            onChange={setSearch}
          />
        </div>

        <div className="d-flex mx-1 mb-2">
          <BulkSensorOperations
            className="btn-sm btn-fill-secondary me-2"
            sensorIds={sensorIds}
            onBeforeAction={onBeforeAction}
            onSuccess={handleSuccess}
          />

          <button
            type="button"
            className="btn btn-sm btn-fill-secondary text-nowrap"
            disabled={!sensorIds?.length}
            onClick={() => {
              onChange('explorer', {
                title: intl.t('twin.rightMenu.datapoints'),
                props: {
                  sensorIds,
                },
              })
            }}
          >
            <Icon name="fa--search" className="me-1" />
            {intl.t('form.actions.exploreData')}
          </button>
        </div>
        <div className="d-flex mx-1 mb-2">
          <button
            type="button"
            className="btn btn-sm btn-fill-secondary text-nowrap me-2"
            disabled={!sensorIds?.length}
            onClick={onUnlinkSensors}
          >
            <Icon name="fa--unlink" className="me-1" />
            {intl.t('form.actions.unlinkSensors')}
          </button>
        </div>
      </div>

      <ListSensorsByTwin
        className="d-flex flex-fill"
        search={search}
        twinsRequired
        withSensors
        defaultCheckedAll
        draggable={DefaultDraggable}
        businessType={businessType}
        blockSettings={blockSettings}
        scrollTo={scrollTo}
        value={sensorIds}
        showDescription={false}
        onChange={onChangeSelection}
        onAttachSensors={handleAttachSensors}
        onCreateCommand={handleCreateCommand}
        onInputSensorValue={handleInputSensorValue}
        onToggleByTwin={onToggleByTwin}
        onToggleAll={onToggleAll}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onEdit={setEdit}
        setAttachedSensorsCount={setAttachedSensorsCount}
      />
      {isEdit ? (
        <FormSensor
          id={isEdit}
          onClose={() => setEdit(null)}
          dialog={{
            title: intl.t('sensors.titleUpdate'),
            dataTestid: 'replaceSensorDialog',
          }}
        />
      ) : null}
    </>
  )
}

RightBarInfo.propTypes = {
  blockSettings: PropTypes.object,
  sensorIds: PropTypes.array,
  businessType: PropTypes.string,
  onAttachSensors: PropTypes.func,
  onChange: PropTypes.func,
  onToggleByTwin: PropTypes.func,
  onChangeSelection: PropTypes.func,
  onBeforeAction: PropTypes.func,
}

RightBarInfo.defaultProps = {
  blockSettings: undefined,
  sensorIds: undefined,
  businessType: undefined,
  onAttachSensors: undefined,
  onChange: undefined,
  onToggleByTwin: undefined,
  onChangeSelection: undefined,
  onBeforeAction: undefined,
}
