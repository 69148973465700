import { useMemo, useRef } from 'react'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import FormDialog from '@/components/Form/FormDialog'
import { Icon } from '@wiz/components'
import { useRouter } from '@/router'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import { testJSON } from '@/utils/json'
import Section from './Section'

export default function Experiment ({
  dialog,
  onClose,
  options: {
    pipeline,
    experiment,
    experimentId,
  },
}) {
  const refForm = useRef()

  const router = useRouter()
  const queryClient = useQueryClient()

  const {
    mutate: run,
    isLoading: isRunLoading,
  } = useMutation({
    mutationKey: [ 'runExecuteFromPipeline', pipeline?.id ],
    mutationFn: params => wizataApi.executions.create(params, params.experimentId ? undefined : 'production'),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/execute.deleteError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: (res) => {
      events.emit('app:notify', {
        type: 'success',
        title: 't/execute.experiment',
        message: 't/execute.experiment.runExperiment',
        duration: 5000,
      })

      onClose()

      queryClient.invalidateQueries({ queryKey: [ 'executions', res.experimentId ] })
      if (res.experimentId && res.experimentId !== experimentId) {
        router.push({ name: 'experiments-list', params: { id: res.experimentId } })
      } else if (!res.experimentId) {
        router.push({ name: 'executions-list' })
      }
    },
  })

  const handleSubmit = async (data) => {
    const variables = data.variables.reduce((acc, val) => {
      acc[val.key] = val[val.value]
      return acc
    }, {})
    const isJSON = testJSON(data.properties)
    const properties = isJSON ? JSON.parse(data.properties) : {}
    const next = {
      properties: {
        now: data.timestamp,
        ...variables,
        ...properties,
      },
      twinId: data.execute.twinId,
      pipelineId: pipeline?.id,
      experimentId: data.execute.experimentId || experimentId,
    }
    await refForm.current?.submit()

    // onRun('d50ea560-9d03-4ac3-9ad1-a02e230a403f')

    await run(next)
  }
  const defaultValues$ = useMemo(() => ({
    pipeline,
    experiment,
    execute: { experimentId },
    timestamp: Date.now(),
    variables: Object.entries(pipeline?.variables)?.map(([ key, value ]) => ({ key, value })),
  }), [ pipeline, experiment, experimentId ])

  if (isRunLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light-alt opacity-50">
        <Icon name="fa--spinner" size="2X" spin />
      </div>
    )
  }

  // if (id && !item) {
  //   return <Removed />
  // }
  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues$}
      dataTestid="executeForm"
      dialog={dialog}
      submitText="form.actions.execute"
      noCancelChanges
    >
      <Section scope="execute" />
    </FormDialog>
  )
}
