import { forwardRef, useMemo, useState } from 'react'
import { useMobile } from '@wiz/components'
import { useAuth } from '@/auth'
import LeftBarDiagram from '@/components/LeftBarDiagram'
import LeftBarInfo from '@/containers/LeftBarInfo'
import LeftBarLibrary from '@/containers/LeftBarLibrary'
import LeftBarConnections from '@/containers/LeftBarConnections'

export const LeftBarItems = [
  {
    id: 'items',
    icon: 'fa--twin-items-add',
    title: 't/twin.leftmenu.itemlibrary',
    canDisable: true,
    component: LeftBarLibrary,
    access: auth => auth.checkAccessManage('SectionDigitalTwinSensors'),
  },
  {
    id: 'sensors',
    icon: 'wiz--menu--datapoint',
    title: 't/twin.leftmenu.datapoints',
    name: 't/twin.leftmenu.datapoints',
    attr: {
      id: 'sensors',
    },
    canDisable: true,
    withGlobalFiltersButton: true,
    showSelectedCount: true,
    component: LeftBarInfo,
    access: auth => auth.checkAccessManage('SectionDigitalTwinSensors'),
    path: 'sensors-list',
    route: { name: 'sensors' },
  },
  // {
  //   id: 'connections',
  //   icon: 'fa--connections-add',
  //   title: 't/twin.leftmenu.connections',
  //   canDisable: true,
  //   component: LeftBarConnections,
  //   // access: auth => auth.checkAccessManage('SectionDigitalTwinSensors'),
  // },
]

const LeftBarTwin = forwardRef((props, ref) => {
  const auth = useAuth()
  const isMobile = useMobile()
  const [ selectedSensorsCount, setSelectedSensorsCount ] = useState(0)
  const options = useMemo(() => (
    LeftBarItems
      .filter(item => (!item.access || item.access(auth)))
      .map(item => ({
        ...item,
        hidden: isMobile || item.hidden,
      }))
  ), [ auth, isMobile ])

  return (
    <LeftBarDiagram
      ref={ref}
      selectedSensorsCount={selectedSensorsCount}
      setSelectedSensorsCount={setSelectedSensorsCount}
      {...props}
      options={options}
    />
  )
})

export default LeftBarTwin
