import { useFormContext } from 'react-hook-form'
import {
  FormFieldInline, FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { get } from '@wiz/utils'
import { testJSON } from '@/utils/json'

export default function FieldsProperties () {
  const intl = useIntl()
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <FormFieldInline
      label={intl.t('pipelines.form.fields.executeProperties')}
      description={intl.t('pipelines.form.fields.executePropertiesDescr')}
      errors={get(errors, 'properties')}
      vertical
      complex
    >
      <FormControl
        type="code"
        title={intl.t('pipelines.form.fields.executeProperties')}
        description={intl.t('pipelines.form.fields.executePropertiesDescr')}
        name="properties"
        lang="json"
        rules={{
          validate: (value) => {
            const str = String(value || '').trim()
            if (str.length && !testJSON(str)) {
              return intl.t('form.errors.mustBeJson')
            }
            // const str = String(value || '').trim()
            // if (!str.length) {
            //   return intl.t('form.errors.fieldRequired')
            // }
            if (value.length > 20000) {
              return intl.t('form.errors.fieldMaxlen', { max: 20000 })
            }
            return true
          },
        }}
      />
    </FormFieldInline>
  )
}
