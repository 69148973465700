import {
  useState, useCallback, useRef,
} from 'react'
import { useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import Twin from '@/hoc/Twin'
import FormTwin from '@/containers/Forms/Twin'
import FormTwinBulkEdit from '@/containers/Forms/TwinBulkEdit'
import Table from '@/containers/Twin/Table'
import TableMobile from '@/containers/Twin/TableMobile'
import Graph from '@/containers/Twin/Graph'
import RightBar from '@/containers/Twin/Graph/RightBar'
import ViewModel from './ViewModel'
import TableToolbar from './TableToolbar'

const ViewTwin = Twin(ViewModel)

export default function List ({
  filters,
  selected,
  selectedIds,
  setSelectedIds,
  onFilter,
  onResetFilter,
  onRemove,
  onSelect,
  onUnlinkParent,
  ...props
}) {
  const intl = useIntl()
  const isMobile = useMobile()
  const [ editId, setEditId ] = useState(null)
  const [ bulkEdit, setBulkEdit ] = useState(false)
  const [ viewId, setViewId ] = useState(null)
  const [ isRightBarOpen, setRightBarOpen ] = useState(false)
  const refRightBar = useRef()

  const handleClose = useCallback(() => {
    setEditId(null)
    setBulkEdit(false)
    setViewId(null)
  }, [])

  const handleAdd = useCallback(() => {
    setEditId('')
  }, [])

  const handleBulkEdit = useCallback(() => {
    setBulkEdit(true)
  }, [])

  const handleRightBarOpen = useCallback(() => {
    setRightBarOpen(!isRightBarOpen)
  }, [ isRightBarOpen ])

  const handleChangeViewMode = useCallback((value) => {
    setSelectedIds([])
    onSelect([])
    onFilter({ view: value })
  }, [ onSelect, onFilter, setSelectedIds ])

  const TableComponent = isMobile ? TableMobile : Table

  // useImperativeHandle(ref, () => ({
  //   openRightBar (name) {
  //     refRightBar.current.open(name)
  //   },
  // }), [])

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0">
        {do {
          if (filters.view === consts.ViewMode.Tree) {
            <Graph
              selected={selected}
              selectedIds={selectedIds}
              filters={filters}
              onFilter={onFilter}
              onSelect={setSelectedIds}
              onRemove={onRemove}
              onAdd={handleAdd}
              onBulkEdit={handleBulkEdit}
              onChangeViewMode={handleChangeViewMode}
            />
          } else {
            <div
              id="twin-list-container"
                // style={{ width: 'calc(100% - 5rem)' }}
              className="d-flex flex-column flex-fill position-relative overflow-hidden pt-3"
            >
              <TableComponent
                filters={filters}
                onFilter={onFilter}
                onRemove={onRemove}
                onEdit={setEditId}
                onSelect={onSelect}
                onUnlinkParent={onUnlinkParent}
                onView={setViewId}
                {...props}
              />

              {isRightBarOpen ? (
                <RightBar
                  ref={refRightBar}
                  container="#twin-list-container"
                  blockIds={selected.map(({ id }) => id)}
                  setEditBlockId={setEditId}
                />
              ) : null}
            </div>
          }
        }}
      </div>

      {bulkEdit ? (
        <FormTwinBulkEdit
          twins={selected}
          twinIds={selectedIds}
          onClose={handleClose}
          dialog={{
            title: intl.t('twin.titleBulkEdit'),
            dataTestid: 'bulkEditTwinDialog',
          }}
        />
      ) : null}

      {editId ? (
        <FormTwin
          id={editId}
          onClose={handleClose}
          dialog={{
            title: intl.t('twin.titleUpdate'),
            dataTestid: 'replaceTwinDialog',
          }}
        />
      ) : null}

      {!editId && editId !== null ? (
        <FormTwin
          id={editId}
          onClose={handleClose}
          onSuccess={(model) => {
            onFilter({ tree: model.id })
          }}
          dialog={{
            title: intl.t('twin.titleCreate'),
            dataTestid: 'replaceTwinDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewTwin
          id={viewId}
          title={intl.t('twin.titleDetail')}
          onClose={handleClose}
          onUnlinkParent={onUnlinkParent}
          onRemove={onRemove}
          onEdit={setEditId}
          dialog
        />
      ) : null}
    </div>
  )
}
