import pako from 'pako'

// const STEPS = {
//   name: 'name',
//   datapoints: 'datapoints',
//   timeRange: 'timeRange',
//   connectionsList: 'connectionsList',
//   filters: 'filters',
//   target: 'target',
// }

const STEPS = {
  name: 'name',
  dpSelect: 'dp_select',
  target: 'target',
  timeAgg: 'time_agg',
  connections: 'connections',
  filters: 'filters',
  exProperties: 'ex_properties',
}

const ERRORS = {
  name: {
    name: 't/experiments.form.errors.name',
  },
  datapoints: 't/experiments.form.errors.datapoints',
  timeRange: {
    dateFrom: 't/experiments.form.errors.dateFrom',
    dateTo: 't/experiments.form.errors.dateTo',
    aggMethod: 't/experiments.form.errors.method',
    interval: 't/experiments.form.errors.interval',
  },
  connectionsList: 't/experiments.form.errors.connections',
  filters: 't/experiments.form.errors.filters',
  sensitivity: 't/experiments.form.errors.sensitivity',
  target: {
    sensor: 't/experiments.form.errors.sensor',
    operator: 't/experiments.form.errors.operator',
    threshold: 't/experiments.form.errors.threshold',
  },
}

export const validateExperimentStep = async (step, data, onError) => {
  // step + data we need to validate. if error, onError, else true
  const validate = (key) => {
    onError(prev => ({ ...prev, [key]: '' }))
  }

  switch (step) {
    case STEPS.name: { // in: string
      const {
        name,
      } = data
      const errors = {}

      if (!name) {
        errors.name = ERRORS.name.name
      }

      if (Object.values(errors).length) {
        onError(prev => ({ ...prev, name: errors }))
        return false
      }

      validate(STEPS.name)
      return true
    }
    case STEPS.dpSelect: { // in: [string]
      const isValid = !!data?.length

      if (isValid) {
        validate(STEPS.datapoints)
        return true
      }
      onError(prev => ({ ...prev, datapoints: ERRORS.datapoints }))
      return false
    }
    case STEPS.timeAgg: { // {dateFrom, dateTo, aggMethod, interval}
      const {
        dateFrom, dateTo, aggMethod, interval,
      } = data
      const errors = {}

      if (!dateFrom) {
        errors.dateFrom = ERRORS.timeRange.dateFrom
      }
      if (!dateTo) {
        errors.dateTo = ERRORS.timeRange.dateTo
      }
      if (!aggMethod?.length) {
        errors.aggMethod = ERRORS.timeRange.aggMethod
      }
      if (!interval) {
        errors.interval = ERRORS.timeRange.interval
      }

      if (Object.values(errors).length) {
        onError(prev => ({ ...prev, timeRange: errors }))
        return false
      }

      validate(STEPS.timeRange)
      return true
    }
    case STEPS.target: {
      const {
        sensor, operator, threshold,
      } = data || {}
      const errors = {}

      if (!sensor) {
        errors.sensor = ERRORS.target.sensor
      }
      if (!operator) {
        errors.operator = ERRORS.target.operator
      }
      if (!threshold) {
        errors.threshold = ERRORS.target.threshold
      }

      if (Object.values(errors).length) {
        onError(prev => ({ ...prev, target: errors }))
        return false
      }

      validate(STEPS.target)
      return true
    }

    default:
      onError({})
      return true
  }
}

export const decodeBytes = (string) => {
  // decode the base64 encoded data
  if (!string) {
    return ''
  }

  const gzipedData = atob(string)
  const gzipedDataArray = Uint8Array.from(gzipedData, c => c.charCodeAt(0))

  const ungzipedData = pako.ungzip(gzipedDataArray)
  const decoder = new TextDecoder()
  const data = decoder.decode(ungzipedData)

  return data
}
