import { useFormContext, Controller } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import {
  FormField, FormSelectDateTime,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import Select from '@/components/Form/Select'
import SelectTwin from '@/components/Form/SelectTwin'
import FormatDateTime from '@/containers/FormatDateTime'
import { get, consts } from '@wiz/utils'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const {
    watch, formState: { errors },
  } = useFormContext()
  const experimentId = watch('experimentId')
  const pipeline = watch('pipeline')

  const { data: experiments, isLoading } = useQuery({
    queryKey: [ 'experimentsByPipeline', pipeline.id ],
    queryFn: () => wizataApi.entities.getList('experiments', {
      pagination: {
        take: 100,
        skip: 0,
      },
      sortingList: [

      ],
      filters: [{
        propertyName: 'pipelineId',
        type: consts.FilterType.Guid,
        operationType: consts.FilterOperationType.Equals,
        value: pipeline.id,
      }],
    }),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!pipeline.id,
  })

  const {
    data: registrations,
    isLoading: registrationsLoading,
  } = useQuery({
    queryKey: [ 'templateRegistrations', pipeline?.templateId ],
    queryFn: () => wizataApi.registrations.getList(pipeline?.templateId),
    enabled: !!pipeline?.templateId,
    retry: false,
    refetchOnWindowFocus: false,
  })

  return (
    <>
      <FormField
        label={intl.t('form.fields.experiment')}
        description={intl.t('form.fields.experimentDescr')}
        errors={get(errors, `${scope}.experimentId`)}
      >
        <Controller
          name={`${scope}.experimentId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              disabled={!!experimentId}
              isLoading={isLoading}
              options={experiments?.items}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.twin')}
        errors={get(errors, `${scope}.twindId`)}
      >
        <Controller
          name={`${scope}.twinId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
              filters={{
                withLocation: true,
              }}
              disabled={!pipeline?.templateId || registrationsLoading}
              twinIds={registrations?.map(reg => reg.twinId)}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('pipelines.form.fields.now')}
        description={intl.t('pipelines.form.fields.nowDescr')}
      >
        <Controller
          name="timestamp"
          render={({ field, fieldState }) => (
            <FormSelectDateTime
              {...field}
              invalid={fieldState.invalid}
              name="timestamp"
              future={false}
              DateTimeComponent={FormatDateTime}
            />
          )}
        />
      </FormField>
    </>
  )
}
