import { Controller, useFormContext } from 'react-hook-form'
import {
  FormSection,
  FormField,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'

export default function Section ({
  scope,
  title,
  description,
}) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
    clearErrors,
  } = useFormContext()

  const [
    minInterval,
    maxInterval,
  ] = watch([
    `${scope}.minInterval`,
    `${scope}.maxInterval`,
  ])

  return (
    <FormSection
      title={title}
      description={description}
    >
      <FormField
        label={intl.t('widgets.sensor.form.fields.sensor')}
        description={intl.t('widgets.sensor.form.fields.sensorDescr')}
        errors={get(errors, `${scope}.sensorId`)}
      >
        <Controller
          name={`${scope}.sensorId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.sensor.form.fields.minInterval')}
        description={intl.t('widgets.sensor.form.fields.minIntervalDescr')}
        errors={get(errors, `${scope}.minInterval`)}
      >
        <FormControl
          type="number"
          name={`${scope}.minInterval`}
          scale={1}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            validate: {
              max: value => (
                value < maxInterval ||
                intl.t('form.errors.fieldMax', { max: maxInterval })
              ),
            },
          }}
          onChange={() => {
            clearErrors(`${scope}.maxInterval`)
          }}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.sensor.form.fields.maxInterval')}
        description={intl.t('widgets.sensor.form.fields.maxIntervalDescr')}
        errors={get(errors, `${scope}.maxInterval`)}
      >
        <FormControl
          type="number"
          name={`${scope}.maxInterval`}
          scale={1}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            validate: {
              min: value => (
                value > minInterval ||
                intl.t('form.errors.fieldMin', { min: minInterval })
              ),
            },
          }}
          onChange={() => {
            clearErrors(`${scope}.minInterval`)
          }}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.sensor.form.fields.precision')}
        description={intl.t('widgets.sensor.form.fields.precisionDescr')}
        errors={get(errors, `${scope}.precision`)}
      >
        <FormControl
          type="number"
          name={`${scope}.precision`}
          rules={{
            validate: {
              min: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) >= 0 ||
                intl.t('form.errors.fieldMin', { min: 0 })
              ),
              max: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) <= 15 ||
                intl.t('form.errors.fieldMax', { max: 15 })
              ),
            },
          }}
          min={0}
          max={15}
          step={1}
        />
      </FormField>
    </FormSection>
  )
}
