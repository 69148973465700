import {
  forwardRef,
  useEffect,
  useReducer,
  useMemo,
  useContext,
  createContext,
  useState,
  useCallback,
} from 'react'
import { omit } from '@wiz/utils'
import { matchPath, useLocation, useLocationQuery } from '@/router'
import { appEnv } from '@/config'
import { auth } from '@/auth'

const API_URL = process.env.WIZATA_API_BASE_URL && new URL(process.env.WIZATA_API_BASE_URL).origin
const BASE_URL = String(process.env.WIZATA_API_BASE_URL).replace('[apiVersion]', '1.0')

function reducer (state, action) {
  switch (action.type) {
    case 'setLocalFilters':
      return {
        ...state,
        localFilters: {
          ...state.localFilters,
          ...action.payload,
        },
      }
    case 'resetLocalFilters':
      return {
        ...state,
        localFilters: action.payload ?
          omit(state.localFilters, action.payload) : null,
      }
    case 'saveScrollPosition':
      return {
        ...state,
        [action.payload.entity]: {
          scrollPosition: action.payload.scrollPosition,
        },
      }
    case 'routeChange':
      return {
        ...state,
        router: {
          prev: action.payload,
        },
      }
    default:
      return state
  }
}

const GlobalExecute = createContext({})

export const useGlobalExecute = () => useContext(GlobalExecute)

export const withGlobalExecute = contextModifier => WrappedComponent => forwardRef((props, ref) => {
  const context = useGlobalExecute()

  useEffect(() => (
    contextModifier(context, props)
  ), [ props ])

  return (
    <WrappedComponent
      ref={ref}
      {...props}
    />
  )
})

export function GlobalExecuteProvider ({ children }) {
  const location = useLocation()
  const query = useLocationQuery()
  const [ twinId, setTwinId ] = useState()
  const [ isOpenTwinSelector, setIsOpenTwinSelector ] = useState(false)
  const [ selectedComponent, setSelectedComponent ] = useState({})
  const [ backendVersion, setBackendVersion ] = useState(undefined)
  const [ user, setUser ] = useState({ id: '', avatar: '' })
  const [ users, setUsers ] = useState({})

  const [ state, dispatch ] = useReducer(reducer, {
    filters: null,
    localFilters: null,
    boards: {
      scrollPosition: 0,
    },
    router: {
      prev: location.pathname,
    },
  })

  const loadAvatar = async (id) => {
    const token = await auth.acquireToken()
    const AVA_ENDPOINT = `${BASE_URL}/Users/${id}/image`

    return fetch(AVA_ENDPOINT, {
      method: 'GET',
      headers: {
        Authorization: `BEARER ${token}`,
      },
    }).then((response) => {
      if (!response?.ok) {
        return Promise.reject()
      }
      return response.blob()
    })
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      }))
      .catch(err => console.warn('Error Image', err))
  }

  const handleTwinChange = useCallback((id) => {
    // setSelectedComponent({})
    setTwinId(id)
  }, [])

  const initAvatar = useCallback(async () => {
    const avatar = await loadAvatar(auth.currentUser.id)
    if (avatar) {
      setUser({ avatar })
    }
  }, [ ])

  const getAvatars = useCallback(async (data) => {
    const usersSet = new Set()
    const usersObj = {}

    data.forEach(item => usersSet.add(item.createdById).add(item.updatedById))

    for (const id of [ ...usersSet ]) {
      if (!users[id]) {
        const ava = await loadAvatar(id)
        if (ava) {
          usersObj[id] = ava
        }
      }
    }

    setUsers(prev => ({ ...prev, ...usersObj }))
  }, [ users ])

  useEffect(() => {
    if (query.tree && !twinId) {
      setTwinId(query.tree)
    }
  }, [])

  useEffect(() => {
    if (!Object.keys(users)?.length) {
      initAvatar()
    }
  }, [])

  useEffect(() => {
    if (!backendVersion && API_URL) {
      const versionUrl = API_URL + appEnv.VERSION_URL
      fetch(versionUrl)
        .then(res => res.text())
        .then(setBackendVersion)
    }
  }, [])

  useEffect(() => {
    const { pathname } = location
    // TODO: We need to think and decide how to handle different cases, eg. boards, dashboards
    const match = matchPath(pathname, {
      path: '/:lang?/twin/chart/:id',
    })
    dispatch({ type: 'routeChange', payload: pathname })

    if (!match) {
      dispatch({ type: 'saveScrollPosition', payload: { entity: 'boards', scrollPosition: 0 } })
    }
  }, [ location ])

  useEffect(() => {
    if (!twinId && !!query?.contextTwinId) {
      setTwinId(query.contextTwinId)
    }
  }, [])

  const context = useMemo(() => ({
    filters: {
      ...state.localFilters,
      ...state.filters,
    },
    boards: {
      ...state.boards,
    },
    user,
    setUser,
    users,
    setUsers,
    loadAvatar,
    contextTwinId: twinId,
    handleTwinChange,
    isOpenTwinSelector,
    setIsOpenTwinSelector,
    selectedComponent,
    setSelectedComponent,
    setLocalFilters: payload => dispatch({ type: 'setLocalFilters', payload }),
    resetLocalFilters: payload => dispatch({ type: 'resetLocalFilters', payload }),
    saveScrollPosition: payload => dispatch({ type: 'saveScrollPosition', payload }),
    backendVersion,
    getAvatars,
  }), [
    state,
    dispatch,
    backendVersion,
    user,
    users,
    twinId,
    handleTwinChange,
    isOpenTwinSelector,
    setIsOpenTwinSelector,
    selectedComponent,
    setSelectedComponent,
    getAvatars,
  ])

  return (
    <GlobalExecute.Provider value={context}>
      {children}
    </GlobalExecute.Provider>
  )
}
