import { useEffect, useMemo } from 'react'
import { switchMap, map } from 'rxjs/operators'
import { withObservables, withProps } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { consts, toArrayValue } from '@wiz/utils'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { of } from 'rxjs'

function observeSensors ({
  autoInputValue,
  batchOnly,
  businessType,
  canInputValue,
  categories,
  edgeSensorHardwareIds,
  inputMode,
  withInputMode,
  keyName,
  outputAutoInputValue,
  rawQuery,
  search,
  selectedOnly,
  sensorIds,
  labelIds,
  twinIds,
  labels,
  units,
  unusedOnly,
  value,
  noData,
}) {
  if (noData) {
    return of([])
  }

  const currentKeyName = keyName || 'id'
  const collection = dbProvider.database.collections.get('sensors')
  let query = collection.query()

  if (sensorIds?.length) {
    query = query.extend(Q.where('id', Q.oneOf(toArrayValue(sensorIds))))
  }

  if (
    edgeSensorHardwareIds?.length &&
    !outputAutoInputValue
  ) {
    query = query.extend(Q.where('hardware_id', Q.oneOf(toArrayValue(edgeSensorHardwareIds))))
  }

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
      Q.where('hardware_id', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  if (selectedOnly) {
    const key = currentKeyName === 'hardwareId' ? 'hardware_id' : currentKeyName
    query = query.extend(Q.where(key, Q.oneOf(toArrayValue(value))))
  }

  if (units?.length) {
    query = query.extend(Q.where('unit_id', Q.oneOf(toArrayValue(units))))
  }

  if (labels?.length) {
    query = query.extend(
      Q.experimentalJoinTables([ 'rel_sensors_labels' ]),
      Q.and(
        Q.on('rel_sensors_labels', 'label_id', Q.oneOf(labels)),
      ),
    )
  }

  if (categories?.length) {
    query = query.extend(Q.where('category_id', Q.oneOf(toArrayValue(categories))))
  }

  if (twinIds?.length) {
    query = query.extend(Q.where('twin_id', Q.oneOf(toArrayValue(twinIds))))
  }

  if (batchOnly) {
    query = query.extend(Q.where('batch', true))
  }

  if (unusedOnly) {
    query = query.extend(Q.where('twin_id', null))
  }

  if (labelIds?.length) {
    query = query.extend(Q.on('rel_sensors_labels', 'label_id', Q.oneOf(toArrayValue(labelIds))))
  }

  if (businessType) {
    let businessTypes = toArrayValue(businessType)
    if (canInputValue) {
      businessTypes = businessTypes.filter(item => consts.SensorBusinessTypesInput.includes(item))
    }

    if (businessTypes.length) {
      query = query.extend(Q.where('business_type', Q.oneOf(businessTypes)))
    }
  }

  if (inputMode) {
    const inputModes = toArrayValue(inputMode)
    if (inputModes.length) {
      query = query.extend(Q.where('input_mode', Q.oneOf(inputModes)))
    }
  }

  if (withInputMode) {
    query = query.extend(
      Q.where('input_mode', Q.oneOf([
        consts.SensorInputMode.Manual,
        consts.SensorInputMode.Automatic,
        consts.SensorInputMode.ManualAndAutomatic,
      ])),
    )
  }

  if (canInputValue === true) {
    query = query.extend(Q.and(
      Q.where('business_type', Q.oneOf(consts.SensorBusinessTypesInput)),
      Q.where('input_mode', Q.oneOf(consts.SensorManualInputModes)),
    ))
  } else if (canInputValue === false) {
    query = query.extend(Q.or(
      Q.where('business_type', Q.notIn(consts.SensorBusinessTypesInput)),
      Q.where('input_mode', Q.notIn(consts.SensorManualInputModes)),
    ))
  }

  if (
    autoInputValue === true ||
    outputAutoInputValue === true
  ) {
    query = query.extend(Q.or(
      outputAutoInputValue && edgeSensorHardwareIds?.length ? (
        Q.and(
          Q.where('business_type', consts.SensorBusinessType.SetPoint),
          Q.where('input_mode', Q.oneOf([
            consts.SensorInputMode.Automatic,
            consts.SensorInputMode.ManualAndAutomatic,
          ])),
          Q.where('hardware_id', Q.oneOf(toArrayValue(edgeSensorHardwareIds))),
        )
      ) : (
        Q.and(
          Q.where('business_type', consts.SensorBusinessType.SetPoint),
          Q.where('input_mode', Q.oneOf([
            consts.SensorInputMode.Automatic,
            consts.SensorInputMode.ManualAndAutomatic,
          ])),
        )
      ),
      Q.and(
        Q.where('business_type', consts.SensorBusinessType.Measurement),
        Q.where('input_mode', Q.oneOf([
          consts.SensorInputMode.Automatic,
          consts.SensorInputMode.ManualAndAutomatic,
        ])),
      ),
      Q.and(
        Q.where('business_type', consts.SensorBusinessType.Logical),
        Q.where('input_mode', Q.oneOf([
          consts.SensorInputMode.Automatic,
          consts.SensorInputMode.ManualAndAutomatic,
        ])),
      ),
    ))
  }

  if (rawQuery) {
    query = query.extend(rawQuery)
  }

  const observe = selectedOnly ?
    query.observeWithColumns([ 'updated_at' ]) :
    query.observe()

  return observe
}

function observeEdgeEndpointNodes ({
  edges,
}) {
  // eslint-disable-next-line react/jsx-indent
  return dbProvider.database.collections.get('edge_endpoint_nodes')
    .query(
      Q.experimentalNestedJoin(
        'edge_endpoints',
        'edge_modules',
      ),
      Q.on(
        'edge_endpoints',
        Q.on(
          'edge_modules',
          Q.where('edge_id', Q.oneOf(toArrayValue(edges))),
        ),
      ),
    )
    .observe()
    .pipe(
      map(items => items.map(item => item.nodeId).filter(Boolean)),
    )
}

export const enhanceGlobalContext = withProps(({ edges }) => {
  const context = useGlobalExecute()
  return {
    ...context.filters,
    edges: edges?.length ? edges : context.filters?.edges,
  }
})

export const enhanceData = withObservables([
  'autoInputValue',
  'batchOnly',
  'businessType',
  'canInputValue',
  'categories',
  'edges',
  'inputMode',
  'keyName',
  'outputAutoInputValue',
  'rawQuery',
  'search',
  'selectedOnly',
  'twinIds',
  'units',
  'labels',
  'unusedOnly',
  'value',
], ({
  edges,
  ...props
}) => {
  let observe

  if (edges?.length) {
    observe = observeEdgeEndpointNodes({ edges })
      .pipe(
        switchMap(edgeSensorHardwareIds => observeSensors({
          ...props,
          edgeSensorHardwareIds,
        })),
      )
  } else {
    observe = observeSensors(props)
  }

  return {
    options: observe,
  }
})

export const enhanceList = withProps(({
  options,
  important,
  setVisibleOptions,
}) => {
  const next = useMemo(() => (
    important?.length ? important.concat(options) : options
  ), [ options, important ])

  useEffect(() => {
    const nextIds = next.map(opt => opt.id)
    setVisibleOptions?.(nextIds)
  }, [ options, next ])

  return {
    options: next,
  }
})

export default WrappedComponent => enhanceGlobalContext(
  enhanceData(
    enhanceList(WrappedComponent),
  ),
)
