import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import { toArrayValue } from '@wiz/utils'

const enhanceData = withObservables([ 'contextFilters', 'items' ], ({ contextFilters, items }) => {
  if (items) {
    return { items, twins: items }
  }

  let query = dbProvider.database.collections.get('stream_jobs').query()

  if (contextFilters.search) {
    const sanitizeSearch = Q.sanitizeLikeString(contextFilters.search)
    query = query.extend(Q.or(
      Q.where('id', contextFilters.search),
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  if (contextFilters.type) {
    query = query.extend(Q.where('type', contextFilters.type))
  }

  if (contextFilters.devices?.length) {
    query = query.extend(Q.where('device_id', Q.oneOf(toArrayValue(contextFilters.devices))))
  }

  if (contextFilters.twins?.length) {
    query = query.extend(Q.on(
      'rel_twins_stream_jobs',
      Q.where('twin_id', Q.oneOf(toArrayValue(contextFilters.twins))),
    ))
  }

  return {
    items: query.observeWithColumns([ 'updated_at' ]),
  }
})

export default enhanceData
