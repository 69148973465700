import { useCallback, useState, useEffect } from 'react'
import { isEqual } from '@wiz/utils'
import { useDidUpdate } from '@wiz/components'
import { useRouter, useLocationQuery } from '@/router'

export default function useFilters ({
  initFilters,
  defaultFilters,
  source,
  setSource,
} = {}) {
  const router = useRouter()
  const query = useLocationQuery()
  const sourceData = source || query
  const [ filters, setFilters ] = useState(initFilters(sourceData))
  const [ publicFilters, setPublicFilters ] = useState(filters)

  const onFilter = useCallback((data) => {
    const next = initFilters({ ...filters, ...data })
    if (!isEqual(filters, next)) {
      setFilters(next)
    }
  }, [ filters, initFilters ])

  const forceUpdate = useCallback((data = {}) => {
    setPublicFilters({ ...publicFilters, ...data })
  }, [ publicFilters ])

  const onResetFilter = useCallback((data) => {
    setFilters(initFilters({ ...data }))
  }, [ initFilters ])

  useEffect(() => {
    const nextFilters = initFilters(sourceData)
    if (!isEqual(filters, nextFilters)) {
      setFilters(nextFilters)
    }
  // no "filters" in dependence, take current version after update "sourceData"
  }, [ initFilters, sourceData ])

  useDidUpdate(() => {
    setPublicFilters(filters)

    const defaultData = defaultFilters?.()
    const next = Object.keys(filters)
      .filter(item => (item !== '_' && filters[item] !== defaultData?.[item]))
      .reduce((out, item) => ({ ...out, [item]: filters[item] }), {})

    if (setSource) {
      setSource(next)
    } else {
      router.replace({ query: next })
    }
  }, [ JSON.stringify(filters) ])

  return {
    filters: publicFilters,
    onFilter,
    onResetFilter,
    forceUpdate,
  }
}
