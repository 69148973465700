import { Controller, useFormContext } from 'react-hook-form'
import { get, consts } from '@wiz/utils'
import { FormField, FormFieldInline, FormControl } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import SelectDataView from '@/components/Form/SelectDataView'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectSensor from '@/components/Form/SelectSensor'
import StreamJobList from '@/hoc/StreamJobList'

export default function Fields ({ scope, dataViews }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 50) {
                return intl.t('form.errors.fieldMaxlen', { max: 50 })
              }
              return true
            },
          }}
          placeholder={intl.t('form.fields.namePlaceholder')}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.color')}
        errors={get(errors, `${scope}.color`)}
      >
        <FormControl
          type="color"
          name={`${scope}.color`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          alpha
        />
      </FormFieldInline>

      <FormField
        label={intl.t('widgets.events.form.fields.dataViews')}
        description={intl.t('widgets.events.form.fields.dataViewsDescr')}
        errors={get(errors, `${scope}.dataViews`)}
      >
        <Controller
          name={`${scope}.dataViews`}
          render={({ field, fieldState }) => (
            <SelectDataView
              {...field}
              invalid={fieldState.invalid}
              options={dataViews}
              placeholder={intl.t('widgets.events.form.fields.dataViewsPlaceholder')}
              multiple
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.events.form.fields.statuses')}
        description={intl.t('widgets.events.form.fields.statusesDescr')}
        errors={get(errors, `${scope}.statuses`)}
      >
        <FormControl
          type="select"
          name={`${scope}.statuses`}
          placeholder={intl.t('events.form.fields.statusPlaceholder')}
          options={consts.EventStatuses.map(id => ({
            id,
            name: intl.t(`enum.eventStatus.${id}`),
          }))}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.events.form.fields.streamJobId')}
        description={intl.t('widgets.events.form.fields.streamJobIdDescr')}
        errors={get(errors, `${scope}.streamJobId`)}
      >
        <Controller
          name={`${scope}.streamJobId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.streamJobPlaceholder')}
              ListSource={StreamJobList}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.events.form.fields.twinId')}
        description={intl.t('widgets.events.form.fields.twinIdDescr')}
        errors={get(errors, `${scope}.twinId`)}
      >
        <Controller
          name={`${scope}.twinId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('events.form.fields.includeChildren')}
        description={intl.t('events.form.fields.includeChildrenDescr')}
        errors={get(errors, `${scope}.includeChildEvents`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.includeChildEvents`}
        />
      </FormFieldInline>

      <FormField
        label={intl.t('form.fields.dataPoints')}
        description={intl.t('widgets.events.form.fields.sensorIdsDescr')}
        errors={get(errors, `${scope}.sensorIds`)}
      >
        <Controller
          name={`${scope}.sensorIds`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              multiselect
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.events.form.fields.eventTypes')}
        description={intl.t('widgets.events.form.fields.eventTypesDescr')}
        errors={get(errors, `${scope}.eventTypes`)}
      >
        <FormControl
          type="select"
          name={`${scope}.eventTypes`}
          placeholder={intl.t('events.form.fields.typePlaceholder')}
          options={consts.EventTypes.map(id => ({
            id,
            name: intl.t(`enum.eventType.${id}`),
          }))}
        />
      </FormField>
    </>
  )
}
