import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTheme } from '@/theme'
import DataExplorer from '@/context/DataExplorer'
import DataExplorerExecute from '@/context/DataExplorerExecute'
import SensorChart from '@/containers/SensorChart'

const Chart = forwardRef(({ className }, ref) => {
  const context = useContext(DataExplorer)
  const runContext = useContext(DataExplorerExecute)
  const theme = useTheme()
  const refChart = useRef()

  useImperativeHandle(ref, () => ({
    saveAsImage () {
      refChart.current.saveAsImage()
    },
    exploreValues () {
      refChart.current.exploreValues()
    },
    downloadCSV () {
      refChart.current.downloadCSV({ title: context.explorer.title })
    },
  }), [ context.explorer.title ])

  return (
    <div className={className}>
      <SensorChart
        refChart={refChart}
        name="DataExplorer"
        theme={theme.echarts}
        dataFilter={context.data.dataFilter}
        conditions={context.data.conditions}
        dataSources={context.data.dataSources}
        dataViews={context.data.dataViews}
        eventSources={context.data.eventSources}
        errors={runContext.errors}
        counts={runContext.counts}
        onChangeStepAuto={runContext.setAutoStepApplied}
        onChangeStepDense={runContext.setDenseStepApplied}
        onLoading={runContext.setLoading}
        onToggleSelectedLegend={context.toggleLegend}
        onResizeLegend={context.resizeLegend}
        onChangeViewZoom={context.changeViewZoom}
        onChangeAppliedConditions={runContext.setAppliedConditions}
        onChangeAppliedEvents={runContext.setAppliedEvents}
        onErrors={runContext.setErrors}
        onChangeCounts={runContext.setCounts}
      />
    </div>
  )
})

export default Chart
