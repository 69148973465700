import { useMemo } from 'react'
import { useLocation, useLocationQuery } from '@/router'
import { CorePages, PagesMap } from '@/utils/consts'
import { useIntl } from '@wiz/intl'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import useAppContext from '@/hooks/useAppContext'
import Routes from '@/pages/routes.json'

const routeFinder = (route, id) => {
  // FIXME: Exception here for it, because it's handled by leftbar of twin and not routes
  if (route === PagesMap.Sensors) {
    return {
      name: 't/twin.leftmenu.datapoints',
    }
  }
  if (route === PagesMap.Templates && id) {
    return {
      name: 't/menu.title.registrations',
    }
  }
  for (const routes of Routes) {
    for (const children of routes.children) {
      if (children.children) {
        for (const child of children.children) {
          const _route = [ child.attr.id, child?.route?.name ]
          if (_route.includes(route)) {
            return child
          }
        }
      }
      const _route = [ children.attr.id, children?.route?.name ]
      if (_route.includes(route)) {
        return children
      }
      if (children.actions?.length) {
        for (const action of children.actions) {
          const _route = [ action.attr.id, action?.route?.name ]
          if (_route.includes(route)) {
            return action
          }
        }
      }
    }
  }

  return null
}

const Breadcrumbs = () => {
  const { labels } = useAppContext()
  const { contextTwinId } = useGlobalExecute()
  const intl = useIntl()

  const query = useLocationQuery()
  const loc = useLocation()
  const [ ,, corePage, currentPage, id ] = loc.pathname.split('/')

  const page = useMemo(() => {
    if ([ CorePages.TWIN, CorePages.DESIGN ].includes(corePage)) {
      return routeFinder(currentPage, id)
    }
    if (CorePages.OPERATE && !!contextTwinId) {
      const label = labels?.find(l => l.id === query.category)
      return label
    }
    return null
  }, [ corePage, currentPage, labels, query.category, contextTwinId, id ])

  return <strong className="text-capitalize mt-1 me-4">{intl.t(page?.name || '')}</strong>
}

export default Breadcrumbs
