import { dbProvider, Q } from '@wiz/store'
import { FormSelect, withObservables } from '@wiz/components'

const enhanceData = withObservables([], () => ({
  options: dbProvider.database.collections.get('sensors')
    .query(Q.where('batch', true))
    .observe(),
}))

const SelectBatchDataSource = enhanceData(FormSelect)

SelectBatchDataSource.displayName = 'SelectBatchDataSource'

export default SelectBatchDataSource
