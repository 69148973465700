import { useMemo, useState, useCallback } from 'react'
import { DateTime } from 'luxon'
import { Icon, VirtualTable } from '@wiz/components'
import { precision } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SensorUnit from '@/containers/SensorChart/SensorUnit'
import SensorCategory from '@/containers/SensorChart/SensorCategory'
import FormatDateTime from '@/containers/FormatDateTime'

export default function Dataview ({
  value = [],
  isBatchView,
  isTablet,
  onClose,
}) {
  const intl = useIntl()
  const [ isUtc, setIsUtc ] = useState(false)
  const [ isMs, setIsMs ] = useState(false)
  const columns = useMemo(() => (isTablet ?
    [
      {
        Header: () => (
          <button
            type="button"
            className="btn btn-sm btn-flat-secondary"
            onClick={() => onClose?.()}
          >
            <Icon name="fa--times-circle" />
          </button>
        ),
        accessor: 'id',
        minWidth: 50,
        width: 50,
        maxWidth: 80,
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ row }) => (`#${row.index}.`),
      },
      {
        Header: () => (
          <>
            {intl.t('form.fields.timestamp')}
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary ms-2"
              onClick={() => setIsUtc(!isUtc)}
            >
              <Icon name={isUtc ? 'fa--clock' : 'fa--user-clock'} />
            </button>
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary"
              title=""
              onClick={() => setIsMs(!isMs)}
            >
              {isMs ? intl.t('form.fields.ms') : intl.t('form.fields.s')}
            </button>
          </>
        ),
        accessor: 'ts',
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ cell }) => {
          const date = DateTime.fromMillis(cell.value, { zone: isUtc ? 'utc' : 'local' })

          return (
            <span className="font-monospace small">
              <FormatDateTime
                date={date}
                withMillis={isMs}
                withSecond
              />
            </span>
          )
        },
      },
      ...(isBatchView ? [{
        id: 'batch',
        Header: intl.t('form.fields.batch'),
        accessor: 'id',
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ row }) => (
          <div>
            {row.original.batchName ? <div>{row.original.batchName}</div> : null}
            {row.original.batch ? <small>{row.original.batch}</small> : null}
          </div>
        ),
      }] : []),
      {
        id: 'value-categories',
        Header: intl.t('form.fields.category'),
        accessor: 'values',
        width: 120,
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ cell }) => (
          <div className="flex-column min-w-0">
            {cell.value.map((item, index) => (
              <div key={index} className="text-truncate">
                <SensorCategory sensorId={item.source.sensorId}>
                  {category => category?.name}
                </SensorCategory>
              </div>
            ))}
          </div>
        ),
      },
      {
        id: 'value-units',
        Header: intl.t('form.fields.unit'),
        accessor: 'values',
        width: 120,
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ cell }) => (
          <div className="flex-column min-w-0">
            {cell.value.map((item, index) => (
              <div key={index} className="text-truncate">
                <SensorUnit sensorId={item.source.sensorId}>
                  {unit => unit?.symbol}
                </SensorUnit>
              </div>
            ))}
          </div>
        ),
      },
      {
        id: 'value-types',
        Header: intl.t('form.fields.type'),
        accessor: 'values',
        minWidth: 50,
        width: 50,
        maxWidth: 80,
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ cell }) => (
          <div className="flex-column min-w-0">
            {cell.value.map((item, index) => (
              <div key={index} className="text-truncate">{item.source.dataType}</div>
            ))}
          </div>
        ),
      },
      {
        id: 'value-hardware-ids',
        Header: intl.t('form.fields.dataSourceSection'),
        accessor: 'values',
        minWidth: 150,
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ cell }) => (
          <div className="flex-column min-w-0">
            {cell.value.map((item, index) => (
              <div key={index} className="text-truncate" title={item.source.sensorId}>{item.source.displayName}</div>
            ))}
          </div>
        ),
      },
      {
        Header: () => (
          <div className="flex-fill d-flex align-items-center justify-content-between">
            {intl.t('form.fields.value')}
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary"
              onClick={() => onClose?.()}
            >
              <Icon name="fa--times-circle" />
            </button>
          </div>
        ),
        accessor: 'values',
        className: 'text-nowrap',
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ cell }) => (
          <table cellSpacing="0" cellPadding="0" width="100%">
            <tbody>
              {cell.value.map((item, index) => (
                <tr key={index}>
                  <td width="100%" className="font-monospace text-end">
                    {item.value !== null ? `${item.value < 0 ? '-' : ''}${Math.trunc(Math.abs(item.value))}` : 'null'}
                  </td>
                  <td className="font-monospace">
                    {item.value !== null ? precision(item.value, item.valuePrecision) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ),
      },
    ] : [
      {
        Header: () => (
          <>
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary me-2"
              onClick={() => onClose?.()}
            >
              <Icon name="fa--times-circle" />
            </button>

            {intl.t('form.fields.value')}

            <button
              type="button"
              className="btn btn-sm btn-flat-secondary ms-2"
              onClick={() => setIsUtc(!isUtc)}
            >
              <Icon name={isUtc ? 'fa--clock' : 'fa--user-clock'} />
            </button>
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary"
              title=""
              onClick={() => setIsMs(!isMs)}
            >
              {isMs ? intl.t('form.fields.ms') : intl.t('form.fields.s')}
            </button>
          </>
        ),
        accessor: 'values',
        className: 'text-nowrap',
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ cell, row }) => {
          const date = DateTime.fromMillis(row.original.ts, { zone: isUtc ? 'utc' : 'local' })
          return (
            <table cellSpacing="0" cellPadding="0" width="100%">
              <tbody>
                <tr>
                  <td colSpan="5">
                    #
                    {row.index}
                    .
                    {' '}
                    <FormatDateTime
                      date={date}
                      withMillis={isMs}
                      withSecond
                    />
                  </td>
                </tr>
                {isBatchView ? (
                  <tr>
                    <td colSpan="5" className="text-truncate">
                      {row.original.batchName}
                      {' '}
                      /
                      {row.original.batch}
                    </td>
                  </tr>
                ) : null}
                {cell.value.map((item, index) => (
                  <tr key={index}>
                    <td className="text-truncate pe-2">{item.source.displayName}</td>
                    <td className="pe-2">{item.source.dataType}</td>
                    <td className="pe-2">
                      <SensorUnit sensorId={item.source.sensorId}>
                        {unit => unit?.symbol}
                      </SensorUnit>
                    </td>
                    <td width="100%" className="font-monospace text-end">
                      {item.value !== null ? `${item.value < 0 ? '-' : ''}${Math.trunc(Math.abs(item.value))}` : 'null'}
                    </td>
                    <td className="font-monospace">
                      {item.value !== null ? precision(item.value, item.valuePrecision) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        },
      },
    ]
  ), [ intl, isUtc, isTablet, isBatchView, onClose, isMs ])

  const itemSize = useCallback((index) => {
    let len = value[index]?.values?.length || 1
    if (!isTablet) {
      len = len + 1 + (isBatchView ? 1 : 0)
    }
    return 20 * len + 16
  }, [ value, isTablet, isBatchView ])

  return (
    <VirtualTable
      className="flex-fill"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={value}
      selection={false}
      estimatedItemSize={isTablet ? 36 : 56}
      itemSize={itemSize}
    />
  )
}
