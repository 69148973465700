import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormSection,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectSensor from '@/components/Form/SelectSensor'
import StreamJobList from '@/hoc/StreamJobList'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'

export default function Section ({ scope, title, description }) {
  const intl = useIntl()
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <FormSection
      title={title}
      description={description}
    >
      <FormField
        label={intl.t('form.fields.device')}
        description={intl.t('widgets.deviceCommands.form.fields.deviceDescr')}
        errors={get(errors, `${scope}.deviceId`)}
      >
        <Controller
          name={`${scope}.deviceId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.devicePlaceholder')}
              ListSource={EdgeDeviceList}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.streamJob')}
        description={intl.t('widgets.deviceCommands.form.fields.streamJobDescr')}
        errors={get(errors, `${scope}.streamJobId`)}
      >
        <Controller
          name={`${scope}.streamJobId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.streamJobPlaceholder')}
              ListSource={StreamJobList}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.twin')}
        description={intl.t('widgets.deviceCommands.form.fields.twinDescr')}
        errors={get(errors, `${scope}.twinId`)}
      >
        <Controller
          name={`${scope}.twinId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dataPoint')}
        description={intl.t('widgets.deviceCommands.form.fields.dataPointDescr')}
        errors={get(errors, `${scope}.sensorId`)}
      >
        <Controller
          name={`${scope}.sensorId`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
            />
          )}
        />
      </FormField>
    </FormSection>
  )
}
