import {
  forwardRef, useRef, useEffect, useImperativeHandle,
} from 'react'
import { echarts } from '@wiz/utils'
import IconFa from '@/shared/icon'

const EChart = forwardRef(({ properties, onChartClick }, ref) => {
  const refChart = useRef()
  const refTarget = useRef()

  useImperativeHandle(ref, () => ({
    resize () {
      refChart.current?.resize()
    },

    plot () {
      return refChart.current
    },
  }))

  useEffect(() => {
    const chart = echarts.init(refTarget.current)
    chart.on('click', onChartClick)
    refChart.current = chart
    return () => {
      chart.dispose()
      chart.off('click', onChartClick)
    }
  }, [ onChartClick ])

  useEffect(() => {
    refChart.current?.setOption(properties, {
      silent: true,
      notMerge: true,
      lazyUpdate: false,
    })
  }, [ properties ])

  return (
    <div
      ref={refTarget}
      className="flex-fill"
      style={{ width: '503px', height: '300px' }}
    />
  )
})

const Chart = ({ data, onChartClick, isLoading }) => {
  const ref = useRef()

  return (
    <div className="d-flex flex-column flex-fill w-100">
      {isLoading ? (
        <div className="position-absolute-fill position-center-fill bg-light opacity-50" style={{ zIndex: 1 }}>
          <IconFa name="faSpinner" size="xl" spin />
        </div>
      ) : null}

      <EChart properties={data} ref={ref} onChartClick={onChartClick} />
    </div>

  )
}

export default Chart
