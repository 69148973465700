import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormControl } from '@wiz/components'
import { dbProvider, IExplorer } from '@wiz/store'
import { toArrayValue } from '@wiz/utils'
import EventSourceExplorer from '@/components/Form/EventSourceExplorer'

export default function SectionEvents ({ scope }) {
  const { watch, setValue } = useFormContext()
  const [
    dataViews,
    eventSources,
  ] = watch([
    `${scope}.dataViews`,
    `${scope}.eventSources`,
  ])

  const handleRemove = useCallback((data) => {
    const next = eventSources.filter(item => item.id !== data.id)
    setValue(`${scope}.eventSources`, next, { shouldDirty: true })
  }, [ scope, setValue, eventSources ])

  const handleReplace = useCallback(async (data) => {
    const nextEventSources = toArrayValue(data)
      .reduce((out, item) => ({
        ...out,
        [item.id]: item,
      }), {})

    const next = []

    for (const item of eventSources) {
      if (nextEventSources[item.id]) {
        const nextEventSource = nextEventSources[item.id]
        delete nextEventSources[item.id]
        const eventSource = await IExplorer.createEventSourceContext(
          dbProvider.database,
          { ...item, ...nextEventSource },
        )
        next.push(eventSource)
      } else {
        next.push(item)
      }
    }

    for (const item of Object.values(nextEventSources)) {
      const eventSource = await IExplorer.createEventSourceContext(dbProvider.database, item)
      next.push(eventSource)
    }

    setValue(`${scope}.eventSources`, next, { shouldDirty: true })
  }, [ scope, setValue, eventSources ])

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <FormControl type="any" name={`${scope}.eventSources`} />

      <div className="flex-fill d-flex flex-column">
        <EventSourceExplorer
          dataViews={dataViews}
          options={eventSources}
          onReplaceEventSource={handleReplace}
          onRemoveEventSource={handleRemove}
        />
      </div>
    </div>
  )
}
