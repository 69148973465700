import { useMemo } from 'react'
import classnames from 'classnames'
import { VirtualTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import EditButton from '@/shared/editButton'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function Table ({
  items,
  onFilter,
  onEdit,
  onRemove,
  onRun,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell, row }) => (auth.checkAccessUpdate('Plot') ? (
        <EditButton cell={cell} onEdit={onEdit} />
      ) : null),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 200,
      Cell: ({ cell, row }) => {
        const allowed = auth.checkAccessUpdate('Plot')
        return (
          <div className="text-truncate min-w-0">
            <div className="d-flex align-items-center">
              <div
                className={classnames('text-truncate', { 'link pointer': allowed })}
                onClick={() => (allowed ? onEdit?.(row.original.id) : undefined)}
                aria-hidden
              >
                { cell.value }
              </div>
            </div>
          </div>
        )
      },
    },
    {
      Header: intl.t('form.fields.generatedBy'),
      accessor: 'generatedById',
      width: 240,
      maxWidth: 300,
      Cell: ({ cell }) => (cell.value ? (
        <div className="text-truncate min-w-0">
          <div className="d-flex align-items-center">
            <a
              className="text-truncate pointer"
              onClick={() => onRun?.(cell.value)}
              aria-hidden
            >
              { cell.value ? intl.t('entities.form.fields.openExecution') : '-' }
            </a>
          </div>
        </div>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      // disableSortBy: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
  ], [ auth, onEdit, intl, onRun ])

  return (
    <>
      <VirtualTable
        {...props}
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Plot') || auth.checkAccessUpdate('Plot')}
      />

      <Pagination {...props} />
    </>
  )
}
