import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { get } from '@wiz/utils'
import {
  FormField, FormControl, FormFieldInline, Icon,
} from '@wiz/components'
import FormTemplates from '@/components/Form/ListTemplates'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import { testJSON } from '@/utils/json'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, getValues } = useFormContext()

  const { id, experimentKey } = getValues(scope)

  const { data: experiment, isLoading } = useQuery({
    queryKey: [ 'experimentById', experimentKey ],
    queryFn: () => wizataApi.experiments.getById(experimentKey),
    enabled: !!experimentKey,
    staleTime: Infinity,
  })

  return (
    <>
      <FormField
        label={intl.t('form.fields.key')}
        description={intl.t('executions.form.fields.keyDescr')}
        errors={get(errors, `${scope}.key`)}
      >
        <FormControl
          name={`${scope}.key`}
          disabled={id}
        />
      </FormField>
      <FormField
        label={intl.t('form.fields.template')}
        description={intl.t('executions.form.fields.templateDescr')}
        errors={get(errors, `${scope}.templateId`)}
      >
        <Controller
          name={`${scope}.templateId`}
          render={({ field, fieldState }) => (
            <FormTemplates
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              disabled
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.experimentKey')}
        description={intl.t('executions.form.fields.experimentKeyDescr')}
        errors={get(errors, `${scope}.experimentKey`)}
      >
        <span className="form-control text-truncate text-muted">
          {experimentKey && isLoading ? (
            <Icon
              name="fa--spinner"
              spin
            />
          ) :
            experiment?.name || '-'}
        </span>

      </FormField>

      <FormFieldInline
        label={intl.t('pipelines.form.fields.variables')}
        description={intl.t('pipelines.form.fields.variablesDescr')}
        errors={get(errors, `${scope}.variables`)}
        vertical
        complex
      >
        <FormControl
          type="code"
          title={intl.t('pipelines.form.fields.variables')}
          description={intl.t('pipelines.form.fields.variablesDescr')}
          name={`${scope}.variables`}
          lang="json"
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (str.length && !testJSON(str)) {
                return intl.t('form.errors.mustBeJson')
              }
              if (value && !str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value?.length > 8000) {
                return intl.t('form.errors.fieldMaxlen', { max: 8000 })
              }
              return true
            },
          }}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('pipelines.form.fields.steps')}
        description={intl.t('pipelines.form.fields.stepsDescr')}
        errors={get(errors, `${scope}.steps`)}
        vertical
        complex
      >
        <FormControl
          type="code"
          title={intl.t('pipelines.form.fields.steps')}
          description={intl.t('pipelines.form.fields.stepsDescr')}
          name={`${scope}.steps`}
          lang="json"
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (str.length && !testJSON(str)) {
                return intl.t('form.errors.mustBeJson')
              }
              if (value && !str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value?.length > 8000) {
                return intl.t('form.errors.fieldMaxlen', { max: 8000 })
              }
              return true
            },
          }}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('pipelines.form.fields.plots')}
        description={intl.t('pipelines.form.fields.plotsDescr')}
        errors={get(errors, `${scope}.plots`)}
        vertical
        complex
      >
        <FormControl
          type="code"
          title={intl.t('pipelines.form.fields.plots')}
          description={intl.t('pipelines.form.fields.plotsDescr')}
          name={`${scope}.plots`}
          lang="json"
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (str.length && !testJSON(str)) {
                return intl.t('form.errors.mustBeJson')
              }
              if (value && !str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value?.length > 8000) {
                return intl.t('form.errors.fieldMaxlen', { max: 8000 })
              }
              return true
            },
          }}
        />
      </FormFieldInline>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
