import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import FormDialog from '@/components/Form/FormDialog'
import { Icon } from '@wiz/components'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import { uuid } from '@wiz/utils'
import { useSignal } from '@/components/Pipelines/pipeline/hooks/useSignal'
import Section from './Section'
import Removed from '../Removed'

const Pipeline = ({
  id,
  templateId,
  dialog,
  onClose,
  onUpdate,
}) => {
  const queryClient = useQueryClient()

  const { init, destroy, messages } = useSignal()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'pipeline', id ],
    queryFn: () => wizataApi.pipelines.getById(id),
    enabled: !!id,
    staleTime: Infinity,
  })

  const {
    mutateAsync: mutatePipeline,
  } = useMutation({
    mutationKey: [ 'mutatePipeline' ],
    mutationFn: (data) => {
      if (!data.id) {
        return wizataApi.pipelines.create({ ...data, id: uuid() })
      }
      return wizataApi.pipelines.update(data)
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/pipelines.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      onUpdate()
      queryClient.invalidateQueries({ queryKey: [ 'pipeline', id ] })
    },
  })

  const handleSubmit = async ({ pipeline }) => {
    const next = { ...pipeline }

    if (pipeline.steps) {
      next.steps = JSON.parse(pipeline.steps)
    }
    if (pipeline.plots) {
      next.plots = JSON.parse(pipeline.plots)
    }
    if (pipeline.variables) {
      next.variables = JSON.parse(pipeline.variables)
    }

    await mutatePipeline(next)
  }

  const defaultValues = useMemo(() => ({
    pipeline: {
      ...item,
      variables: item ? JSON.stringify(item.variables, null, 2) : null,
      steps: item ? JSON.stringify(item.steps, null, 2) : null,
      plots: item ? JSON.stringify(item.plots, null, 2) : null,
      templateId: item ? item.templateId : templateId,
    },
  }), [ item, templateId ])

  if (id && isLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light opacity-50">
        <Icon name="fa--spinner" size="2X" spin />
      </div>
    )
  }

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onClose={onClose}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      dataTestid="pipelineForm"
      dialog={dialog}
    >
      <Section scope="pipeline" />
    </FormDialog>
  )
}

export default Pipeline
