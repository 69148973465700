import { useCallback, useEffect } from 'react'
import { of as of$ } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { dbProvider, Q } from '@wiz/store'
import { useRouter, useLocationQuery } from '@/router'
import { withObservables, FormCheckButton, Icon as LegacyIcon } from '@wiz/components'
import Icon from '@/shared/icon'
import useAppContext from '@/hooks/useAppContext'
import Breadcrumb from '@/components/Twin/Graph/Breadcrumb'
import SelectViewMode from '@/components/Form/SelectViewMode'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import cx from 'classnames'
import classes from './index.module.css'

const enhanceData = withObservables([], () => ({
  defaultTree: dbProvider.observeSettings('PhysicalTwinDefaultTree'),
  globalSettings: dbProvider.observeGlobalSettings([ 'EnvironmentName' ]),
}))

const enhanceTwinData = withObservables([ 'defaultTree', 'tree' ], ({ defaultTree, tree }) => ({
  twinsChain: (tree || defaultTree) ? dbProvider.database.collections.get('twins')
    .query(Q.where('id', (tree || defaultTree)))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      switchMap(([ item ]) => (item ? item.observeTwinsChain : of$([]))),
      map(items => items.reverse()),
    ) : of$([]),
}))

const DigitalTwinActions = ({
  globalSettings, twinsChain,
}) => {
  const intl = useIntl()
  const auth = useAuth()
  const router = useRouter()
  const query = useLocationQuery()

  const { handleTwinChange } = useGlobalExecute()
  const { linkMode, setLinkMode } = useAppContext()

  const handleView = useCallback((treeId) => {
    router.push({ name: 'twin-items-list', query: { ...query, tree: treeId } })
    handleTwinChange(treeId)
  }, [ router, query, handleTwinChange ])

  const handleChangeViewMode = useCallback((value) => {
    router.push({ query: { ...query, view: value } })
  }, [ router, query ])

  const handleRootTwin = useCallback(() => {
    handleView('')
  }, [ handleView ])

  const canUpdate = twinsChain?.length ? auth.checkAccessUpdate(twinsChain[twinsChain.length - 1]) : false

  return (
    <>
      {canUpdate ? (
        <div className={cx('mx-2 flex-shrink-0', classes.connections)}>
          <FormCheckButton
            className={cx(classes.btn, {
              [classes.active]: !linkMode,
            })}
            checked={!linkMode}
            label={<LegacyIcon name="wiz--twin--pointer" width={14} height={14} size="2X" />}
            title={intl.t('twinGraph.form.actions.linkParentModeTitle')}
            onChange={() => setLinkMode(null)}
          />
          <FormCheckButton
            className={cx(classes.btn, {
              [classes.active]: linkMode === 'link-parent-mode',
            })}
            checked={linkMode === 'link-parent-mode'}
            label={<LegacyIcon name="wiz--twin--connect" width={14} height={14} size="2X" />}
            title={intl.t('twinGraph.form.actions.linkParentModeTitle')}
            onChange={checked => setLinkMode(checked ? 'link-parent-mode' : null)}
          />
          <FormCheckButton
            className={cx(classes.btn, {
              [classes.active]: linkMode === 'link-descr-mode',
            })}
            checked={linkMode === 'link-descr-mode'}
            label={<LegacyIcon name="wiz--twin--process-connect" width={14} height={14} size="2X" />}
            title={intl.t('twinGraph.form.actions.linkDescrModeTitle')}
            onChange={checked => setLinkMode(checked ? 'link-descr-mode' : null)}
          />
        </div>
      ) : null}

      <Breadcrumb
        className="flex-fill px-2 py-1"
        options={twinsChain}
        onChange={handleView}
        onRootTwinOpen={handleRootTwin}
        envName={globalSettings.EnvironmentName}
      />

      {/* {(
        !isMobile && (
          auth.checkAccessCreate('Twin') ||
    auth.checkAccessUpdate('Twin') ||
    auth.checkAccessRemove('Twin')
        )
      ) ? (
        <div className="ms-auto">
          <SelectViewMode
            value={filters.view}
            onChange={onChangeViewMode}
          >
            {intl.t('twin.topmenu.switchto')}
          </SelectViewMode>
        </div>
        ) : null} */}
    </>
  )
}

export default enhanceData(enhanceTwinData(DigitalTwinActions))
