import { useMemo } from 'react'
import { BusinessLabel as BusinessLabelModel } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import Removed from '../Removed'
import Schema from './Schema'

export default function BusinessLabel ({
  id,
  businessLabel,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    businessLabel: BusinessLabelModel.toJSON(businessLabel),
  }), [ businessLabel?.updatedAt ?? businessLabel ])

  if (id && !businessLabel) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      dataTestid="businessLabelForm"
      dialog={dialog}
    >
      <Section
        scope="businessLabel"
      />
    </FormDialog>
  )
}
