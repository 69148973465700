import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { dbProvider, Sensor } from '@wiz/store'
import { withProps } from '@wiz/components'
// import { testJSON } from '@/utils/json'
// import { useIntl } from '@wiz/intl'

export default withProps(() => {
  const queryClient = useQueryClient()
  // const intl = useIntl()
  const handleSyncSuccess = useCallback(() => {
    dbProvider.sync.removeListener('synced', handleSyncSuccess)
    queryClient.invalidateQueries({ queryKey: [ 'sensorsPagedList' ] })
  }, [ queryClient ])

  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Sensor, { ...data.sensor, extraProperties: data.extraProperties })
    await model.prepareReplaceLabels(context, data.labels)
    await dbProvider.batch(context)
    dbProvider.sync.once('synced', handleSyncSuccess)
  }, [ handleSyncSuccess ])

  // const validates = useMemo(() => ({
  //   // eslint-disable-next-line object-shorthand
  //   async 'sensor.hardwareId' (value) {
  //     if (!value) {
  //       return intl.t('form.errors.fieldRequired')
  //     }

  //     const sensors = await dbProvider.database.collections.get('sensors')
  //       .query(Q.where('hardware_id', value))
  //       .fetch()

  //     if (sensors.length > 1 || (
  //       sensors.length && sensors[0].id !== sensor?.id
  //     )) {
  //       return intl.t('sensors.form.errors.hardwareIdExists')
  //     }

  //     return true
  //   },
  // }), [ intl, sensor ])

  return {
    // validates,
    onSubmit,
  }
})
