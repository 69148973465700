import {
  useEffect,
  useCallback,
  useRef,
} from 'react'
import cx from 'classnames'
import { useIntl } from '@wiz/intl'
import { Dropdown } from '@wiz/components'
import { useRouter } from '@/router'
import { useStonly } from '@/integrations/stonly'
import { useAuth } from '@/auth'
import { checkPermissions } from '@/utils/menu'
import Icon from '@/shared/icon'
import { MenuItem } from '../TopMenu/MenuItem'
import ButtonScreen from './ButtonScreen'
import ButtonTheme from './ButtonTheme'
import { intl } from '../../i18n'

const RoutesById = {
  dashboard: 'homepage',
  profile: 'profile',
  logout: 'logout',
}

const options = [
  {
    id: '9cbaa2c7-f8b1-4317-b99e-dde5ff5ba784',
    name: 't/menu.title.global',
    group: '55b6f387-b59f-4e4a-83a2-f68c90062e37',
    path: '/settings',
    route: {
      name: 'settings',
    },
    attr: {
      id: 'global',
    },
    access: [
      [
        'SectionGeneralSettings',
        [
          'read',
        ],
      ],
    ],
  },
  {
    id: 'f8441323-3963-49e5-a23f-e3dd993f1d84',
    name: 't/menu.title.users',
    group: '55b6f387-b59f-4e4a-83a2-f68c90062e37',
    attr: {
      id: 'userManagement',
    },
    actions: [
      {
        path: 'users-list',
        name: 't/menu.settings.users.users',
        attr: {
          id: 'users',
        },
        access: [
          [
            'SectionUsers',
            [
              'read',
            ],
          ],
        ],
      },
      {
        path: 'roles-list',
        name: 't/menu.settings.users.roles',
        attr: {
          id: 'roles',
        },
        access: [
          [
            'SectionRoles',
            [
              'read',
            ],
          ],
        ],
      },
      {
        path: 'notification-sheets-list',
        name: 't/menu.settings.users.lists',
        attr: {
          id: 'lists',
        },
        access: [
          [
            'SectionNotificationSheets',
            [
              'read',
            ],
          ],
        ],
      },
    ],
  },
  {
    id: 'd1116222-5163-4333-a212-e3plw23f1d84',
    name: 't/menu.title.integrations',
    group: '55b6f387-b59f-4e4a-83a2-f68c90062e37',
    path: '/settings/integrations',
    route: {
      name: 'integrations-list',
    },
    attr: {
      id: 'integrations',
    },
    access: [
      [
        'SectionGeneralSettings',
        [
          'read',
        ],
      ],
    ],
  },
  {
    id: 'f8116113-3963-49e5-a23f-e3ww123f1d84',
    name: 't/menu.title.appearance',
    group: '55b6f387-b59f-4e4a-83a2-f68c90062e37',
    attr: {
      id: 'appearance',
    },
    actions: [
      {
        path: 'twin-style',
        name: 't/menu.settings.colors',
        attr: {
          id: 'colors',
        },
        icon: <Icon name="faPalette" type="solid" size="lg" />,
        access: [
          [
            'SectionDigitalTwinStyle',
            [
              'read',
            ],
          ],
        ],
      },
      {
        path: '/',
        name: 't/menu.settings.fullScreen',
        attr: {
          id: 'fullScreen',
        },
        component: <ButtonScreen label={intl.t('menu.settings.fullScreen')} />,
      },
      // {
      //   path: '/',
      //   name: 't/menu.settings.theme',
      //   attr: {
      //     id: 'theme',
      //   },
      //   component: <ButtonTheme label={intl.t('menu.settings.theme')} />
      // },
    ],
  },
]

export const Options = ({ onClick, items }) => {
  const auth = useAuth()
  const router = useRouter()
  const intl = useIntl()

  const handleClick = useCallback(({ id }) => {
    if (id === 'relogin') {
      auth.relogin()
    } else if (RoutesById[id]) {
      router.push({ name: RoutesById[id] })
    }
  }, [ auth, router ])

  const itemsWithAccess = checkPermissions(options)
  const data = items || itemsWithAccess

  if (!data.length) {
    return null
  }

  return data?.map(({ name, id, ...rest }, idx) => (
    <MenuItem
      key={id}
      idx={idx}
      className={cx('d-flex align-items-center')}
      {...rest}
      onAction={handleClick}
      onClose={onClick}
    >
      <span className="d-flex flex-fill" role={!rest.actions && 'menuitem'}>{intl.t(name)}</span>
      {rest.actions?.length ? <Icon type="solid" name="faCaretRight" className="ms-4" /> : null}
    </MenuItem>
  ))
}

export default function ButtonSettings () {
  const refTarget = useRef()
  const { send } = useStonly()

  const itemsWithAccess = checkPermissions(options)

  useEffect(() => {
    const key = 'permission-settings'
    const value = itemsWithAccess.length ? 'read' : 'none' // add 'write' for boards/dashboards create

    send({ [key]: value })
  }, [])

  if (!itemsWithAccess.length) {
    return null
  }

  return (
    <>
      <button
        ref={refTarget}
        type="button"
        className="btn btn-text btn-flat-secondary"
        title={intl.t('menu.tooltip.settings')}
      >
        <Icon type="solid" name="faCog" size="lg" />
      </button>

      <Dropdown
        arrow
        autoclose
        target={refTarget}
        placement="bottom-end"
        className="py-2 text-break"
      >
        <Options items={itemsWithAccess} />
      </Dropdown>
    </>
  )
}
