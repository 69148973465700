import { Controller, useFormContext } from 'react-hook-form'
import { FormSection, FormField, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'

export default function Section ({ scope, title, description }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormSection
      title={title}
      description={description}
    >
      <FormField
        label={intl.t('widgets.timeline.form.fields.lastLimit')}
        description={intl.t('widgets.timeline.form.fields.lastLimitDescr')}
        errors={get(errors, `${scope}.duration`)}
      >
        <FormControl
          type="duration"
          name={`${scope}.duration`}
          parts="months,weeks,days,hours,minutes"
        />
      </FormField>

      <FormField
        label={intl.t('widgets.timeline.form.fields.twin')}
        description={intl.t('widgets.timeline.form.fields.twinDescr')}
        errors={get(errors, `${scope}.twinSensorId`)}
      >
        <Controller
          name={`${scope}.twinSensorId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('widgets.timeline.form.fields.twinPlaceholder')}
              filters={{
                withHardware: true,
                withSensors: true,
                types: [],
              }}
            />
          )}
        />
      </FormField>
    </FormSection>
  )
}
