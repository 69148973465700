import { useMemo } from 'react'
// import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Sensor as Sensor$ } from '@wiz/store'
// import { wizataApi } from '@/api'
import FormDialog from '@/components/Form/FormDialog'
// import { Icon } from '@wiz/components'
// import { testJSON } from '@/utils/json'
// import events from '@/utils/events'
// import { uuid } from '@wiz/utils'
import Removed from '../Removed'
import Section from './Section'
import Schema from './Schema'
import resolver from './resolver'

export default function Sensor ({
  id,
  sensor,
  labels,
  dialog,
  onClose,
  onSubmit,
}) {
  // const queryClient = useQueryClient()

  // const { data: item, isLoading } = useQuery({
  //   queryKey: [ 'sensor', id ],
  //   queryFn: () => wizataApi.sensors.getById(id),
  //   enabled: !!id,
  //   staleTime: Infinity,
  //   refetchOnWindowFocus: false,
  // })

  // const {
  //   mutateAsync: mutateDatapoint,
  // } = useMutation({
  //   mutationKey: [ 'mutateDatapoint' ],
  //   mutationFn: (data) => {
  //     if (!data.id) {
  //       return wizataApi.sensors.create({ ...data, id: uuid() })
  //     }
  //     return wizataApi.sensors.update(data)
  //   },
  //   onError: (err) => {
  //     events.emit('app:notify', {
  //       type: 'error',
  //       title: 't/sensors.update.error',
  //       message: err.message,
  //       duration: 5000,
  //     })
  //   },
  //   onSuccess: (payload) => {
  //     const next = {
  //       sensor: {
  //         ...payload,
  //       },
  //     }
  //     console.log('next', next)
  //     onSubmit?.(next)
  //     queryClient.invalidateQueries({ queryKey: [ 'sensor', id ] })
  //     queryClient.invalidateQueries({ queryKey: [ 'sensorsPagedList' ] })
  //   },
  // })

  // const handleSubmit = async ({ sensor }) => {
  //   const next = { ...sensor }
  //   if (!sensor.extraProperties || !testJSON(sensor.extraProperties)) {
  //     next.extraProperties = JSON.stringify({})
  //   }
  //   await mutateDatapoint(next)
  // }

  const defaultValues = useMemo(() => ({
    sensor: {
      ...Sensor$.toJSON(sensor),
      extraProperties: sensor?.extraProperties ? JSON.stringify(sensor?.extraProperties) : sensor?.extraProperties,
    },
    labels: labels.map(item => item.id),
  }), [
    sensor?.updatedAt ?? sensor,
    labels,
  ])

  // if (id && isLoading) {
  //   return (
  //     <div className="position-absolute-fill position-center-fill bg-light opacity-50">
  //       <Icon name="fa--spinner" size="2X" spin />
  //     </div>
  //   )
  // }

  if (id && !sensor) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="sensorForm"
      dialog={dialog}
      resolver={resolver}
      schema={Schema}
    >
      <Section
        scope="sensor"
      />
    </FormDialog>
  )
}
