import {
  memo,
  useMemo,
  useContext,
  useCallback,
} from 'react'
import { useIntl } from '@wiz/intl'
import { Badge } from '@wiz/components'
import { Sensor } from '@wiz/store'
import SelectedDataView from '@/context/SelectedDataView'
import SelectedDataSource from '@/context/SelectedDataSource'
import FilterSearch from '@/components/Form/FilterSearch'
import ListSensors from '@/components/Form/ListSensors'
import ButtonCopy from '@/components/Form/ButtonCopy'
import Actions from '@/components/Form/Actions'

const DataSourceExplorer = ({
  autoFocus,
  value,
  batchOnly,
  actions,
  readOnly,
  hasImportant = true,
  keyName = 'id',
  onChange,
}) => {
  const intl = useIntl()
  const dataViews = useContext(SelectedDataView)
  const dataSources = useContext(SelectedDataSource)

  const important = useMemo(() => (
    hasImportant ? [].concat(dataViews, dataSources) : []
  ), [ hasImportant, dataViews, dataSources ])

  const hashImportant = useMemo(() => (
    important.reduce((out, item) => ({ ...out, [item.id]: true }), {})
  ), [ important ])

  const Content = useMemo(() => ({
    active,
    data,
    search,
    selectable,
    className,
    ContentDefault,
  }) => {
    const item = data.payload || data
    const name = (
      <>
        <ContentDefault
          color={item.color}
          icon={item.icon}
          name={item.displayName || item.name}
          search={search}
          className={className}
        />

        {item.dataType ? (
          <Badge className="flex-shrink-0 bg-info ms-2">
            {item.dataType}
          </Badge>
        ) : null}

        {item.hardwareId ? (
          <ButtonCopy
            className="hover-inline-flex"
            value={item.hardwareId}
            title={intl.t('message.copy.hardwareId', { value: item.hardwareId })}
          />
        ) : null}
      </>
    )
    return (
      <>
        {name}

        {selectable && !hashImportant[item.id] && !readOnly ? (
          <Actions
            className="hover-inline-flex"
            classNameList="mt-n1 mb-n1 flex-nowrap flex-shrink-0"
            actions={actions}
            isActive={active}
            onClick={params => onChange?.(item[keyName], params)}
          >
            {name}
          </Actions>
        ) : null}
      </>
    )
  }, [
    intl,
    readOnly,
    actions,
    keyName,
    hashImportant,
    onChange,
  ])

  const handleActive = useCallback((data) => {
    const item = data?.payload ?? data
    if (value) {
      if (
        value === item[keyName] ||
        value === item.id ||
        value.includes?.(item[keyName]) ||
        value.includes?.(item.id)
      ) {
        return true
      }
    }

    return false
  }, [ value, keyName ])

  const handleSelectable = useCallback((data) => {
    const item = data?.payload ?? data
    if (Sensor.is(item)) {
      return true
    }

    if (hashImportant[item.id]) {
      return true
    }

    return false
  }, [ hashImportant ])

  const handleChange = useCallback((data) => {
    const item = data.payload || data
    if (hashImportant[item.id]) {
      onChange?.(undefined, item)
    } else if (!actions?.length) {
      onChange?.(item[keyName])
    }
  }, [
    actions,
    keyName,
    onChange,
    hashImportant,
  ])

  return (
    <FilterSearch
      searchTestid="sourceSearch"
      autoFocus={autoFocus}
      onChange={onChange}
    >
      {formData => (
        <ListSensors
          {...formData}
          className="position-absolute-fill"
          important={important}
          batchOnly={batchOnly}
          keyName={keyName}
          value={value}
          selectable={handleSelectable}
          active={handleActive}
          onChange={handleChange}
          Content={actions?.length ? Content : undefined}
        />
      )}
    </FilterSearch>
  )
}

export default memo(DataSourceExplorer, (prevProps, nextProps) => {
  const isEqual = (
    prevProps.autoFocus === nextProps.autoFocus &&
    prevProps.value === nextProps.value &&
    prevProps.batchOnly === nextProps.batchOnly &&
    prevProps.actions === nextProps.actions &&
    prevProps.onChange === nextProps.onChange
  )

  return isEqual
})
