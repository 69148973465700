import { Controller, useFormContext } from 'react-hook-form'
import { FormSection, FormField } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'
import TimeSettings from '@/components/Form/TimeSettings'

export default function Section ({ scope, title, description }) {
  const intl = useIntl()
  const { watch, formState: { errors } } = useFormContext()

  const [ windDirectionSensorId, windSpeedSensorId ] = watch([ `${scope}.windDirectionSensorId`, `${scope}.windSpeedSensorId` ])

  return (
    <FormSection
      title={title}
      description={description}
    >
      <FormField
        label={intl.t('widgets.roseWind.form.fields.windDirection')}
        description={intl.t('widgets.roseWind.form.fields.windDirectionDescr')}
        errors={get(errors, `${scope}.windDirectionSensorId`)}
      >
        <Controller
          name={`${scope}.windDirectionSensorId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            validate: (value) => {
              if (windSpeedSensorId === value) {
                return intl.t('widgets.roseWind.form.errors.fieldsAreSame')
              }
              return true
            },
            deps: [ `${scope}.windSpeedSensorId` ],
          }}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.roseWind.form.fields.windSpeed')}
        description={intl.t('widgets.roseWind.form.fields.windSpeedDescr')}
        errors={get(errors, `${scope}.windSpeedSensorId`)}
      >
        <Controller
          name={`${scope}.windSpeedSensorId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            validate: (value) => {
              if (windDirectionSensorId === value) {
                return intl.t('widgets.roseWind.form.errors.fieldsAreSame')
              }
              return true
            },
            deps: [ `${scope}.windDirectionSensorId` ],
          }}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
            />
          )}
        />
      </FormField>

      <TimeSettings
        dateFromFieldName={`${scope}.dateFrom`}
        dateToFieldName={`${scope}.dateTo`}
        durationFieldName={`${scope}.duration`}
        offsetDurationFieldName={`${scope}.offsetDuration`}
        stepRequestFieldName={`${scope}.stepRequest`}
      />
    </FormSection>
  )
}
