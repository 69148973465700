import {
  useMemo, useRef, useCallback, useState, useContext,
} from 'react'
import classnames from 'classnames'
import {
  FormSelectDateTimeRange, DropdownMenu, FormInputSearch,
} from '@wiz/components'
import FormatDateTime from '@/containers/FormatDateTime'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'
import { useIntl } from '@wiz/intl'
import { useRouter, useLocationQuery } from '@/router'
import { debounce } from '@wiz/utils'
import Icon from '@/shared/icon'
import useAppContext from '@/hooks/useAppContext'
import Breadcrumbs from './breadcrumbs'
import classes from './index.module.css'

const dashboardTypes = [
  {
    value: 'grafana',
    name: 'Grafana',
    id: 'grafana',
  },
  {
    value: 'dashboard',
    name: 'Dashboard',
    id: 'dashboard',
  },
  {
    value: 'iframe',
    name: 'Iframe',
    id: 'iframe',
  },
]

const viewButtonByType = {
  all: {
    icon: 'faEye',
    next: 'personal',
    nextTtl: 'personal only',
  },
  personal: {
    icon: 'faUser',
    next: 'org',
    nextTtl: 'organizational',
  },
  org: {
    icon: 'faSitemap',
    next: 'all',
    nextTtl: 'all',
  },
}

const Topbar = () => {
  const [ isTouched, setIsTouched ] = useState(false)
  const [ userValue, setUserValue ] = useState([ null, null ])

  const refMenu = useRef()
  const refMenuTarget = useRef()
  const {
    dashboard,
    currentTwinDashboards,
    handleSaveFormData,
    formData,
    isEditing,
    isDuplicating,
    handleAdd,
    handleEdit,
    handleEditId,
    handleDelete,
    handleDuplicate,
    dashboardsComponentsDeepList,
    contextTwinId,
    grafanaCheckData,
  } = useAppContext()

  const intl = useIntl()
  const router = useRouter()
  const { isReadOnly } = useAuthComponents()
  const { view, ...query } = useLocationQuery()
  const defaultValues = [ dashboard?.defaultFrom, dashboard?.defaultTo ]

  const isOwn = useMemo(() => {
    if (dashboardsComponentsDeepList?.data?.length) {
      const twin = dashboardsComponentsDeepList?.data?.find(opt => opt.twinId === contextTwinId)
      return !!twin
    }
    return false
  }, [ dashboardsComponentsDeepList, contextTwinId ])

  const subCategories = useMemo(
    () => dashboardsComponentsDeepList?.data?.reduce((acc, val) => {
      const isChild = val?.twinId === contextTwinId

      if (!contextTwinId || !isOwn || !isChild) {
        return acc
      }
      const subCat = acc.find(item => item.id === val.id)
      if (!subCat && val.labelId === query.category) {
        acc.push({ ...val, label: val.name, value: val.id })
      }

      return acc
    }, []).sort((a, b) => a.order - b.order) || [],
    [ dashboardsComponentsDeepList?.data, query.category, contextTwinId, isOwn ],
  )

  const handleViewChange = useCallback(() => {
    const nextView = viewButtonByType[view || 'all'].next
    const next = { ...query }
    if (nextView === 'personal') {
      const db = subCategories.find(opt => !!opt.ownerId)
      if (db) {
        next.category = db.labelId
        next.sub = db.id
      }
    } else if (nextView === 'org') {
      const orgList = currentTwinDashboards?.data?.filter(item => !item.ownerId).sort((a, b) => a.order - b.order)
      const withSameCategory = orgList.find(item => item.labelId === query.category)
      if (withSameCategory) {
        next.category = withSameCategory.labelId
        next.sub = withSameCategory.id
      } else {
        next.category = orgList[0].labelId
        next.sub = orgList[0].id
      }
    } else {
      const db = subCategories[0]
      next.category = db.labelId
      next.sub = db.id
    }
    router.replace({ query: { ...next, view: nextView } })
  }, [ view, router, query, subCategories, currentTwinDashboards.data ])

  return (
    <>
      <Breadcrumbs
        options={subCategories}
        onEditing={handleEdit}
        isEdit={isEditing}
        isLoading={isDuplicating}
        onComponentEdit={handleEditId}
        onDelete={handleDelete}
        onDuplicate={handleDuplicate}
        // className="d-flex flex-fill"
      >
        {subCategories?.length ? null : (
          <div className="ms-2">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={query.search}
              onChange={debounce((value) => {
                // if (value?.length > 2) {
                router.replace({ query: { ...query, search: value } })
                // }
              }, 500)}
              className="form-control-sm bg-light-alt"
            />
          </div>
        )}
        {isReadOnly ? null : (
          <button
            ref={refMenuTarget}
            type="button"
            className="btn btn-flat-secondary btn-text"
            disabled={!contextTwinId}
          >
            <Icon name="faPlus" />
          </button>
        )}
        {isEditing ? (
          <button
            type="button"
            className={classnames('btn btn-flat-secondary btn-text', classes.editBtn)}
            onClick={() => handleEdit(false)}
            title="Stop editing"
          >
            <Icon type="solid" name="faSquare" />
          </button>
        ) : null}
      </Breadcrumbs>

      <div className="d-flex mb-auto ms-auto">
        {isReadOnly ? null : (
          <button
            type="button"
            className={classnames('btn btn-flat-secondary btn-text me-2', classes.viewBtn)}
            onClick={handleViewChange}
            title={`See ${viewButtonByType[view || 'all']?.nextTtl}`}
          >
            <Icon name={viewButtonByType[view || 'all']?.icon} />
          </button>
        )}
        {dashboard?.type !== 'Dashboard' ? (
          <FormSelectDateTimeRange
            value={(
              formData.dateFrom && formData.dateTo ?
                [ formData.dateFrom, formData.dateTo ] :
                [ null, null ]
            )}
            DateTimeComponent={FormatDateTime}
            size="sm"
            clearable
            showArrows
            isRenderWithDefault
            defaultValues={defaultValues}
            userValue={userValue}
            setUserValue={setUserValue}
            isTouched={isTouched}
            setIsTouched={setIsTouched}
            disabled={!!formData.stepRequest || !subCategories?.length}
            onChange={([ dateFrom, dateTo ]) => {
              handleSaveFormData({ dateFrom, dateTo })
            }}
          />
        ) : null}
      </div>
      <DropdownMenu
        ref={refMenu}
        target={refMenuTarget}
        arrow
        placement="bottom-start"
        draggable
        autoclose
        options={dashboardTypes
          .filter(dashboardType => (grafanaCheckData?.isSuccess ? dashboardType : dashboardType.id !== 'grafana'))}
        onClick={handleAdd}
        // onDragStart={handleDragStart}
        // onDragEnd={handleDragEnd}
      />
    </>
  )
}

export default Topbar
