import {
  useState, useMemo, useEffect,
} from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import { useRouter } from '@/router'
import withTableActions from '@/containers/withTableActions'
import {
  VirtualTable, Pagination, withPagination, withSort,
} from '@wiz/components'
import events from '@/utils/events'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import Icon from '@/shared/icon'
import FormTrigger from '@/components/Forms/Trigger'
import { consts } from '@wiz/utils'
import useAppContext from '@/hooks/useAppContext'
import ChartsDrawer from '@/components/ChartsDrawer'
import CellExternalLink from './CellExternal'
import CellTime from './CellTime'
import CellTwin from './CellTwin'
import CellTemplate from './CellTemplate'
import EditButton from '../../shared/editButton'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
  delay: 'delay',
  interval: 'interval',
  status: 'status',
  pipelineId: 'pipelineId',
  templateId: 'templateId',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const Table = ({
  columns, data, filters, ...props
}) => {
  const intl = useIntl()

  return (
    <>
      <VirtualTable
        {...props}
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={data?.items || []}
        items={data?.items || []}
        filters={filters}
      />

      <Pagination
        {...props}
        total={data?.totalCount || 0}
        items={data?.items || []}
        filters={filters}
        relative
      />
    </>
  )
}

const TableComponent = withSort()(
  withPagination()(Table),
)

const Triggers = ({
  onClose, id, filters, templateId, embed, ...props
}) => {
  const [ editId, setEditId ] = useState(false)
  const [ isAdd, setAdd ] = useState(false)

  const { filters: contextFilters, onRowSelect, contextTwinId } = useAppContext()

  const auth = useAuth()
  const intl = useIntl()
  const router = useRouter()

  const {
    data: triggersList, isLoading, refetch, isError, error, isFetching,
  } = useQuery({
    queryKey: [ 'triggersPagedList' ],
    queryFn: () => {
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize

      const nextFilters = []
      const complexFilters = {}

      if (contextFilters.templateId) {
        nextFilters.push({
          propertyName: 'templateId',
          type: consts.FilterType.Guid,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.templateId,
        })
      }
      if (contextFilters.pipelineId) {
        nextFilters.push({
          propertyName: 'pipelineId',
          type: consts.FilterType.Guid,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.pipelineId,
        })
      }
      if (contextFilters.triggerStatus) {
        nextFilters.push({
          propertyName: 'status',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.triggerStatus,
        })
      }
      if (contextTwinId) {
        complexFilters.twinId = contextTwinId
        complexFilters.includeChildren = true
      }

      return wizataApi.triggers.getList({
        pagination: {
          take: limit - 1,
          skip: offset || 0,
        },
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
        filters: nextFilters,
        ...complexFilters,
      })
        .then((data) => {
          const { items } = data || {}
          const _items = items.slice(0, filters.pageSize)
          return { ...data, items: _items }
        })
    },
    enabled: !!filters,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const pipelines = useQuery({
    queryKey: [ 'pipelines', id ],
    queryFn: () => wizataApi.pipelines.getList(id),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const templates = useQuery({
    queryKey: [ 'templatesList' ],
    queryFn: () => wizataApi.templates.getList('false'),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const handleCloseDialog = () => {
    setEditId(null)
    setAdd(false)
    onClose?.()
  }

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      maxWidth: 40,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate('Trigger') ? (
            <EditButton cell={cell} onEdit={setEditId} className="p-0" />
          ) : null}
          {auth.checkAccessRead('Trigger') ? ( // FIXME: should be Trigger
            <button
              type="button"
              className="btn btn-text p-0 ms-1"
              onClick={() => router.push({ name: 'executions-list', query: { executionTriggerId: cell.value } })}
            >
              <Icon name="faEye" />
            </button>
          ) : null}
        </>
      ),
    },
    {
      Header: intl.t('form.fields.pipeline'),
      accessor: 'pipelineId',
      disableResizing: true,
      minWidth: 190,
      Cell: ({ row, cell }) => (
        <CellExternalLink
          id={cell.value}
          list={pipelines}
          name="pipelineId"
          page="pipelines"
          row={row}
          embed={embed}
        />
      ),
    },
    {
      Header: intl.t('form.fields.pipelineImage'),
      accessor: 'pipelineImageId',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 200,
      Cell: ({ cell }) => (
        <div className="d-flex align-items-center min-w-0">
          <div className="text-truncate">
            {cell.value}
          </div>
        </div>
      ),
    },
    {
      Header: intl.t('form.fields.template'),
      accessor: 'templateId',
      disableResizing: true,
      minWidth: 180,
      Cell: ({ row, cell }) => (
        <CellTemplate
          id={cell.value}
          list={templates}
        />
      ),
    },
    {
      Header: intl.t('form.fields.twins'),
      accessor: 'twinIds',
      disableResizing: true,
      disableSortBy: true,
      Cell: ({ row, cell }) => (
        <CellTwin
          twinIds={cell.value}
        />
      ),
    },
    {
      Header: intl.t('form.fields.status'),
      accessor: 'status',
      disableResizing: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
    },
    {
      Header: intl.t('form.fields.interval'),
      accessor: 'interval',
      disableResizing: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      Cell: ({ row, cell }) => (
        <CellTime
          value={cell.value}
          parts="days,hours,minutes,seconds"
        />
      ),
    },
    {
      Header: intl.t('form.fields.delay'),
      accessor: 'delay',
      disableResizing: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      Cell: ({ row, cell }) => (
        <CellTime
          value={cell.value}
          parts="hours,minutes,seconds"
        />
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
  ], [ intl, pipelines, router, templates, embed, auth ])

  const itemsList = useMemo(() => ({
    pipelineId: pipelines?.data,
    templateId: templates?.data,
  }), [ pipelines, templates ])

  const drawerSequence = [
    {
      name: 'status',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'pipelineId',
      chart: consts.PanelChartType.bar,
    },
    {
      name: 'templateId',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'twinId',
      chart: consts.PanelChartType.pie,
    },
  ]

  const possibleFilters = useMemo(() => ({
    pipelineId: contextFilters.pipelineId,
    templateId: contextFilters.templateId,
    status: contextFilters.status,
    twinId: contextTwinId,
  }), [ contextFilters, contextTwinId ])

  useEffect(() => {
    if (triggersList) {
      refetch()
    }
  }, [
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
    contextFilters.triggerStatus,
    contextFilters.templateId,
    contextFilters.pipelineId,
    contextTwinId,
  ])

  if (isError) {
    return (
      <div className="position-center-fill flex-column h-100">
        <h4>{intl.t('executions.loadingError')}</h4>
        <p className="text-danger">{error.message}</p>
        <button
          type="button"
          className="btn btn-fill-secondary"
          onClick={refetch}
        >
          {intl.t('form.actions.refresh')}
        </button>
      </div>
    )
  }

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          data={triggersList}
          columns={columns}
          filters={filters}
          loading={isLoading || isFetching}
          {...props}
          onSelect={onRowSelect}
        />
      </div>
      <ChartsDrawer
        filters={possibleFilters}
        valuesList={[ 'interval', 'delay' ]}
        sequence={drawerSequence}
        entity="executionTriggers"
        itemsList={itemsList}
      />
      {editId || isAdd ? (
        <FormTrigger
          id={editId}
          templateId={id}
          pipelines={pipelines?.data}
          // list={triggersList?.data || []}
          onClose={handleCloseDialog}
          onUpdate={triggersList?.refetch}
          dialog={{
            title: intl.t('triggers.form.fields.title'),
            dataTestid: 'replaceTriggerDialog',
          }}
        />
      ) : null}
    </div>
  )
}

export default withTableActions({
  defaultFilters () {
    return {
      sortBy: 'createdDate',
      sortDir: 'desc',
    }
  },
})(
  Triggers,
)
