import { useEffect } from 'react'
import { useAuth } from '@/auth'
import {
  useRouteMatch, useRouter, useLocation, useLocationQuery,
} from '@/router'
import { CorePages } from '@/utils/consts'
import Routes from '@/pages/routes.json'
// import { Q, dbProvider } from '@wiz/store'
// import SpotlightParts from '@/components/Spotlight/parts.json'

const routeFinder = (route) => {
  for (const routes of Routes) {
    for (const children of routes.children) {
      if (children.attr.id === route) {
        return children.route.name
      }
      if (children.actions?.length) {
        for (const action of children.actions) {
          if (action.attr.id === route) {
            return action.route.name
          }
        }
      }
    }
  }

  return null
}

export default function useDefaultRedirect (path) {
  const auth = useAuth()
  const router = useRouter()
  const location = useLocation()
  const query = useLocationQuery()
  const [ ,, corePage, currentPage ] = location.pathname.split('/')

  let match = useRouteMatch({
    path,
    exact: true,
  })

  if (!path) {
    match = true
  }

  useEffect(() => {
    async function defaultRedirect () {
      // let next = auth.checkAccessRead('SectionDashboards') ?
      //   { name: 'dashboards' } :
      //   { name: 'profile' }
      const next = { name: 'controlpanel' }
      // const homepageDefaultId = await dbProvider.fetchSettings('homepageDefaultId')
      // let homepageDefault = false
      // if (homepageDefaultId) {
      //   const part = SpotlightParts.find(item => item.id === homepageDefaultId)
      //   if (
      //     part &&
      //     part.route &&
      //     part.homepage &&
      //     (
      //       !part.access?.length ||
      //       part.access.every(rule => auth.checkAccessRules(rule[1], rule[0]))
      //     )
      //   ) {
      //     next = part.route
      //     homepageDefault = true
      //   }

      //   if (!homepageDefault && auth.checkAccessRead('SectionDigitalTwinChart')) {
      //     const [ defaultTwinGraph ] = await dbProvider.database.collections
      //       .get('twin_graphs')
      //       .query(Q.where('id', homepageDefaultId))
      //       .fetch()

      //     if (
      //       defaultTwinGraph &&
      //       auth.checkAccessRead(defaultTwinGraph)
      //     ) {
      //       next = { name: 'chart-view', params: { id: defaultTwinGraph.blockId } }
      //       homepageDefault = true
      //     }
      //   }

      //   if (!homepageDefault && auth.checkAccessRead('SectionDashboards')) {
      //     const [ defaultDashboard ] = await dbProvider.database.collections
      //       .get('dashboards')
      //       .query(Q.where('id', homepageDefaultId))
      //       .fetch()

      //     if (
      //       defaultDashboard &&
      //       auth.checkAccessRead(defaultDashboard)
      //     ) {
      //       next = { name: 'dashboard', params: { id: defaultDashboard.id } }
      //       homepageDefault = true
      //     }
      //   }

      //   if (!homepageDefault && auth.checkAccessRead('SectionDigitalTwinChart')) {
      //     const [ defaultTwinGraph ] = await dbProvider.database.collections
      //       .get('twin_graphs')
      //       .query(Q.where('id', homepageDefaultId))
      //       .fetch()

      //     if (
      //       defaultTwinGraph &&
      //       defaultTwinGraph.blockId &&
      //       auth.checkAccessRead(defaultTwinGraph)
      //     ) {
      //       next = { name: 'chart-view', params: { id: defaultTwinGraph.blockId } }
      //       homepageDefault = true
      //     }
      //   }
      // }

      router.push(next, {
        originalPathname: location.pathname,
      })
    }

    if (match) {
      defaultRedirect()
    } else if (!Object.values(CorePages).includes(corePage)) {
      const nextRouteName = routeFinder(currentPage)
      router.replace({ name: nextRouteName, originalPathname: location.pathname, query })
    }
  }, [ match, auth, router ])
}
