import { forwardRef, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { useRouter, useLocationQuery } from '@/router'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'
import classes from './index.module.css'

const iconsByType = {
  Overview: 'faIndustryWindows', // 'fa-industry-windows'
  Maintenance: 'faBrakeWarning', // 'fa--brake-warning'
  Process: 'faArrowsSpin', // 'fa-arrows-spin'
  Quality: 'faBadgeCheck', // 'fa--badge-check'
}

const RightBar = forwardRef(({
  options = [], onDrop, onDragEnd, onClick,
}, ref) => {
  const router = useRouter()
  const query = useLocationQuery()

  const intl = useIntl()

  return (
    <div
      ref={ref}
      className={classnames(classes.leftbar, 'bg-light shadow-sm')}
    >
      {options.map(({
        id, label, labelId, icon, color,
      }) => (
        <div
          key={id}
          className="d-flex flex-column"
          onDragEnter={e => onDragEnd(e, {
            id, labelId, label, type: 'Label',
          })}
          onDragEnd={onDrop}
        >
          <button
            type="button"
            title={intl.t(label)}
            className={classnames({
              active: labelId === query.category,
            }, 'btn p-2 outline-none rounded-0 btn-flat-secondary d-flex align-items-center flex-column py-4')}
            onClick={() => {
              onClick(labelId)
            }}
          >
            <Icon name={icon || 'placeholder'} color={color} size="lg" />
            <span className="mt-1 fs-7">
              {intl.t(label)}
            </span>
          </button>
        </div>
      ))}
      <div className="d-flex flex-column">
        <button
          type="button"
          title={intl.t('search')}
          className={classnames({
            active: !query.category || query.category === 'search',
          }, 'btn p-2 outline-none rounded-0 btn-flat-secondary d-flex align-items-center flex-column py-4')}
          onClick={() => {
            router.push({ query: { ...query, category: 'search' } })
          }}
        >
          <Icon name="faSearch" size="lg" />
          <span className="mt-1 fs-7">
            {intl.t('form.actions.search')}
          </span>
        </button>
      </div>
    </div>
  )
})

export default RightBar
