import {
  forwardRef,
  useRef,
  useMemo,
  useImperativeHandle,
} from 'react'
import { Tree as TreeComponent } from '@wiz/components'
import { consts } from '@wiz/utils'
import TreeOption from './TreeOption'
import NoDataDisplay from './NoDataDisplay'
import withDrag from './withDrag'

function Tree ({
  className,
  options,
  search,
  draggable,
  selectable,
  Content,
  itemSize = consts.ListItemHeight,
  active,
  checkDragged,
  optionClassName,
  checkFiltered,
  defaultOpennessState,
  onDragStart,
  onDragEnd,
  onDrop,
  onToggle,
  onResetFilter,
  onChange,
  onAction,
  twinsChain,
  isOpenByDefault,
  isActiveTwinOpen,
  isOpen,
}, ref) {
  const refTree = useRef()

  const itemData = useMemo(() => ({
    active,
    checkDragged,
    Content,
    draggable: typeof draggable === 'function' ? draggable : () => draggable,
    onAction,
    onChange,
    onDragEnd,
    onDragStart,
    onDrop,
    onToggle,
    optionClassName: typeof optionClassName === 'function' ? optionClassName : () => ('d-flex align-items-center hover-toggle'),
    search,
    selectable: typeof selectable === 'function' ? selectable : () => selectable,
  }), [
    active,
    checkDragged,
    Content,
    draggable,
    onAction,
    onChange,
    onDragEnd,
    onDragStart,
    onDrop,
    onToggle,
    optionClassName,
    search,
    selectable,
  ])

  useImperativeHandle(ref, () => ({
    scrollTo (id) {
      if (id && refTree.current) {
        refTree.current.scrollToRecord(id)
      }
    },

    getItemById (id) {
      if (id && refTree.current) {
        return refTree.current.getItemById(id)
      }

      return undefined
    },

    getSiblingsById (id) {
      if (id && refTree.current) {
        return refTree.current.getSiblingsById(id)
      }

      return []
    },

    getVisible () {
      return refTree.current?.getVisible()
    },
  }), [])

  if (!options.length && (!checkFiltered || checkFiltered())) {
    return (
      <NoDataDisplay
        className={className}
        onResetFilter={onResetFilter}
      />
    )
  }

  return (
    <TreeComponent
      refTree={refTree}
      className={className}
      options={options}
      itemSize={itemSize}
      isOpenByDefault={isOpenByDefault}
      isActiveTwinOpen={isActiveTwinOpen}
      isOpen={isOpen}
      defaultOpennessState={defaultOpennessState}
      itemData={itemData}
      twinsChain={twinsChain}
    >
      {TreeOption}
    </TreeComponent>
  )
}

export default withDrag(
  forwardRef(Tree),
)
