import { useRef } from 'react'
import classnames from 'classnames'
import { Icon, OverflowCenter, DropdownColor } from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function ItemContent ({
  search,
  data,
  context,
}) {
  const refColor = useRef()
  const intl = useIntl()
  const {
    onAction,
  } = context

  const {
    applied,
    data: item,
  } = data.payload

  const readOnly = onAction('readOnly')

  return (
    <>
      {applied ? (
        <Icon
          name={applied === true ? 'fa--check-circle' : 'fa--exclamation-circle'}
          className={classnames('me-2', {
            'text-success': applied === true,
            'text-danger': applied !== true,
          })}
          title={applied?.message}
        />
      ) : null}

      <button
        ref={refColor}
        type="button"
        className="btn p-0 me-2"
        style={{ color: item.color }}
      >
        <Icon name="fa--circle" size="xs" />
      </button>

      {readOnly ? null : (
        <DropdownColor
          alpha
          arrow
          target={refColor}
          value={item.color}
          onChange={color => onAction('replaceEventSource', { ...item, color })}
        />
      )}

      <div className="d-flex align-items-center min-w-0 flex-fill">
        {item.name ? (
          <OverflowCenter
            value={item.name}
            mark={search}
          />
        ) : null}
      </div>

      {readOnly ? null : (
        <div className="hover-toggle d-flex align-items-center ms-1 position-relative flex-shrink-0">
          <div className="hover-flex align-items-center position-absolute end-0">
            <div className="border-start rh-4 flex-shrink-0 mx-2" />

            <button
              type="button"
              className="btn btn-sm btn-fill-secondary"
              onClick={() => onAction('removeEventSource', item)}
            >
              <Icon name="fa--trash-alt" />
            </button>

            <button
              type="button"
              className="btn btn-sm ms-1 btn-fill-secondary"
              title={intl.t('form.actions.edit')}
              onClick={() => onAction('editEventSource', item)}
            >
              <Icon name="fa--edit" />
            </button>
          </div>

          <button
            type="button"
            className="btn btn-sm ms-1 btn-flat-primary"
          >
            <Icon name="fa--ellipsis-h" />
          </button>
        </div>
      )}
    </>
  )
}
